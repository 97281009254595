import {useState} from "react";

import {useSelector} from "react-redux";
import useLoadedMap from "Features/maps/hooks/useLoadedMap";

import {List, ListItemButton, Box, Typography, IconButton} from "@mui/material";
import {
  AddLocation as Locate,
  CenterFocusStrong as Focus,
} from "@mui/icons-material";

import FabLocate from "Features/zones/components/FabLocate";

export default function ListItemSample({
  scene,
  caplaEditor,
  sample,
  selected,
  onClick,
  viewerContext,
  onDragEnd,
  onFocusClick,
}) {
  // data

  const isDragging = useSelector((s) => s.zones.isDraggingLocateFab);
  const locateFabItem = useSelector((s) => s.zones.locateFabItem);
  const loadedMap = useLoadedMap();

  // state

  const [hover, setHover] = useState(false);

  // helpers
  const isLocated = sample?.coords?.find(
    (c) => c.mapClientId === loadedMap?.id
  );
  const showFab =
    loadedMap &&
    ((!isLocated && hover) || (isDragging && sample.id === locateFabItem?.id));

  const showFocus = isLocated;

  // helper - item fab

  const item = {...sample, type: "SAMPLE"};

  // handlers

  function handleMouseEnter() {
    setHover(true);
  }

  function handleMouseLeave() {
    setHover(false);
  }
  // helpers

  const materialName = sample?.material?.name;

  // helpers - showFocus

  const zoneId =
    viewerContext?.selectedFixedViewer === "ZONE"
      ? viewerContext?.selectedZoneId
      : null;

  const coord = sample?.coords?.find((c) => c.mapClientId === zoneId);

  // handlers

  function handleClick() {
    onClick(sample);
  }

  function handleFocusClick(e, sample) {
    e.stopPropagation();
    onFocusClick(sample);
  }

  return (
    <ListItemButton
      selected={selected}
      onClick={handleClick}
      sx={{p: 0.5, m: 0}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: 1,
        }}
      >
        <Box sx={{width: "60px", height: "40px"}}>
          <img
            src={sample.imageData}
            style={{width: "100%", height: "100%", objectFit: "cover"}}
          />
        </Box>

        <Box sx={{ml: 1, flexGrow: 1}}>
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {sample.num}
          </Typography>
          <Typography variant="body2">{materialName}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            //justifyContent: "center",
            color: "grey.400",
            transition: showFab ? "transform 0.3s ease-in-out" : "none",
            transform: showFab ? "translateX(10px)" : "translateX(0)",
          }}
        >
          {showFocus && (
            <IconButton
              size="small"
              color="inherit"
              onClick={(e) => handleFocusClick(e, sample)}
            >
              <Focus fontSize="small" />
            </IconButton>
          )}
          {showFab && <FabLocate caplaEditor={caplaEditor} item={item} />}
        </Box>
      </Box>
    </ListItemButton>
  );
}
