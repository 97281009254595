import {useDispatch} from "react-redux";
import {updateShape} from "../shapesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateShape() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  return async (updates) => {
    await dispatch(updateShape({accessToken, updates}));
  };
}
