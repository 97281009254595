import {saveBlob, urlToBlob} from "Features/files/utils";
import {createImageAsync, getImageSize} from "Features/images/imageUtils";
import Konva from "konva";
import theme from "Styles/theme";

export default class ImagesEditor {
  containerElement;

  stage;
  layer;
  width;
  height;

  strokeWidth;
  color;

  constructor({containerElement, src, imageSize}) {
    this.containerElement = containerElement;

    this.width = containerElement.getBoundingClientRect()?.width;
    this.height = containerElement.getBoundingClientRect()?.height;

    this.stage = new Konva.Stage({
      container: "containerViewerImage", // container string  ("container")
      width: this.width,
      height: this.height,
      draggable: true,
    });

    this.layerImage = new Konva.Layer({zIndex: 5});

    this.stage.add(this.layerImage);

    this.src = src;

    this.imageSize = imageSize;

    this.scaleBy = 1.1;
    this.scale = 1;

    this.imageScale = 1;
    this.offsetX = 0;
    this.offsetY = 0;

    this.addListeners();

    this.firstImageWasAdded = false; // to avoid zoom reset when changing image url.

    //
    this.imageSizeRef = {width: 200, height: 100};
    this.positionRef = {x: 0, y: 0, z: 0}; // ref used to compute position of several images.
    this.canvasSize = {width: 300, height: 150}; // used to compute canvas size with several images.

    // images

    this.firstImageNode = null;

    this.loadImage();
  }

  /*
   * setters
   */

  setFirstImageWasAdded(bool) {
    this.firstImageWasAdded = bool;
  }

  /*
   * layers
   */

  resetLayers() {
    this.clearLayerImage();
  }

  /*
   * image
   */

  async loadImage() {
    const containerSize = this.stage.container().getBoundingClientRect();
    const imageSize = this.imageSize;
    console.log(
      "containerSize",
      containerSize.width,
      containerSize.height,
      this.stage.width(),
      this.stage.height()
    );

    if (!this.src) return;

    const imageR = imageSize.width / imageSize.height;
    const caplaEditorR = containerSize.width / containerSize.height;
    const isPortrait = imageR < caplaEditorR;

    if (isPortrait) {
      this.imageScale = imageSize.height / containerSize.height;
      this.offsetX =
        (containerSize.width * this.imageScale - imageSize.width) / 2;
    } else {
      this.imageScale = imageSize.width / containerSize.width;
      this.offsetY =
        (containerSize.height * this.imageScale - imageSize.height) / 2;
    }
    const imageObj = new Image();
    imageObj.onload = () => {
      const image = new Konva.Image({
        image: imageObj,
        x: this.offsetX,
        y: this.offsetY,
        width: imageSize.width,
        height: imageSize.height,
      });

      this.stage.scaleX(1 / this.imageScale);
      this.stage.scaleY(1 / this.imageScale);

      this.layerImage.add(image);
      this.layerImage.draw();
    };
    imageObj.crossOrigin = "Anonymous";
    imageObj.src = this.src;
  }

  draw() {
    this.layerImage.draw();
  }

  clear() {
    this.stage.clear();
    this.stage.clearCache();
  }

  clearLayerImage() {
    const children = this.layerImage.getChildren();
    for (let i = 0; i < children.length; i++) {
      children[i].destroy();
    }
  }

  updateStageSize() {
    const container = this.stage.container();
    const width = container.getBoundingClientRect().width;
    const height = container.getBoundingClientRect().height;
    console.log("dims", width, height);
    this.stage.width(width);
    this.stage.height(height);
  }

  resetStageScale() {
    this.stage.scaleX(1 / this.imageScale);
    this.stage.scaleY(1 / this.imageScale);
    this.stage.position({x: 0, y: 0});
  }

  addListeners() {
    this.stage.on("wheel", (e) => {
      e.evt.preventDefault();
      var oldScale = this.stage.scaleX();

      var pointer = this.stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale,
      };

      var newScale =
        e.evt.deltaY < 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
      this.scale = newScale;
      this.stage.scale({x: newScale, y: newScale});

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      this.stage.position(newPos);
      this.stage.batchDraw();
    });
  }

  /*
   * cursors
   */

  enableCrosshair() {
    this.stage.container().style.cursor = "crosshair";
  }
  disableCrosshair() {
    this.stage.container().style.cursor = "pointer";
  }
}
