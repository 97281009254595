import {useEffect} from "react";

import useSelectedZone from "./useSelectedZone";

export default function useAutoLoadImageInZonesEditor({scene, zonesEditor}) {
  // data

  const selectedZone = useSelectedZone(scene);

  // effects

  useEffect(() => {
    if (selectedZone?.id && zonesEditor) {
      zonesEditor.loadZone(selectedZone);
    }
  }, [selectedZone?.id, zonesEditor]);
}
