import {useState} from "react";
import {IconButton, Box} from "@mui/material";
import {FormatColorFill} from "@mui/icons-material";

import DialogSelectorColor from "./DialogSelectorColor";

export default function SelectorColorVariantButton({color, onChange}) {
  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleClick() {
    console.log("handleClick");
    setOpen(true);
  }

  function handleChange(color) {
    if (onChange) onChange(color);
  }

  return (
    <>
      <DialogSelectorColor
        color={color}
        onChange={handleChange}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Box onClick={handleClick}>
        <IconButton onClick={handleClick}>
          <FormatColorFill sx={{fill: color}} />
        </IconButton>
      </Box>
    </>
  );
}
