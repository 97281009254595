import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import useUpdateShortcutElementTypesFromLeftPanel from "../hooks/useUpdateShortcutElementTypesFromLeftPanel";

import {Box, Paper} from "@mui/material";

import HeaderInLeftPanel from "./HeaderInLeftPanel";

import useSceneModule from "Features/navigation/useSceneModule";

import SectionMeasurementsInLeftPanelV2 from "./SectionMeasurementsInLeftPanelV2";

import SectionIssuesInLeftPanel from "Features/issues/components/SectionIssuesInLeftPanel";

import ListSceneSectors from "./ListSceneSectors";

import SectionSceneHomeInLeftPanel from "Features/boxhome/components/SectionSceneHomeInLeftPanel";
import SectionOnboardingInLeftPanel from "Features/onboarding/components/SectionOnboardingInLeftPanel";
import SectionPackagesInLeftPanel from "Features/elementPackages/components/SectionPackagesInLeftPanel";
import SectionElementTypesInLeftPanel from "Features/elementTypes/components/SectionElementTypesInLeftPanel";
//import SectionRessourcesInLeftPanel from "Features/ressources/components/SectionRessourcesInLeftPanel";
import SectionArticlesInLeftPanel from "Features/articles/components/SectionArticlesInLeftPanel";
import SectionSubHeaderInLeftPanel from "./SectionSubHeaderInLeftPanel";
import SectionLeftPanelHandel from "./SectionLeftPanelHandel";
import SectionMarkersInLeftPanel from "Features/markers/components/SectionMarkersInLeftPanel";
import SectionEditMeasurementsInLeftPanelV2 from "./SectionEditMeasurementsInLeftPanelV2";
import SectionInputsInLeftPanel from "Features/inputs/components/SectionInputsInLeftPanel";
import TopBarOverviewInLeftPanel from "Features/navigation/components/TopBarOverviewInLeftPanel";

import HeaderInLeftPanelOnboarding from "Features/onboarding/components/HeaderInLeftPanelOnboarding";
import HeaderInLeftPanelPackage from "Features/elementPackages/components/HeaderInLeftPanelPackage";
import HeaderInLeftPanelReport from "Features/reports/components/HeaderInLeftPanelReport";

import SectionLeftPanelActions from "./SectionLeftPanelActions";
import SectionViewsInLeftPanel from "Features/views/components/SectionViewsInLeftPanel";
import SectionSurveysInLeftPanel from "Features/surveys/components/SectionSurveysInLeftPanel";
import SectionZonesInLeftPanel from "Features/zones/components/SectionZonesInLeftPanel";
import SectionRoomsInLeftPanel from "Features/rooms/components/SectionRoomsInLeftPanel";
import SectionMaterialsInLeftPanel from "Features/materials/components/SectionMaterialsInLeftPanel";
import SectionSamplesInLeftPanel from "Features/samples/components/SectionSamplesInLeftPanel";
import SectionReportsInLeftPanel from "Features/reports/components/SectionReportsInLeftPanel";
import SectionBlocksInLeftPanel from "Features/reportBlocks/components/SectionBlocksInLeftPanel";

import SectionScopesInLeftPanel from "Features/scopes/components/SectionScopesInLeftPanel";
import TabSelectorForEditMeasurementsInLeftPanel from "Features/measurements/components/TabSelectorForEditMeasurementsInLeftPanel";
import HeaderSharedSceneInLeftPanel from "Features/scenes/components/HeaderSharedSceneInLeftPanel";
import SectionLeftPanelOverPanels from "./SectionLeftPanelOverPanels";
import SectionShapesInLeftPanel from "Features/shapes/components/SectionShapesInLeftPanel";

export default function SectionLeftPanel({scene, caplaEditor, viewer}) {
  // update shortcuts from Leftpanel selection

  useUpdateShortcutElementTypesFromLeftPanel(scene, viewer);

  // data

  const {sceneId} = useParams();
  const sceneModule = useSceneModule();

  const os = useSelector((s) => s.ui.openSections);
  const leftPanelWidth = useSelector((s) => s.navigation.leftPanelWidth);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);

  const inOutMode = useSelector(
    (s) => s.measurements.filteredMeasurementsViewModeBckp
  );

  // helpers

  let display =
    sceneId &&
    sceneModule !== "LIBRARY" &&
    sceneModule !== "PRICINGS" &&
    sceneModule !== "RELATIONS" &&
    sceneModule !== "MODULES";
  if (viewer) display = true;

  // helpers - show left panel

  const showLeftPanel =
    (openLeftPanel && !os.outlet) ||
    (openLeftPanel && sceneModule === "ARTICLES");

  //
  let width = 0;
  if (showLeftPanel) width = leftPanelWidth;

  return (
    <Paper
      elevation={12}
      sx={{
        display: display ? "flex" : "none",
        width: width,
        minWidth: width,
        height: 1,
        maxHeight: 1,
        bgcolor: "background.default",
        position: "relative",
        flexDirection: "column",
        justifyItems: "stretch",
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <SectionLeftPanelOverPanels
        scene={scene}
        caplaEditor={caplaEditor}
        viewer={viewer}
      />

      {!os.outlet && <SectionLeftPanelHandel />}
      {showLeftPanel && (
        <Box
          sx={{
            height: 1,
            display: "flex",
            flexDirection: "column",
            //border: "4px solid orange",
            boxSizing: "border-box",
            justifyItems: "stretch",
          }}
        >
          {!viewer && (
            <TopBarOverviewInLeftPanel
              scene={scene}
              caplaEditor={caplaEditor}
            />
          )}
          {viewer && (
            <HeaderSharedSceneInLeftPanel
              scene={scene}
              caplaEditor={caplaEditor}
            />
          )}
          {!viewer &&
            !["ONBOARDING", "EDIT_MEASUREMENTS", "REPORT"].includes(
              sceneModule
            ) && (
              <HeaderInLeftPanel
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={viewer}
              />
            )}
          {!viewer && sceneModule === "ONBOARDING" && (
            <HeaderInLeftPanelOnboarding
              scene={scene}
              caplaEditor={caplaEditor}
              viewer={viewer}
            />
          )}
          {!viewer && sceneModule === "EDIT_MEASUREMENTS" && (
            <HeaderInLeftPanelPackage
              scene={scene}
              caplaEditor={caplaEditor}
              viewer={viewer}
            />
          )}
          {!viewer && sceneModule === "REPORT" && (
            <HeaderInLeftPanelReport
              scene={scene}
              caplaEditor={caplaEditor}
              viewer={viewer}
            />
          )}

          {!viewer && <SectionSubHeaderInLeftPanel />}

          <SectionLeftPanelActions
            scene={scene}
            caplaEditor={caplaEditor}
            viewer={viewer}
          />

          {viewer && (
            <Box
              sx={{
                height: 1,
                display: "flex",
                flexDirection: "column",
                //border: "4px solid orange",
                boxSizing: "border-box",
                justifyItems: "stretch",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "stretch",
                  height: 1,
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                  }}
                >
                  <TabSelectorForEditMeasurementsInLeftPanel />
                </Box>
                <SectionMeasurementsInLeftPanelV2
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              </Box>
            </Box>
          )}

          {!viewer && (
            <Box
              sx={{
                flexGrow: 1,
                minHeight: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sceneModule === "HOME" && (
                <SectionSceneHomeInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "SCOPES" && (
                <SectionScopesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "ONBOARDING" && (
                <SectionOnboardingInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "MARKERS" && (
                <SectionMarkersInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "SECTORS" && (
                <ListSceneSectors scene={scene} caplaEditor={caplaEditor} />
              )}
              {sceneModule === "ELEMENT_TYPES" && (
                <SectionElementTypesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {/* {sceneModule === "RESSOURCES" && (
                <SectionRessourcesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )} */}
              {sceneModule === "ARTICLES" && (
                <SectionArticlesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "INPUTS" && (
                <SectionInputsInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "ZONES" && (
                <SectionZonesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "ROOMS" && (
                <SectionRoomsInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "MATERIALS" && (
                <SectionMaterialsInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "PACKAGES" && (
                <SectionPackagesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "MEASUREMENTS" && (
                <SectionMeasurementsInLeftPanelV2
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "EDIT_MEASUREMENTS" && (
                <SectionEditMeasurementsInLeftPanelV2
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "ISSUES" && (
                <SectionIssuesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                  width={width}
                  viewer={false}
                  inOutMode={inOutMode}
                />
              )}
              {sceneModule === "VIEWS" && (
                <SectionViewsInLeftPanel scene={scene} />
              )}
              {sceneModule === "SURVEYS" && (
                <SectionSurveysInLeftPanel scene={scene} />
              )}
              {sceneModule === "SAMPLES" && (
                <SectionSamplesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
              {sceneModule === "REPORTS" && (
                <SectionReportsInLeftPanel scene={scene} />
              )}
              {sceneModule === "REPORT" && (
                <SectionBlocksInLeftPanel scene={scene} />
              )}
              {sceneModule === "SHAPES" && (
                <SectionShapesInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}
