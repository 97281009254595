import React from "react";

import {useSelector, useDispatch} from "react-redux";

import {setShowNodesLayers} from "../zonesSlice";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleColorMap from "Features/navigation/useSceneModuleColorMap";

import {Box, Paper, IconButton, Tooltip} from "@mui/material";
import {lighten} from "@mui/material";

export default function SelectorNodesLayers() {
  const dispatch = useDispatch();

  // data

  const iconsMap = useSceneModuleIconMap();
  const colorsMap = useSceneModuleColorMap();

  // selection

  const showNodesLayers = useSelector((s) => s.zones.showNodesLayers);

  // handlers

  function handleClick(key) {
    const exists = showNodesLayers.includes(key);
    const newLayers = showNodesLayers.filter((layer) => layer !== key);
    if (!exists) newLayers.push(key);
    dispatch(setShowNodesLayers(newLayers));
  }

  const nodesLayers = [
    {
      key: "ROOMS",
      label: "Pièces",
      icon: iconsMap.ROOMS,
    },
    {
      key: "SAMPLES",
      label: "Prélèvements",
      icon: iconsMap.SAMPLES,
    },
    {
      key: "SHAPES",
      label: "Dessin",
      icon: iconsMap.SHAPES,
    },
  ];
  return (
    <Box sx={{display: "flex", gap: 1}}>
      {nodesLayers.map((nodeLayer) => {
        let bgcolor = colorsMap[nodeLayer.key];
        const selected = showNodesLayers.includes(nodeLayer.key);
        if (!selected) bgcolor = lighten(bgcolor, 0.3);
        return (
          <Tooltip key={nodeLayer.key} title={nodeLayer.label}>
            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                bgcolor,
                color: "white",
                borderRadius: "8px",
              }}
              elevation={selected ? 4 : 0}
            >
              <IconButton
                color="inherit"
                onClick={() => handleClick(nodeLayer.key)}
              >
                {nodeLayer.icon}
              </IconButton>
            </Paper>
          </Tooltip>
        );
      })}
    </Box>
  );
}
