import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

import {
  createShapeMatRelation,
  updateShapeMatRelation,
} from "../shapeMatRelationsSlice";
import useShapeMatRelations from "./useShapeMatRelations";

export default function useCreateShapeMatRelation(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const relations = useShapeMatRelations({
    filterBySceneId: scene?.id,
    variant: "byShapeIdMatId",
  });

  const create = async (shapeMatRelation) => {
    const exists =
      relations[shapeMatRelation.shapeId + shapeMatRelation.materialId];
    if (exists) {
      const updates = {...exists, ...shapeMatRelation};
      dispatch(updateShapeMatRelation({updates, accessToken}));
      return;
    } else {
      dispatch(createShapeMatRelation({shapeMatRelation, accessToken}));
    }
  };

  return create;
}
