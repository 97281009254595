import {useDispatch} from "react-redux";

import {updateSample} from "../samplesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateSample() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const update = async (updates) => {
    console.log("updating sample", updates);
    if (updates.id && updates.surveyId && accessToken) {
      await dispatch(updateSample({accessToken, updates}));
    } else {
      console.log("error updating sample, missing id or surveyId", updates);
    }
  };

  return update;
}
