import Konva from "konva";

import getShapeNodePoints from "./utilsShapesManager/getShapeNodePoints";
import getMousePositionInStage from "./utilsZonesEditor/getMousePositionInStage";
import getTempAnchorPosition from "./utilsShapeEditor/getTempAnchorPosition";
import theme from "Styles/theme";
import getZoneEditorBboxInStage from "./utilsZonesEditor/getZoneEditorBboxInStage";

export default class ShapeEditor {
  constructor({zonesEditor, onEditedShapeIdChange}) {
    this.zonesEditor = zonesEditor;

    this.stage = zonesEditor.stage;
    this.layerEditing = zonesEditor.layerEditing;
    this.layerAnchors = zonesEditor.layerAnchors;

    this.isEditing = false;
    this.editedShapeNode = null;
    this.onEditedShapeIdChange = onEditedShapeIdChange;
    this.updates = null;

    this.anchorsNodes = [];
    this.anchorsChanged = false; // to check if we should save.

    this.selectedAnchorIndex = null; // used to delete anchor.
    this.lastSelectedAnchor = null; // to reset color.

    this.tempAnchorIndex = null;
    this.tempAnchorNode = new Konva.Circle({
      radius: 5,
      fill: "white",
      stroke: "black",
      strokeWidth: 1,
      shapeType: "TEMP_ANCHOR",
      zIndex: 2,
      x: 0,
      y: 0,
    });
    this.tempAnchorNode.on("mouseenter", this.handleMouseEnterTemp);
    this.tempAnchorNode.on("mouseleave", this.handleMouseLeaveTemp);
    this.tempAnchorNode.on("click", this.handleMouseClickTemp);
    this.layerAnchors.add(this.tempAnchorNode);
    this.tempAnchorNode.hide();

    this.lastCursor = "default";
  }

  startEdition = (shapeNode) => {
    console.log("[edition shape] startEdition");
    // remove previous anchors
    this._removeAnchors();

    // init
    this.isEditing = true;
    this.editedShapeNode = shapeNode;
    if (this.onEditedShapeIdChange) this.onEditedShapeIdChange(shapeNode.id());

    // draw anchors
    this._drawAnchors(shapeNode);

    this.stage.on("mousemove", this.handleMouseMove);
  };

  stopEdition = () => {
    console.log("[edition shape] stopEdition");
    try {
      this.stage.off("mousemove", this.handleMouseMove);

      // new points
      let newPoints;
      if (this.anchorsChanged) {
        newPoints = getShapeNodePoints(this.editedShapeNode, this.zonesEditor);
      }

      // updates
      let updates;
      if (this.updates) {
        updates = {...this.updates};
      }

      // editedShapeNode
      this.editedShapeNode = null; // we do not destroy the node here (in layerEditing)
      if (this.onEditedShapeIdChange) this.onEditedShapeIdChange(null);

      // reset
      this._removeAnchors();
      this._resetTempAnchorNode();
      this.isEditing = false;
      this.updates = null;

      // return
      console.log("newPoints", newPoints);
      let result;
      if (newPoints) result = {points: newPoints};
      if (updates) result = {...(result ?? {}), ...updates};
      return result;
    } catch (e) {
      console.log("error in stopEdition", e);
    }
  };

  _resetTempAnchorNode() {
    this.tempAnchorIndex = null;
    this.tempAnchorNode.hide();
    this.layerAnchors.batchDraw();
  }

  // handlers - event

  handleMouseEnterTemp = (e) => {
    this.lastCursor = this.stage.container().style.cursor;
    this.stage.container().style.cursor = "crosshair";
  };
  handleMouseLeaveTemp = (e) => {
    this.stage.container().style.cursor = this.lastCursor;
  };
  handleMouseClickTemp = (e) => {
    const x1 = this.tempAnchorNode.x();
    const y1 = this.tempAnchorNode.y();
    const oldPoints = this.editedShapeNode.points();
    const index = this.tempAnchorIndex;
    const isLast = index === oldPoints / 2 - 1;
    let newPoints = oldPoints;
    if (isLast) {
      newPoints = [...oldPoints, x1, y1];
    } else {
      const part1 = oldPoints.slice(0, 2 * (index + 1));
      const part2 = oldPoints.slice(2 * (index + 1));
      newPoints = [...part1, x1, y1, ...part2];
    }
    console.log("newPoints", newPoints);
    this.editedShapeNode.points(newPoints);
    this._removeAnchors();
    this.anchorsChanged = true;
    this._drawAnchors(this.editedShapeNode);
    this.layerAnchors.batchDraw();
  };

  handleMouseMove = (e) => {
    if (!this.isEditing) return;

    const mousePosition = getMousePositionInStage(this.stage);
    const bbox = getZoneEditorBboxInStage(this.zonesEditor);
    const minDistanceToCorner = bbox.width * 0.03;
    const tempPoint = getTempAnchorPosition(
      mousePosition,
      this.editedShapeNode,
      minDistanceToCorner
    );
    console.log("tempPoint", tempPoint);
    if (tempPoint) {
      this.tempAnchorNode.show();
      this.tempAnchorNode.position(tempPoint.position);
      this.layerAnchors.batchDraw();
      this.tempAnchorIndex = tempPoint.index;
    } else {
      this.tempAnchorNode.hide();
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      console.log("delete anchor");
      window.removeEventListener("keydown", this.handleKeyDown);
      if (this.editedShapeNode) {
        const oldPoints = this.editedShapeNode.points();
        const index = this.selectedAnchorIndex;
        const part1 = oldPoints.slice(0, 2 * index);
        const part2 = oldPoints.slice(2 * index + 2);
        const newPoints = [...part1, ...part2];
        this.editedShapeNode.points(newPoints);
        this._removeAnchors();
        this.anchorsChanged = true;
        this._drawAnchors(this.editedShapeNode);
        this.layerAnchors.batchDraw();
      }
    }
  };

  // edit shape props

  setColor(color) {
    if (this.editedShapeNode) {
      this.editedShapeNode.fill(color);
      this.layerEditing.batchDraw();
      this.updates = {...(this.updates ?? {}), color};
    }
  }

  // helpers

  _drawAnchors(shape) {
    try {
      const points = shape.points();
      for (let i = 0; i < points.length; i += 2) {
        const anchor = new Konva.Circle({
          x: points[i],
          y: points[i + 1],
          radius: 5,
          fill: theme.palette.anchor.default,
          draggable: true,
          dragOnTop: false,
          shapeType: "ANCHOR",
        });

        anchor.on("dragmove", () => {
          this.anchorsChanged = true;
          points[i] = anchor.x();
          points[i + 1] = anchor.y();
          shape.points(points);
          this.layerAnchors.batchDraw();
          this.layerEditing.batchDraw();
        });

        anchor.on("mouseenter", () => {
          this.lastCursor = this.stage.getContainer().style.cursor;
          this.stage.getContainer().style.cursor = "grab";
        });

        anchor.on("mouseleave", () => {
          this.stage.getContainer().style.cursor = this.lastCursor;
        });

        anchor.on("click", (e) => {
          //e.evt.stopPropagation();
          this._selectAnchorNode(anchor);
          this.selectedAnchorIndex = i / 2;
          window.addEventListener("keydown", this.handleKeyDown);
        });

        this.layerAnchors.add(anchor);
        this.anchorsNodes.push(anchor);
      }
      this.layerAnchors.batchDraw();
    } catch (e) {
      console.log("Error in drawAnchors", e);
    }
  }

  _removeAnchors() {
    try {
      this.anchorsNodes.forEach((anchorNode) => {
        anchorNode.destroy();
      });
      this.anchorsNodes = [];
      this.anchorsChanged = false;
      this.layerAnchors.batchDraw();
    } catch (e) {
      console.log("Error in removeAnchors", e);
    }
  }

  _selectAnchorNode(anchorNode) {
    this.lastSelectedAnchor?.fill(theme.palette.anchor.default);
    anchorNode.fill(theme.palette.anchor.selected);
    this.lastSelectedAnchor = anchorNode;
    this.layerAnchors.batchDraw();
  }
}
