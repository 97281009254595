export default function downloadImage(img, options) {
  // options

  let variant = options?.variant;

  // main

  const link = document.createElement("a");

  link.href = variant === "base64" ? img : img.src;
  link.download = "image.png";
  link.click();
}
