import testCoordsInBbox from "Utils/testCoordsInBbox";

export default function getSamplesInMap(mapClientId, samples, options) {
  if (!samples?.length > 0) return [];

  // options

  const inBbox = options?.inBbox;

  // main

  let samplesInMap = samples.filter(
    (r) =>
      Array.isArray(r.coords) &&
      r.coords.map((c) => c?.mapClientId).includes(mapClientId)
  );

  // filters

  if (inBbox) {
    samplesInMap = samplesInMap.filter((r) => {
      const coords = r.coords.find((c) => c?.mapClientId === mapClientId);
      return testCoordsInBbox(coords, inBbox);
    });
  }

  return samplesInMap;
}
