import Konva from "konva";
import getMousePositionInStage from "./utilsZonesEditor/getMousePositionInStage";

export default class ScaleManager {
  constructor({zonesEditor, onEditScale}) {
    this.zonesEditor = zonesEditor;
    this.layerEditing = zonesEditor.layerEditing;
    this.stage = zonesEditor.stage;

    this.onEditScale = onEditScale;

    this.drawingIsEnabled = false;
    this.isDrawing = false;

    this.currentScale = null; // the scale being drawn.
    this.startPos = null;
    this.endPos = null;

    // Bind event handlers
    this.handleMouseClick = this.handleMouseClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.handleShapeClick = this.handleShapeClick.bind(this);
    this.handleShapeDblClick = this.handleShapeDblClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleStageClick = this.handleStageClick.bind(this);

    // Add keydown event listener
    window.addEventListener("keydown", this.handleKeyDown);
  }

  enableDrawing() {
    //
    this.zonesEditor.enableCrosshair();
    this.drawingIsEnabled = true;
    this.stage.on("click", this.handleMouseClick);
  }

  disableDrawing() {
    this.resetCurrentScale();
    this.zonesEditor.disableCrosshair();
    this.drawingIsEnabled = false;
    this.stage.off("click", this.handleMouseClick);
  }

  startDrawing() {
    this.isDrawing = true;
    this.resetCurrentScale();
    this.stage.on("mousemove", this.handleMouseMove);
    window.addEventListener("keydown", this.handleKeyDown);
    //
    this.zonesEditor.onIsDrawingChange(true);
  }

  stopDrawing() {
    this.isDrawing = false;
    this.stage.off("mousemove", this.handleMouseMove);
    window.removeEventListener("keydown", this.handleKeyDown);
    //
    setTimeout(() => {
      this.zonesEditor.onIsDrawingChange(false); // to let window keyDown event being executed on the Viewer.
    }, 100);
  }

  resetCurrentScale() {
    if (this.currentScale) this.currentScale?.destroy();
    this.currentScale = null;
    this.startPos = null;
    this.endPos = null;
    this.layerEditing.destroyChildren();
  }

  handleMouseClick(event) {
    //event.evt.stopPropagation();
    if (!this.drawingIsEnabled || this.zonesEditor.stageIsDragging) return;

    const {x, y} = getMousePositionInStage(this.stage);
    if (!this.isDrawing) {
      this.startDrawing();
      this.startPos = {x, y};
      this.currentScale = new Konva.Line({
        points: [x, y],
        stroke: "black",
        strokeWidth: 2,
      });
      this.layerEditing.add(this.currentScale);
    } else if (!this.endPos) {
      this.endPos = {x, y};
      const mousePosition = {x: event.evt.x, y: event.evt.y};
      const length = Math.sqrt(
        Math.pow(this.endPos.x - this.startPos.x, 2) +
          Math.pow(this.endPos.y - this.startPos.y, 2)
      );
      this.onEditScale({mousePosition, length});
      this.stopDrawing();
    } else {
      return;
    }
  }

  handleMouseMove(event) {
    if (!this.isDrawing || !this.currentScale || this.endPos) return;

    const nextPoint = getMousePositionInStage(this.stage);
    const dx = nextPoint.x - this.startPos.x;
    const dy = nextPoint.y - this.startPos.y;
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

    if (event.evt.shiftKey) {
      if (Math.abs(angle) < 45) {
        // Snap to horizontal axis
        nextPoint.y = this.startPos.y;
      } else if (Math.abs(angle) > 45 && Math.abs(angle) < 135) {
        // Snap to vertical axis
        nextPoint.x = this.startPos.x;
      }
    }

    this.currentScale.points([
      this.startPos.x,
      this.startPos.y,
      nextPoint.x,
      nextPoint.y,
    ]);
    this.stage.batchDraw();
  }

  handleShapeDblClick(event) {
    console.log("dblclick event", event);
    const shape = event.target;
    this.transformer.nodes([shape]);
    this.layerEditing.batchDraw();
  }

  handleShapeClick(event) {
    const shape = event.target;
    console.log("click on shape", shape);
    //this.transformer.nodes([shape]);
    //this.layerEditing.batchDraw();
    this.drawAnchors(shape);
    this.editedShape = shape;
  }

  handleKeyDown(event) {
    if (event.key === "Escape" && this.isDrawing) {
      this.resetCurrentScale();
      this.stopDrawing();
    }
  }

  handleStageClick(event) {
    const clickedShape = event.target;
    const shapeId = clickedShape?._id ?? "-?-";
    console.log("clickedShape id", shapeId);
    // if (this.selectedShape?.id && this.selectedShape.id !== this.selectedShape) {
    //   this.removeAnchors();
    // }
  }
}
