import theme from "Styles/theme";

export default function getMarkerFromRoom(room, options) {
  const roomInMap = {};

  // options
  const mapClientId = options?.mapClientId;
  const draggable = options?.draggable;
  const selected = options?.selected;
  const over = options?.over;
  const counter = options?.counter;

  // coords
  const coords = room.coords?.find((c) => c.mapClientId === mapClientId);
  if (coords) {
    roomInMap.x = coords.x;
    roomInMap.y = coords.y;
    roomInMap.scale = coords.scale;
    roomInMap.mapClientId = mapClientId;
  }

  // label
  roomInMap.label = room.name;

  // variant
  roomInMap.type = "ROOM_LABEL";
  roomInMap.color = theme.palette.sceneModule.room;

  // id
  roomInMap.id = room.id;

  // other
  roomInMap.draggable = draggable;
  roomInMap.selected = selected;
  roomInMap.over = over;
  roomInMap.counter = counter;

  return roomInMap;
}
