import {useDispatch} from "react-redux";

import {setEnabledDrawingMode} from "../zonesSlice";

import {Box, Paper, IconButton} from "@mui/material";
import {Architecture as Scale} from "@mui/icons-material";

export default function ButtonDrawScaleInViewer({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  // handler

  function handleClick(e) {
    e.stopPropagation();
    //
    dispatch(setEnabledDrawingMode("SCALE"));
    //
    caplaEditor.zonesEditor.enableDrawingMode("SCALE");
  }

  return (
    <Box
      sx={{
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        sx={{width: 30, height: 30, borderRadius: "4px"}}
        onClick={handleClick}
      >
        <Scale fontSize="small" />
      </IconButton>
    </Box>
  );
}
