import {useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setSelectedFixedViewer} from "Features/ui/uiSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";

import {List, ListItemButton, Box, Typography, IconButton} from "@mui/material";
import {
  AddLocation,
  CenterFocusStrong as Focus,
  Map,
} from "@mui/icons-material";

import useMapsByScene from "Features/maps/hooks/useMapsByScene";
import getItemsMapById from "Utils/getItemsMapById";
import FabLocate from "Features/zones/components/FabLocate";
import useLoadedMap from "Features/maps/hooks/useLoadedMap";

export default function ListItemRoomVariantLeftPanel({
  scene,
  room,
  onClick,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // data

  const pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const os = useSelector((s) => s.ui.openSections);
  const fixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const maps = useMapsByScene(scene, {filterByScope: true});
  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);
  const isDragging = useSelector((s) => s.zones.isDraggingLocateFab);
  const locateFabItem = useSelector((s) => s.zones.locateFabItem);
  const loadedMap = useLoadedMap();

  // state

  const [hoverState, setHoverState] = useState(false);

  // helper

  const isZoneViewerOpened = os.fixedViewersBox && fixedViewer === "ZONE";
  const isLocated = (room.coords ?? []).find(
    ({mapClientId}) => mapClientId === loadedMap?.id
  );

  // helpers - show

  const showFab =
    loadedMap &&
    ((!isLocated && hoverState) ||
      (isDragging && room.id === locateFabItem?.id));

  const showMap = hoverState && room.mapsClientIds?.length > 0;

  const showFocus = Boolean(isLocated);

  // helper - item

  const {selected, count, isLeaf, rank} = room;
  const item = {...room, type: "ROOM"};

  // helpers - items

  const mapsById = useMemo(() => getItemsMapById(maps), [maps]);

  // handlers - event

  function handleMouseEnter() {
    setHoverState(true);
  }

  // handlers - other

  function handleClick(room) {
    onClick(room);
  }

  function handleFocusClick(e, room) {
    e.stopPropagation();
    console.log("handleFocusClick room", room);
    caplaEditor.zonesEditor.markersManager.focusOnMarker(room.id);
  }

  function handleMapClick(e, room) {
    console.log("openMap of room", room);
    e.stopPropagation();
    if (!isZoneViewerOpened) {
      dispatch(setSelectedFixedViewer("ZONE"));
    }

    if (room.mapsClientIds?.length > 0) {
      const roomMaps = room.mapsClientIds.map((id) => mapsById[id]);
      const roomZoneIds = roomMaps
        .map((map) => map?.fromModel?.zoneId)
        .filter(Boolean);
      console.log("roomZoneIds", roomZoneIds);
      if (!selectedZoneId || !roomZoneIds.includes(selectedZoneId)) {
        dispatch(setSelectedZoneId(roomZoneIds[0]));
      }
    }
  }

  return (
    <ListItemButton
      disableGutters
      divider
      selected={selected}
      onClick={() => handleClick(room)}
      sx={{p: 0, m: 0}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setHoverState(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: 1,
          justifyContent: "space-between",
          px: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              pr: 1,
              display: "flex",
              alignItems: "center",
              visibility: showMap ? "visible" : "hidden",
              color: "text.secondary",
            }}
          >
            <IconButton
              size="small"
              color="inherit"
              onClick={(e) => handleMapClick(e, room)}
            >
              <Map fontSize="small" />
            </IconButton>
          </Box>

          <Box
            sx={{
              pl: `${(rank - 1) * 16}px`,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 12,

                ...(isLeaf && {fontWeight: "bold"}),
                ...(rank === 1 && {color: "text.secondary"}),
                ...(room.noParent && {color: "error.main"}),
              }}
            >
              {room?.name}
            </Typography>
          </Box>
        </Box>
        {count > 0 && (
          <Typography sx={{fontSize: 12, color: "text.secondary"}}>
            {count}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            //justifyContent: "center",
            color: "grey.400",
            transition: showFab ? "transform 0.3s ease-in-out" : "none",
            transform: showFab ? "translateX(10px)" : "translateX(0)",
          }}
        >
          {showFocus && (
            <IconButton
              size="small"
              color="inherit"
              onClick={(e) => handleFocusClick(e, room)}
            >
              <Focus fontSize="small" />
            </IconButton>
          )}
          {showFab && <FabLocate caplaEditor={caplaEditor} item={item} />}
        </Box>
      </Box>
    </ListItemButton>
  );
}
