import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setNewShape} from "Features/shapes/shapesSlice";

import {Paper, Typography} from "@mui/material";

import SelectorColorVariantButton from "Features/color/components/SelectorColorVariantButton";
import SelectorFillTypeVariantButton from "Features/shapes/components/SelectorFillTypeVariantButton";

export default function ToolbarZoneViewerDrawShape({caplaEditor}) {
  const dispatch = useDispatch();

  // string

  const label = "[ESC] : quitter le mode dessin";

  // data

  const newShape = useSelector((s) => s.shapes.newShape);

  // init

  useEffect(() => {
    caplaEditor.zonesEditor.shapesManager.setNewShape(newShape);
  }, [caplaEditor?.zonesEditor]);

  // handlers

  function handleColorChange(color) {
    const shape = {...newShape, color};
    dispatch(setNewShape(shape));
    caplaEditor.zonesEditor.shapesManager.setNewShape(shape);
  }
  function handleFillTypeChange(fillType) {
    const shape = {...newShape, fillType};
    dispatch(setNewShape(shape));
    caplaEditor.zonesEditor.shapesManager.setNewShape(shape);
  }

  return (
    <Paper sx={{display: "flex", alignItems: "center"}}>
      <SelectorColorVariantButton
        color={newShape.color}
        onChange={handleColorChange}
      />
      <SelectorFillTypeVariantButton
        color={newShape.color}
        fillType={newShape.fillType}
        onChange={handleFillTypeChange}
      />
    </Paper>
  );
}
