import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import useLoadedMap from "Features/maps/hooks/useLoadedMap";
import useUpdateRoom from "Features/rooms/hooks/useUpdateRoom";
import useSceneModule from "Features/navigation/useSceneModule";
import useUpdateSample from "Features/samples/hooks/useUpdateSample";

import useUpdateRoomWithMaterials from "Features/rooms/hooks/useUpdateRoomWithMaterials";
import useCreateShapeMatRelation from "Features/shapeMatRelations/hooks/useCreateShapeMatRelation";

import {setIsDraggingLocateFab, setIsLocatingFab} from "../zonesSlice";
import {setLocateFabItem} from "../zonesSlice";

import {setOverRoomIdInMap} from "Features/rooms/roomsSlice";
import {setOverShapeIdInMap} from "Features/shapes/shapesSlice";

import {useDraggable, useDndMonitor} from "@dnd-kit/core";

import {Box, Typography} from "@mui/material";
import {AddLocation, LocationOn} from "@mui/icons-material";

import theme from "Styles/theme";
import useSelectedSurvey from "Features/surveys/hooks/useSelectedSurvey";

export default function FabLocate({scene, caplaEditor, item}) {
  const dispatch = useDispatch();

  // data

  const loadedMap = useLoadedMap();
  const selectedMapClientId = loadedMap?.id;
  const isLocatingFabState = useSelector((s) => s.zones.isLocatingFab);

  // data - room

  const overRoomIdInMap = useSelector((s) => s.rooms.overRoomIdInMap);
  const roomsMap = useSelector((s) => s.rooms.roomsMap);

  // data - shape

  const overShapeIdInMap = useSelector((s) => s.shapes.overShapeIdInMap);
  const createShapeMatRelation = useCreateShapeMatRelation(scene);

  // data - func

  const updateRoom = useUpdateRoom();
  const updateRoomWithMaterials = useUpdateRoomWithMaterials();
  const updateSample = useUpdateSample();

  // data - dnd

  const {attributes, listeners, setNodeRef, transform, isDragging} =
    useDraggable({
      id: `localize-${item?.type}`,
    });
  useDndMonitor({
    onDragEnd: handleDragEnd,
    onDragStart: handleDragStart,
    onDragMove: handleDragMove,
  });

  // helper - label / color

  let label = item?.name;
  let bgcolor = theme.palette.primary.main;
  if (item?.type === "ROOM") {
    label = item?.name;
    bgcolor = theme.palette.sceneModule.room;
  } else if (item?.type === "MATERIAL") {
    label = item?.name;
    bgcolor = theme.palette.sceneModule.material;
  } else if (item?.type === "SAMPLE") {
    label = item?.num;
    bgcolor = theme.palette.sceneModule.sample;
  }

  // helpers - dnd

  const deltaX = Math.abs(transform?.x ?? 0);
  const deltaY = Math.abs(transform?.y ?? 0);
  const maxDelta = Math.max(deltaX, deltaY);
  const isLocating = maxDelta > 20; // start the creation process

  // effect

  useEffect(() => {
    if (isLocatingFabState !== isLocating) {
      dispatch(setIsLocatingFab(isLocating));
    }
  }, [isLocating, isLocatingFabState]);

  // handlers

  function handleDragStart(event) {
    dispatch(setIsDraggingLocateFab(true));
    dispatch(setLocateFabItem(item));
  }

  function handleDragMove(event) {
    if (!isLocating) return;
    const {activatorEvent, delta} = event;
    const _x = activatorEvent.clientX + delta.x;
    const _y = activatorEvent.clientY + delta.y;
    const {x, y} = caplaEditor.zonesEditor.getPointerCoords(_x, _y, {
      outputInStageContainer: true,
    });
    //
    let roomId = null;
    let shapeId = null;
    const node = caplaEditor.zonesEditor.stage.getIntersection({x, y});
    if (node) {
      const nodeType = node.getAttr("overNodeType");
      const nodeId = node.getAttr("overNodeId");
      if (nodeType === "ROOM_LABEL") {
        roomId = nodeId;
      } else if (nodeType === "SHAPE") {
        shapeId = nodeId;
      }
    }
    if (roomId !== overRoomIdInMap) {
      dispatch(setOverRoomIdInMap(roomId));
    }
    if (shapeId !== overShapeIdInMap) {
      dispatch(setOverShapeIdInMap(shapeId));
    }
  }

  async function handleDragEnd(event) {
    if (!isLocating) return;
    dispatch(setIsDraggingLocateFab(false));
    console.log("ListItemSample handleDragEnd event", event);
    const {activatorEvent, delta} = event;
    const _x = activatorEvent.clientX + delta.x;
    const _y = activatorEvent.clientY + delta.y;
    const {x, y} = caplaEditor.zonesEditor.getPointerCoords(_x, _y, {
      asImageRatio: true,
    });
    if (item.type === "ROOM") {
      console.log("[FabLocate] updateRoom", item);
      const allCoords = item.coords;
      const coords = allCoords
        ? allCoords.filter((c) => c.mapClientId !== selectedMapClientId)
        : [];
      coords.push({mapClientId: selectedMapClientId, x, y});
      const updates = {
        id: item.id,
        roomsGroupId: item.roomsGroupId,
        coords,
      };
      updateRoom(updates);
    } else if (item.type === "MATERIAL") {
      if (overRoomIdInMap) {
        const room = roomsMap[overRoomIdInMap];
        await updateRoomWithMaterials(room, [item]);
      } else if (overShapeIdInMap) {
        const relation = {
          sceneId: scene.id,
          shapeId: overShapeIdInMap,
          materialId: item.id,
        };
        await createShapeMatRelation(relation);
      }
    } else if (item.type === "SAMPLE") {
      console.log("[FabLocate] updateSample", item);
      const allCoords = item.coords;
      const coords = allCoords
        ? allCoords.filter((c) => c.mapClientId !== selectedMapClientId)
        : [];
      coords.push({mapClientId: selectedMapClientId, x, y});
      const updates = {
        id: item.id,
        surveyId: item.surveyId,
        coords,
      };
      updateSample(updates);
    }
    // end
    dispatch(setOverRoomIdInMap(null));
    dispatch(setOverShapeIdInMap(null));
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          cursor: "grab",
          bgcolor: isDragging ? "white" : bgcolor,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10,
        }}
      >
        <AddLocation sx={{color: "white"}} />
      </Box>
    </Box>
  );
}
