export default function getBboxInImageFromBboxInStage(bbox, zonesEditor) {
  const imageNode = zonesEditor.imageManager.imageNode;
  const imageSize = zonesEditor.imageManager.imageSize;

  const x = (bbox.x - imageNode.x()) / imageSize.width;
  const y = (bbox.y - imageNode.y()) / imageSize.height;
  const width = bbox.width / imageSize.width;
  const height = bbox.height / imageSize.height;

  return {x, y, width, height};
}
