import {client} from "API/capla360";
import remoteToStateShapeMatRelation from "./utils/remoteToStateShapeMatRelation";
import stateToRemoteShapeMatRelation from "./utils/stateToRemoteShapeMatRelation";

export async function fetchShapeMatRelationsService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/shape_mat_relations/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedShapeMatRelations = response.data;
  const shapeMatRelations = sharedShapeMatRelations.map(
    remoteToStateShapeMatRelation
  );
  return {items: shapeMatRelations, sceneId};
}

export async function createShapeMatRelationService({
  accessToken,
  shapeMatRelation,
  fromScopeId,
}) {
  const sceneId = shapeMatRelation.sceneId;
  const newShapeMatRelation = stateToRemoteShapeMatRelation(shapeMatRelation);
  const response = await client.post(
    `scenes/${sceneId}/shape_mat_relations/`,
    newShapeMatRelation,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        fromScope: fromScopeId,
      },
    }
  );
  const remoteShapeMatRelation = response.data;
  const item = remoteToStateShapeMatRelation(remoteShapeMatRelation);
  return {item, fromScopeId};
}

export async function fetchShapeMatRelationService({
  accessToken,
  sceneId,
  shapeMatRelationId,
}) {
  const response = await client.get(
    `/scenes/${sceneId}/shape_mat_relations/${shapeMatRelationId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteShapeMatRelation = response.data;
  const item = remoteToStateShapeMatRelation(remoteShapeMatRelation);
  return {item, sceneId};
}

export async function updateShapeMatRelationService({accessToken, updates}) {
  console.log("[updateShapeMatRelationService] updates", updates);
  const sceneId = updates.sceneId;
  const shapeMatRelationId = updates.id;
  const updatesForRemote = stateToRemoteShapeMatRelation(updates);
  const response = await client.patch(
    `/scenes/${sceneId}/shape_mat_relations/${shapeMatRelationId}/`,
    updatesForRemote,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedShapeMatRelation = response.data;
  const item = remoteToStateShapeMatRelation(updatedShapeMatRelation);
  return {item};
}

export const deleteShapeMatRelationService = async ({
  accessToken,
  shapeMatRelationId,
  sceneId,
}) => {
  await client.delete(
    `/scenes/${sceneId}/shape_mat_relations/${shapeMatRelationId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return shapeMatRelationId;
};
