import React from "react";

import {Box, Dialog, DialogTitle, DialogContent} from "@mui/material";

import {CompactPicker} from "react-color";

export default function DialogSelectorColor({color, onChange, open, onClose}) {
  console.log("[DialogSelectorColor] open", open);
  // strings

  const titleS = "Select a color";

  // handlers - dialog

  function handleCloseDialog() {
    onClose();
  }

  // handlers - color

  function handleColorChange(color) {
    onChange(color.hex);
    onClose();
  }

  // effects

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>{titleS} </DialogTitle>
      <DialogContent>
        <Box sx={{flexGrow: 1, display: "flex", alignItems: "center"}}>
          <Box sx={{p: 2, bgcolor: color}}>
            <CompactPicker onChange={handleColorChange} color={color} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
