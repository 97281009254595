import {List, ListItemButton, Box, Typography} from "@mui/material";

import ListItemSample from "./ListItemSample";

export default function ListSamples({
  scene,
  caplaEditor,
  samples,
  selection,
  onClick,
  viewerContext,
  onDragEnd,
  onFocusClick,
}) {
  // handlers

  function handleSampleClick(sample) {
    onClick(sample);
  }

  function handleFocusClick(sample) {
    onFocusClick(sample);
  }

  function handleDragEnd(event) {
    onDragEnd(event);
  }

  return (
    <List>
      {samples.map((sample) => {
        const selected = selection?.includes(sample.id);

        return (
          <ListItemSample
            scene={scene}
            caplaEditor={caplaEditor}
            key={sample.id}
            sample={sample}
            selected={selected}
            onClick={() => handleSampleClick(sample)}
            viewerContext={viewerContext}
            onDragEnd={handleDragEnd}
            onFocusClick={() => handleFocusClick(sample)}
          />
        );
      })}
    </List>
  );
}
