import {
  Home,
  ViewInAr as Admin,
  ContentCopy as Data,
  Construction as Build,
  Error as Issues,
  ViewInAr as Measurements,
  //DataObject as ElementTypes,
  Category as ElementTypes,
  ViewInAr as Viewer3D,
  Image as Zones,
  VerticalSplit as Sectors,
  Adjust as Markers,
  CheckCircle as Progress,
  LocalGroceryStore as Ressources,
  ViewTimeline as Phases,
  Palette as EditMeasurements,
  Layers as Packages,
  Folder as Issuesets,
  TableChart as Datasets,
  //ContentCopy as Inputs,
  PictureAsPdf as Inputs,
  MeetingRoom as Rooms,
  Accessibility as Assistant,
  PictureAsPdf as AnnotatedPdf,
  AppShortcut as Bimapps,
  Launch as Sharedboxes,
  AutoStories as Library,
  FormatColorFill as Materials,
  Euro as Pricings,
  Update as Versions,
  Palette as Onboarding,
  Calculate as Relations,
  SettingsSuggest as Procedures,
  Filter as Views,
  FileCopy as Reports,
  DocumentScanner as Report,
  Troubleshoot as Samples,
  Event as Surveys,
  GroupWork as Scopes,
  TableChart as Articles,
  Pentagon as Shape,
} from "@mui/icons-material";

export default function useSceneModuleIconMap(options) {
  const inheritFill = options?.inheritFill;
  return {
    HOME: (
      <Home
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ASSISTANT: (
      <Assistant
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ADMIN: (
      <Admin
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    BUILD: (
      <Build
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    INPUTS: (
      <Inputs
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    DATA: (
      <Data
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    VIEWER_3D: (
      <Viewer3D
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    MARKERS: (
      <Markers
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ZONES: (
      <Zones
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    SECTORS: (
      <Sectors
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ROOMS: (
      <Rooms
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    PHASES: (
      <Phases
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    VERSIONS: (
      <Versions
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    MEASUREMENTS: (
      <Measurements
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    EDIT_MEASUREMENTS: (
      <EditMeasurements
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ELEMENT_TYPES: (
      <ElementTypes
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    RESSOURCES: (
      <Ressources
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    PACKAGES: (
      <Packages
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ISSUESETS: (
      <Issuesets
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ISSUES: (
      <Issues
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    PROGRESS: (
      <Progress
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    DATASETS: (
      <Datasets
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ANNOTATED_PDF: (
      <AnnotatedPdf
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    BIMAPPS: (
      <Bimapps
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    SHAREDBOXES: (
      <Sharedboxes
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    LIBRARY: (
      <Library
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    MATERIALS: (
      <Materials
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    PRICINGS: (
      <Pricings
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ONBOARDING: (
      <Onboarding
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    RELATIONS: (
      <Relations
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    PROCEDURES: (
      <Procedures
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    VIEWS: (
      <Views
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    REPORTS: (
      <Reports
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    REPORT: (
      <Report
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    SURVEYS: (
      <Surveys
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    SAMPLES: (
      <Samples
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    SCOPES: (
      <Scopes
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    ARTICLES: (
      <Articles
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
    SHAPES: (
      <Shape
        fontSize="small"
        color="inherit"
        sx={{...(inheritFill && {fill: "inherit"})}}
      />
    ),
  };
}
