import Konva from "konva";

export default class PolygonsManager {
  constructor({zonesEditor}) {
    this.zonesEditor = zonesEditor;
    this.polygonNodesById = {};
    this.isDrawingMode = false;

    this.currentRect = null;
    this.startPos = null;

    this.anchors = [];
    this.editedShape = null;

    this.layerEditing = zonesEditor.layerEditing;
    this.stage = zonesEditor.stage;

    // Transformer for selected shapes
    this.transformer = new Konva.Transformer();
    this.layerEditing.add(this.transformer);

    // Bind event handlers
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleShapeClick = this.handleShapeClick.bind(this);
    this.handleShapeDblClick = this.handleShapeDblClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleStageClick = this.handleStageClick.bind(this);

    // Add keydown event listener
    window.addEventListener("keydown", this.handleKeyDown);
    //this.stage.on("click", this.handleStageClick);
  }

  enableDrawingMode() {
    this.isDrawingMode = true;
    this.stage.on("mousedown", this.handleMouseDown);
    this.stage.on("mousemove", this.handleMouseMove);
    this.stage.on("mouseup", this.handleMouseUp);
  }

  disableDrawingMode() {
    this.isDrawingMode = false;
    this.stage.off("mousedown", this.handleMouseDown);
    this.stage.off("mousemove", this.handleMouseMove);
    this.stage.off("mouseup", this.handleMouseUp);
  }

  handleMouseDown(event) {
    if (!this.isDrawingMode) return;

    const stagePos = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    const x = (stagePos.x - this.stage.x()) / scale;
    const y = (stagePos.y - this.stage.y()) / scale;
    this.startPos = {x, y};

    console.log("startDrawing at", x.toFixed(1), y.toFixed(2), "scale", scale);

    this.currentRect = new Konva.Rect({
      x,
      y,
      width: 0,
      height: 0,
      stroke: "black",
      strokeWidth: 2,
      draggable: true,
      fillPatternImage: this.createHatchingPattern(),
      fillPatternRepeat: "repeat",
    });

    this.layerEditing.add(this.currentRect);
  }

  handleMouseMove(event) {
    if (!this.isDrawingMode || !this.currentRect) return;

    const stagePos = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    const x = (stagePos.x - this.stage.x()) / scale;
    const y = (stagePos.y - this.stage.y()) / scale;
    const width = x - this.startPos.x;
    const height = y - this.startPos.y;

    this.currentRect.width(width);
    this.currentRect.height(height);
    this.stage.batchDraw();
  }

  handleMouseUp(event) {
    if (!this.isDrawingMode) return;

    // Convert the rectangle to a polygon
    this.convertRectToPolygon(this.currentRect);

    this.currentRect = null;
    this.startPos = null;

    this.zonesEditor.stopDrawing();
  }

  handleShapeDblClick(event) {
    console.log("dblclick event", event);
    const shape = event.target;
    this.transformer.nodes([shape]);
    this.layerEditing.batchDraw();
  }

  handleShapeClick(event) {
    const shape = event.target;
    console.log("click on shape", shape);
    //this.transformer.nodes([shape]);
    //this.layerEditing.batchDraw();
    this.drawAnchors(shape);
    this.editedShape = shape;
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.transformer.nodes([]);
      this.layerEditing.batchDraw();
    }
  }
  handleStageClick(event) {
    const clickedShape = event.target;
    const shapeId = clickedShape?._id ?? "-?-";
    console.log("clickedShape id", shapeId);
    // if (this.selectedShape?.id && this.selectedShape.id !== this.selectedShape) {
    //   this.removeAnchors();
    // }
  }

  convertRectToPolygon(rect) {
    const points = [
      rect.x(),
      rect.y(),
      rect.x() + rect.width(),
      rect.y(),
      rect.x() + rect.width(),
      rect.y() + rect.height(),
      rect.x(),
      rect.y() + rect.height(),
    ];

    const polygon = new Konva.Line({
      points: points,
      stroke: "black",
      strokeWidth: 2,
      closed: true,
      draggable: true,
      fillPatternImage: this.createHatchingPattern(),
      fillPatternRepeat: "repeat",
    });

    this.layerEditing.add(polygon);
    rect.destroy();
    this.layerEditing.batchDraw();

    polygon.on("pointerdblclick", this.handleShapeDblClick);
    polygon.on("click", this.handleShapeClick);
  }

  drawAnchors(shape) {
    try {
      this.removeAnchors();
      const points = shape.points();
      for (let i = 0; i < points.length; i += 2) {
        const anchor = new Konva.Circle({
          x: points[i],
          y: points[i + 1],
          radius: 5,
          fill: "red",
          draggable: true,
          dragOnTop: false,
        });

        anchor.on("dragmove", () => {
          points[i] = anchor.x();
          points[i + 1] = anchor.y();
          shape.points(points);
          this.layerEditing.batchDraw();
        });

        this.layerEditing.add(anchor);
        this.anchors.push(anchor);
      }
    } catch (e) {
      console.log("Error in drawAnchors", e);
    }
  }

  removeAnchors() {
    this.anchors.forEach((anchor) => anchor.destroy());
    this.anchors = [];
    this.layerEditing.batchDraw();
  }
}
