import {useState} from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import ListMaterials from "./ListMaterials";
import ListMaterialsMultipleWithGroups from "./ListMaterialsMultipleWithGroups";

import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";

export default function SelectorMaterial({
  materialsGroupsWithNomenclatureAndMaterials,
  onClose,
  selectedMaterial,
  onSelect,
  scene,
  caplaEditor,
}) {
  // string

  const title = "Sélectionnez un matériau";

  // state

  const [selectedMaterialsGroupId, setSelectedMaterialsGroupId] = useState(
    selectedMaterial?.materialsGroupId
  );

  // helpers - materials and groups

  const materialsGroups = (
    materialsGroupsWithNomenclatureAndMaterials ?? []
  ).map(({materialsGroup}) => materialsGroup);
  const nomenclatures = (materialsGroupsWithNomenclatureAndMaterials ?? []).map(
    ({nomenclature}) => nomenclature
  );
  const materials =
    (materialsGroupsWithNomenclatureAndMaterials ?? []).find(
      ({materialsGroup}) => materialsGroup.id === selectedMaterialsGroupId
    )?.materials || [];

  // helpers

  const selectedMaterialIds = selectedMaterial ? [selectedMaterial.id] : [];

  const selectedMaterialsGroup = materialsGroups.find(
    (g) => g.id === selectedMaterialsGroupId
  );

  const selection = selectedMaterial ? [selectedMaterial.id] : [];

  // handlers

  function handleClose() {
    onClose();
  }

  function handleGroupClick(group) {
    setSelectedMaterialsGroupId(group.id);
  }

  function handleMaterialClick(material) {
    onSelect(material.id);
  }

  return (
    <Box sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <SelectorGenericItemWithChips
          items={materialsGroups}
          selectedItem={selectedMaterialsGroup}
          onClick={handleGroupClick}
        />
        <ListMaterials
          materials={materials}
          selection={selection}
          onClick={handleMaterialClick}
        />
      </Box>
    </Box>
  );
}
