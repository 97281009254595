import {id} from "date-fns/locale";
import useUpdateRoom from "./useUpdateRoom";
import addMaterialToMaterialsData from "Features/materials/utils/addMaterialToMaterialsData";

export default function useUpdateRoomWithMaterials() {
  const updateRoom = useUpdateRoom();
  const update = async (room, materials) => {
    let newMaterialsData = room.materialsData ?? [];

    materials.forEach((material) => {
      newMaterialsData = addMaterialToMaterialsData(
        material.clientId,
        material.materialsGroupId,
        newMaterialsData
      );
    });

    const updates = {
      id: room.id,
      roomsGroupId: room.roomsGroupId,
      materialsData: newMaterialsData,
    };
    await updateRoom(updates);
  };

  return update;
}
