import {useSelector, useDispatch} from "react-redux";

import {setSelectedShapeId} from "../shapesSlice";

import useShapes from "../hooks/useShapes";
import useLoadedMap from "Features/maps/hooks/useLoadedMap";

import {Box} from "@mui/material";
import ListShapes from "./ListShapes";

export default function SectionShapesInLeftPanel() {
  const dispatch = useDispatch();

  // data

  const loadedMap = useLoadedMap();
  const shapes = useShapes({filterByMapClientId: loadedMap?.id});

  const selectedShapeId = useSelector((s) => s.shapes.selectedShapeId);

  // helper

  const selection = [selectedShapeId];

  // handlers

  function handleShapeClick(shape) {
    console.log("click", shape);
    const id = selectedShapeId === shape.id ? null : shape.id;
    dispatch(setSelectedShapeId(id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <ListShapes
        shapes={shapes}
        selection={selection}
        onClick={handleShapeClick}
      />
    </Box>
  );
}
