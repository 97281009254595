export default function getPointsBbox(points) {
  if (!points.length) {
    return {top: 0, left: 0, width: 0, height: 0};
  }

  let minX = points[0].x;
  let minY = points[0].y;
  let maxX = points[0].x;
  let maxY = points[0].y;

  points.forEach((point) => {
    if (point.x < minX) minX = point.x;
    if (point.x > maxX) maxX = point.x;
    if (point.y < minY) minY = point.y;
    if (point.y > maxY) maxY = point.y;
  });

  return {
    top: minY,
    left: minX,
    width: maxX - minX,
    height: maxY - minY,
  };
}
