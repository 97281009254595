import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createSceneService,
  createRemoteSceneService,
  deleteSceneService,
  updateSceneService,
  updateRemoteSceneService,
  updateRemoteScenePartialService,
  deleteRemoteModelService,
  fetchStoredScenesService,
  fetchStoredSceneService,
  getShareLinkService,
  updateRemoteLogosUrlService,
  fetchSharedSceneService,
  fetchSharedRemoteSceneService,
  fetchRemoteScenesService,
  fetchRemoteSceneService,
  fetchRemoteSceneOverviewService,
  fetchRemoteSceneLightService,
  deleteRemoteSceneService,
  updateSceneMembershipsService,
  deleteSceneMembershipsService,
  fetchSceneToSyncService,
} from "./services";

export const createScene = createAsyncThunk(
  "scenes/createScene",
  createSceneService
);

export const deleteScene = createAsyncThunk(
  "scenes/deleteScene",
  deleteSceneService
);
export const updateScene = createAsyncThunk(
  "scenes/updateScene",
  updateSceneService
);
export const fetchStoredScenes = createAsyncThunk(
  "scenes/fetchStoredScenes",
  fetchStoredScenesService
);
export const fetchStoredScene = createAsyncThunk(
  "scenes/fetchStoredScene",
  fetchStoredSceneService
);
export const updateRemoteLogosUrl = createAsyncThunk(
  "scenes/updateRemoteLogosUrl",
  updateRemoteLogosUrlService
);

export const getShareLink = createAsyncThunk(
  "scenes/getShareLink",
  getShareLinkService
);

export const fetchSharedScene = createAsyncThunk(
  "scenes/fetchSharedScene",
  fetchSharedSceneService
);

export const fetchSharedRemoteScene = createAsyncThunk(
  "scenes/fetchSharedRemoteScene",
  fetchSharedRemoteSceneService
);
// sceneToSync (remote data to compare with client data and define action to sync)

export const fetchSceneToSync = createAsyncThunk(
  "scenes/fetchSceneToSync",
  fetchSceneToSyncService
);

// remote scenes

export const createRemoteScene = createAsyncThunk(
  "scenes/createRemoteScene",
  createRemoteSceneService
);

export const fetchRemoteScenes = createAsyncThunk(
  "scenes/fetchRemoteScenes",
  fetchRemoteScenesService
);

export const fetchRemoteScene = createAsyncThunk(
  "scenes/fetchRemoteScene",
  fetchRemoteSceneService
);
export const fetchRemoteSceneOverview = createAsyncThunk(
  "scenes/fetchRemoteSceneOverview",
  fetchRemoteSceneOverviewService
);
export const fetchRemoteSceneLight = createAsyncThunk(
  "scenes/fetchRemoteSceneLight",
  fetchRemoteSceneLightService
);
export const updateRemoteScene = createAsyncThunk(
  "scenes/updateRemoteScene",
  updateRemoteSceneService
);
export const updateRemoteScenePartial = createAsyncThunk(
  "scenes/updateRemoteScenePartial",
  updateRemoteScenePartialService
);

export const deleteRemoteScene = createAsyncThunk(
  "scenes/deleteRemoteScene",
  deleteRemoteSceneService
);

export const deleteRemoteModel = createAsyncThunk(
  "scenes/deleteRemoteModel",
  deleteRemoteModelService
);

// memberships

export const updateSceneMemberships = createAsyncThunk(
  "scenes/updateSceneMemberships",
  updateSceneMembershipsService
);

export const deleteSceneMemberships = createAsyncThunk(
  "scenes/deleteSceneMemberships",
  deleteSceneMembershipsService
);

const scenesSlice = createSlice({
  name: "scenes",
  initialState: {
    // sync

    autoSync: true, // used to trigger sync every x sec. ButtonDialogSync component.
    sceneIsSyncing: false, // used to animate the sync button.
    sceneToSync: null, // contained basic data to compare remote & client versions.

    // search

    testScenesOnly: false,

    // scene creation

    connectSceneOnCreation: true,
    createdSceneClientId: null,
    // scene story
    readStory: undefined, // story read when viewer is in story mode
    demos: {}, //{sceneClientId:{status:"loaded"}}
    items: [], // {id,clientId,title,imageUrl,imageClientUrl}

    remoteScenes: {status: "idle", items: []}, // remote scenes only : stored scenes are removed from the list.

    isFetchingRemoteScenes: false, // set when we trigger fetchRemoteScene => display loading bimbox in home page.

    downloads: [], // [{sceneId,status,progress}], array to track remote download progress && avoid infinite loop in useScene.

    spaceScenesStatus: {}, // {sceneId:"loading","preloaded (models in state)", "loaded"(models in editor)},... used in useSpace.

    fetchScenesStatus: "idle", // (only local scenes) used for ?
    scenesInitialLoadingStatus: {}, // {sceneClientId,status}, status:"idle","loading","loaded"
    sceneClientId: undefined,
    remoteScene: null, // remote scene fetched from the server
    // scenesStatus: {}, // {sceneClientId:"idle"/"loading"} loading when model is being loaded. (conflict with scenesStatus of the viewer3D slice...)

    //sceneIsLoaded: false, // used to hide selection panel if the scene is not loaded.

    shareLinkMap: {}, // {entityId:shareLink}
    sharedScene: undefined, // shared scene from shared link
    sharedRemoteScene: {status: "idle", scene: undefined}, // shared scene used without stored data

    // clipping planes

    clippingPlanes: [], // [{origin,normal,id,name,sceneClientId}]
    clippingPlanesStatus: [], // [{clippingPlaneId,disabled}]

    // refresh date

    refreshDate: null, // date in iso Format. Refresh date of the list of models. Triggered by the refresh button in Files & Data. Used as a dependency in usedClientAndRemoteModel.

    // selection

    selectedSceneClientId: null,

    // edit

    isEditing: false,
    editedScene: {},
    newScene: {},
  },
  reducers: {
    setTestScenesOnly: (state, action) => {
      state.testScenesOnly = action.payload;
    },
    setAutoSync: (state, action) => {
      state.autoSync = action.payload;
    },
    setSceneIsSyncing: (state, action) => {
      state.sceneIsSyncing = action.payload;
    },
    setConnectSceneOnCreation: (state, action) => {
      state.connectSceneOnCreation = action.payload;
    },
    setCreatedSceneClientId: (state, action) => {
      state.createdSceneClientId = action.payload;
    },
    setSceneInitialLoadingStatus: (state, action) => {
      const {sceneClientId, status} = action.payload;
      state.scenesInitialLoadingStatus[sceneClientId] = status;
    },
    setReadStory: (state, action) => {
      state.readStory = action.payload;
    },
    setFetchScenesStatus: (state, action) => {
      state.fetchScenesStatus = action.payload;
    },
    setSceneClientId: (state, action) => {
      state.sceneClientId = action.payload;
    },
    setSpaceSceneStatus: (state, action) => {
      const {sceneId, status} = action.payload;
      state.spaceScenesStatus[sceneId] = status;
    },
    loadDemoScene: (state, action) => {
      const {scene} = action.payload;
      const demo = state.demos[scene.clientId];
      const status = demo?.status;
      if (status !== "loaded") state.items.push(scene);
    },
    setAnimations: (state, action) => {
      state.animations = action.payload;
    },
    setAnimationSelection: (state, action) => {
      const animationId = action.payload;
      state.animationSelection = animationId;
    },
    addAnimation: (state, action) => {
      state.animations.push(action.payload);
    },
    updateAnimation: (state, action) => {
      const newAnimation = action.payload;
      const animations = state.animations;
      const newAnimations = animations.map((a) => {
        if (a.id === newAnimation.id) {
          return newAnimation;
        } else {
          return a;
        }
      });
      state.animations = newAnimations;
    },

    // stories

    setStories: (state, action) => {
      state.stories = action.payload;
    },
    setStorySelection: (state, action) => {
      const storyId = action.payload;
      state.storySelection = storyId;
    },
    setStoryDefaultId: (state, action) => {
      const storyId = action.payload;
      state.storyDefaultId = storyId;
    },
    addStory: (state, action) => {
      state.stories.push(action.payload);
    },
    updateStory: (state, action) => {
      const newStory = action.payload;
      const stories = state.stories;
      const newStories = stories.map((a) => {
        if (a.id === newStory.id) {
          return newStory;
        } else {
          return a;
        }
      });
      state.stories = newStories;
    },

    // clippingPlanes

    setClippingPlanes: (state, action) => {
      const clippingPlanes = action.payload;
      const clippingPlanesStatus = clippingPlanes.map(({id}) => ({
        clippingPlaneId: id,
        disabled: true,
        edit: false,
      }));
      state.clippingPlanes = clippingPlanes;
      state.clippingPlanesStatus = clippingPlanesStatus;
    },
    addClippingPlane: (state, action) => {
      const newClippingPlane = action.payload;
      state.clippingPlanes.push(newClippingPlane);
    },
    deleteClippingPlane: (state, action) => {
      const cpId = action.payload;
      const cp = [...state.clippingPlanes];
      const newClippingPlanes = cp.filter((cp) => cp.id !== cpId);
      state.clippingPlanes = newClippingPlanes;
    },
    updateClippingPlane: (state, action) => {
      const updatedClippingPlane = action.payload;
      const newClippingPlanes = state.clippingPlanes.map((cp) => {
        if (cp.id === updatedClippingPlane.id) {
          return updatedClippingPlane;
        } else {
          return cp;
        }
      });
      state.clippingPlanes = newClippingPlanes;
    },
    updateClippingPlanesStatus: (state, action) => {
      const {clippingPlaneId, disabled, edit} = action.payload;
      let addCps = true;
      const newClippingPlanesStatus = state.clippingPlanesStatus.map((cps) => {
        if (cps.clippingPlaneId === clippingPlaneId) {
          addCps = false;
          const newCps = {...cps};
          if (typeof disabled === "boolean") newCps.disabled = disabled;
          if (typeof edit === "boolean") newCps.edit = edit;
          return newCps;
        } else {
          return cps;
        }
      });
      if (addCps)
        newClippingPlanesStatus.push({clippingPlaneId, disabled, edit});
      state.clippingPlanesStatus = newClippingPlanesStatus;
    },
    disableAllClippingPlanes: (state, action) => {
      const cps = [...state.clippingPlanesStatus];
      const newCps = cps.map((cp) => ({...cp, disabled: true}));
      state.clippingPlanesStatus = newCps;
    },
    // downloads
    updateDownloads: (state, action) => {
      const {sceneId, status, progress} = action.payload;
      const newDownloads = state.downloads.filter((d) => d.sceneId !== sceneId);
      newDownloads.push({sceneId, status, progress});
      state.downloads = newDownloads;
    },
    setRefreshDate: (state, action) => {
      state.refreshDate = action.payload;
    },
    // edit,
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setEditedScene: (state, action) => {
      state.editedScene = action.payload;
    },
    setNewScene: (state, action) => {
      state.newScene = action.payload;
    },
    // select
    setSelectedSceneClientId: (state, action) => {
      state.selectedSceneClientId = action.payload;
    },
  },
  extraReducers: {
    [fetchSceneToSync.fulfilled]: (state, action) => {
      state.sceneToSync = action.payload;
    },
    [fetchStoredScenes.fulfilled]: (state, action) => {
      const sceneClientIds = state.items.map((item) => item.clientId);
      const newScenes = action.payload;
      // remove duplicates
      let newScenesClientIds = newScenes.map((s) => s.clientId);
      newScenesClientIds = [...new Set(newScenesClientIds)];
      const scenes = newScenesClientIds.map((clientId) =>
        newScenes.find((s) => s.clientId === clientId)
      );
      // update state
      // scenes.forEach((scene) => {
      //   if (!sceneClientIds.includes(scene.clientId)) state.items.push(scene);
      // });
      // update fetchScenesStatus
      state.fetchScenesStatus = "success"; // for client data.
    },
    [fetchStoredScene.fulfilled]: (state, action) => {
      const scene = action.payload;
      const scenes = state.items.filter((s) => s.clientId !== scene.clientId);
      scenes.push(scene);
      //state.items = scenes;
    },
    [createScene.fulfilled]: (state, action) => {
      const {scene, ressource} = action.payload;

      // existing scenes

      const oldScene = state.items.find(
        (item) => item.clientId === scene.clientId
      );
      let newScene = {...scene};
      if (oldScene && oldScene.clientId) newScene = {...oldScene, ...scene};

      // add scene
      const newItems = state.items.filter(
        (item) => item.clientId !== scene.clientId || item.id !== scene.id
      );
      //
      console.log("debugafa 1408 add scene99", newScene);
      //
      state.items = [...newItems, newScene];

      // remove scene from remote scene.
      if (state.remoteScenes.items.map((s) => s.id).includes(scene.id)) {
        console.log("removing remotescene", scene.id);
        const items = state.remoteScenes.items.filter((i) => i.id !== scene.id);
        state.remoteScenes.items = items;
      }
      state.scenesInitialLoadingStatus[scene.clientId] = "loaded";
    },
    [deleteScene.fulfilled]: (state, action) => {
      const deletedScene = action.payload;
      const items = state.items.filter(
        (s) => s.clientId !== deletedScene.clientId
      );
      const remoteItems = state.remoteScenes?.items?.filter(
        (s) => s.clientId !== deletedScene.clientId
      );
      state.items = items;
      state.remoteScenes.items = remoteItems;
    },
    [updateScene.fulfilled]: (state, action) => {
      const updatedScene = action.payload;
      state.items = state.items.map((item) => {
        if (
          item.clientId === updatedScene.clientId ||
          (item.id && item.id === updatedScene.id)
        ) {
          return {...item, ...updatedScene};
        } else {
          return item;
        }
      });
      // edge case : update from a "isRemote" scene, in home page.
      const exists = Boolean(
        state.items.find((item) => item.clientId === updatedScene.clientId)
      );
      if (!exists) state.items.push(updatedScene);
      console.log("[STATE 44] scene", updatedScene);
    },
    [createRemoteScene.fulfilled]: (state, action) => {
      const updatedScene = action.payload;
      state.items = state.items.map((item) => {
        if (item.clientId === updatedScene.clientId) {
          return updatedScene;
        } else {
          return item;
        }
      });
      state.remoteScene = updatedScene;
    },
    [updateRemoteScene.fulfilled]: (state, action) => {
      console.log("STATE 44", action.payload);
      const updatedScene = action.payload;
      state.items = state.items.map((item) => {
        if (item.clientId === updatedScene.clientId) {
          //return updatedScene;
          return {...item, ...updatedScene};
        } else {
          return item;
        }
      });
      state.remoteScene = updatedScene;
      // edge case : update from a "isRemote" scene, in home page.
      const exists = Boolean(
        state.items.find((item) => item.clientId === updatedScene.clientId)
      );
      if (!exists) state.items.push(updatedScene);
    },
    [updateRemoteScenePartial.fulfilled]: (state, action) => {
      const result = action.payload;
      if (!result) return;
      const {sceneId, remoteScene, updatedFields} = result;
      state.items = state.items.map((item) => {
        if (item.id === sceneId) {
          const updates = {};
          updatedFields.forEach((field) => {
            updates[field] = remoteScene[field];
          });
          updates.version = remoteScene.version;
          return {...item, ...updates};
        } else {
          return item;
        }
      });
    },
    [fetchRemoteScene.fulfilled]: (state, action) => {
      const fetchResult = action.payload;
      const remoteScene = fetchResult?.remoteScene;
      console.log("[STATE] FETCHED REMOTE SCENE", remoteScene);
      if (!remoteScene) return;
      state.remoteScene = remoteScene; // ??
      //
      const newItems = state.items.map((scene) => {
        if (scene?.id !== remoteScene.id) {
          return scene;
        } else {
          return remoteScene;
        }
      });
      state.items = newItems;
    },
    [fetchRemoteSceneOverview.fulfilled]: (state, action) => {
      const result = action.payload;
      console.log("store sceneOverview in redux", result);
      if (!result) return;
      const stateSceneOverview = result.remoteSceneOverview;
      if (!stateSceneOverview) return;
      //
      console.log("debugafa 1408 update scene", result.remoteSceneOverview);
      //
      const newItems = state.items.map((scene) => {
        if (scene?.id !== stateSceneOverview.id) {
          return scene;
        } else {
          return {...scene, ...stateSceneOverview};
        }
      });
      const isNew = !state.items.find(
        (item) => item.id === stateSceneOverview.id
      );
      if (isNew) newItems.push(stateSceneOverview);
      console.log("debugafa 1408 set new scene items", newItems);
      state.items = newItems;
    },
    [fetchRemoteSceneLight.fulfilled]: (state, action) => {
      const stateSceneLight = action.payload;
      if (!stateSceneLight) return;
      //
      const oldScene = state.items.find((s) => s.id === stateSceneLight.id);
      //
      let newItems = state.items.filter(
        (scene) => scene?.id !== stateSceneLight.id
      );
      //
      let newScene = {...stateSceneLight};
      if (oldScene) newScene = {...oldScene, ...stateSceneLight};
      //
      console.log("store sceneLight in redux", newScene);
      //
      state.items = [...newItems, newScene];
    },
    "colorings/fetchColoringScene/fulfilled": (state, action) => {
      if (action.payload) {
        const {scene} = action.payload;
        console.log("COLORINGS");
        if (scene) {
          const prevScenes = state.items.filter((i) => i.id !== scene.id);
          const prevScene = state.items.find((i) => i.id === scene.id);
          let newScene = {...scene};
          if (prevScene) {
            newScene = {...prevScene, ...scene};
          }
          state.items = [...prevScenes, newScene];
        }
      }
    },
    [fetchRemoteScenes.pending]: (state) => {
      state.remoteScenes.status = "loading";
      state.isFetchingRemoteScenes = true;
    },
    [fetchRemoteScenes.fulfilled]: (state, action) => {
      const scenes = action.payload;
      console.log("[STATE], fetch scenes", scenes, "andMoveTo", [
        ...state.items,
      ]);
      const idsMap = {};
      let duplicatesCount = 0;
      if (Array.isArray(scenes)) {
        let newItems = [...state.items];
        scenes.forEach((scene) => {
          // test duplicates
          const duplicate = idsMap[scene.clientId];
          if (duplicate) duplicatesCount += 1;
          idsMap[scene.clientId] = "ok";
          //
          let newScene = {...scene};
          delete newScene.data;
          const oldScene = newItems.find(
            (item) => scene.id && item.id === scene.id
          );
          if (oldScene) {
            newScene = {...oldScene, ...newScene};
            //console.log("[STATE] replace scene", oldScene, "with", newScene);
            newItems = newItems.filter(
              (item) => item.clientId !== newScene.clientId
            );
          }
          newItems = [...newItems, newScene];
        });

        state.items = newItems;
        console.log("[STATE], fetch scenes, duplicatesCount", duplicatesCount);

        state.isFetchingRemoteScenes = false;

        const oldRemoteScenesIds = state.remoteScenes.items.map((s) => s.id);
        let newScenes = scenes.filter(
          (s) => !oldRemoteScenesIds.includes(s.id)
        );
        // remove duplicates
        let newScenesClientIds = [
          ...new Set([...newScenes.map((s) => s.clientId)]),
        ];
        newScenes = newScenesClientIds.map((clientId) =>
          newScenes.find((s) => s.clientId === clientId)
        );

        state.remoteScenes.items.push(...newScenes);
        state.remoteScenes.status = "loaded";
      }
    },
    [fetchRemoteScenes.rejected]: (state) => {
      state.remoteScenes.status = "failed";
    },
    // models
    [deleteRemoteModel.fulfilled]: (state, action) => {
      const payload = action.payload;
      if (!payload) return;
      const {modelRemoteId} = payload;
      const newRemoteScene = {...state.remoteScene};
      const oldModels = state.remoteScene?.models
        ? [...state.remoteScene.models]
        : [];
      const newModels = oldModels.filter((m) => m.remoteId !== modelRemoteId);
      newRemoteScene.models = newModels;
      state.remoteScene = newRemoteScene;
    },
    [updateRemoteLogosUrl.fulfilled]: (state, action) => {
      const updatedScene = action.payload;
      console.log("UPDATE REMOTE LOGOS URL", updatedScene);
      state.items = state.items.map((item) => {
        if (item.clientId === updatedScene.clientId) {
          return updatedScene;
        } else {
          return item;
        }
      });
    },
    // sharelink
    // [getShareLink.pending]: (state, action) => {
    //   const updatedScene = action.payload;
    //   state.shareLinkStatus[updatedScene.id] = "loading";
    // },
    [getShareLink.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const {shareLink, sceneId, scopeId} = action.payload;
      const entityId = scopeId ? scopeId : sceneId;
      state.shareLinkMap[entityId] = shareLink;
      // state.items = state.items.map((item) => {
      //   if (item.clientId === updatedScene.clientId) {
      //     return {...item, shareLink: updatedScene.shareLink};
      //   } else {
      //     return item;
      //   }
      // });
      // state.shareLinkStatus[updatedScene.id] = "loaded";
    },

    [fetchSharedScene.fulfilled]: (state, action) => {
      const data = action.payload;
      state.sharedScene = data;
    },
    [fetchSharedRemoteScene.fulfilled]: (state, action) => {
      const {scene, models} = action.payload;
      console.log(
        "[STATE] FETCHED SHARED REMOTE SCENE",
        scene,
        "models",
        models,
        "modelsMeasurements",
        models
          .filter((m) => m.type === "MEASUREMENTS")
          .map((m) => m.measurementsData)
      );
      state.sharedRemoteScene = {
        status: "loaded",
        scene,
      };
      if (!state.items.find((s) => s.id === scene.id)) state.items.push(scene);
      state.sceneClientId = scene.clientId;
    },

    [updateSceneMemberships.fulfilled]: (state, action) => {
      const {scene} = action.payload;
      console.log("update membership", scene);
    },
    [deleteSceneMemberships.fulfilled]: (state, action) => {
      const {scene} = action.payload;
      console.log("delete membership", scene);
    },
  },
});

// actions exports

export const {
  //
  setTestScenesOnly,
  //
  setAutoSync,
  setSceneIsSyncing,
  setConnectSceneOnCreation,
  setCreatedSceneClientId,
  //
  setSceneInitialLoadingStatus,
  setFetchScenesStatus,
  setSceneClientId,
  setScenesStatus,
  setSpaceSceneStatus,
  loadDemoScene,
  setReadStory,
  setAnimations,
  setAnimationSelection,
  addAnimation,
  updateAnimation,
  //
  setStories,
  setStorySelection,
  setStoryDefaultId,
  addStory,
  updateStory,
  //
  updateDownloads,
  //
  setClippingPlanes,
  addClippingPlane,
  deleteClippingPlane,
  updateClippingPlane,
  updateClippingPlanesStatus,
  disableAllClippingPlanes,
  //
  setRefreshDate,
  //
  setEditedScene,
  setIsEditing,
  setNewScene,
  //
  setSelectedSceneClientId,
} = scenesSlice.actions;

export default scenesSlice.reducer;
