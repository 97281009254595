export default function getCoordsFromMarkerNode(groupNode, stage) {
  // scale

  const scale = groupNode.getAttr("savedScale");

  // type

  const type = groupNode.getAttr("nodeType");

  // x, y
  const labelGroupNode = groupNode.children[0];
  const triangleNode = groupNode.children[1];
  const backgroundNode = labelGroupNode.children[0];

  // refNode
  let refNode = triangleNode;
  if (["ROOM_LABEL"].includes(type)) refNode = backgroundNode;
  console.log("[getCoordinatesFromMarkerNode] refNode", type, refNode);

  // edge case
  if (!refNode) return {x: 0, y: 0};

  const absolutePosition = refNode.absolutePosition();

  // Get the stage transformations
  const stageScale = stage.scaleX(); // Assuming uniform scaling
  const stagePosition = stage.position();

  // Calculate the position of nodeA in the context of the stage
  const newX = (absolutePosition.x - stagePosition.x) / stageScale;
  const newY = (absolutePosition.y - stagePosition.y) / stageScale;

  return {x: newX, y: newY, scale};
}
