import {useDispatch, useSelector} from "react-redux";

import {setSelectedSampleId} from "../samplesSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";

import useSamples from "../hooks/useSamples";

import {Box, List, ListItemButton, Typography} from "@mui/material";

import ListSamples from "./ListSamples";
import useSelectedZone from "Features/zones/hooks/useSelectedZone";
import {setSelectedFixedViewer} from "Features/ui/uiSlice";

export default function SectionSamplesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedSampleId = useSelector((s) => s.samples.selectedSampleId);

  const samples = useSamples({
    withMaterial: true,
    filterByScope: true,
    sortByNum: true,
  });

  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);

  // helpers - viewerContext

  const viewerContext = {
    selectedFixedViewer,
    selectedZoneId,
  };

  // handlers

  function handleClick(sample) {
    const id = sample?.id === selectedSampleId ? null : sample?.id;
    dispatch(setSelectedSampleId(id));
  }

  function handleDragEnd(event) {
    try {
      if (!event) return;
      const {x, y} = caplaEditor.zonesEditor.getPointerCoords(event.x, event.y);
      caplaEditor.zonesEditor.addCircle({x, y});
    } catch (e) {
      console.log("SectionSamplesInLeftPanel handleDragEnd error", e);
    }
  }

  function handleFocusClick(sample) {
    const hasCoords = sample.coords?.length > 0;
    const zoneId0 = sample.coords[0]?.mapClientId;
    const coords = sample?.coords.find((c) => c.mapClientId === selectedZoneId);
    const zoneViewerIsOpened = selectedFixedViewer === "ZONE";
    if (!zoneViewerIsOpened) {
      dispatch(setSelectedFixedViewer("ZONE"));
    }
    if (!coords && hasCoords) {
      dispatch(setSelectedZoneId(zoneId0));
    }
    if (coords && zoneViewerIsOpened) {
      caplaEditor.zonesEditor.focusOnMarker(coords.x, coords.y);
    }
  }

  return (
    <ListSamples
      scene={scene}
      caplaEditor={caplaEditor}
      samples={samples}
      selection={[selectedSampleId]}
      viewerContext={viewerContext}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
      onFocusClick={handleFocusClick}
    />
  );
}
