import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSelector} from "react-redux";
import getItemsMapByClientId from "Utils/getItemsMapByClientId";

export default function useSamples(options) {
  // options

  const variant = options?.variant;
  const filterByScope = options?.filterByScope;
  const withMaterial = options?.withMaterial;
  const sortByNum = options?.sortByNum;

  // data

  const samplesMap = useSelector((s) => s.samples.samplesMap);
  const scope = useSelectedScope();

  // data - relations

  const materialsMap = useSelector((s) => s.materials.materialsMap);

  // samples

  let samples = Object.values(samplesMap);

  // samples - filters

  if (filterByScope) {
    samples = samples.filter(
      (s) => s.scopeId === scope?.id || s.scopeId === "all"
    );
  }

  // samples - relations

  if (withMaterial) {
    const materials = Object.values(materialsMap);
    const materialsByClientId = getItemsMapByClientId(materials);
    samples = samples.map((sample) => {
      const material = sample.materialClientId
        ? materialsByClientId[sample.materialClientId]
        : null;
      return {...sample, material};
    });
  }

  // samples - sort

  if (sortByNum) {
    samples = samples.sort((a, b) => a.num.localeCompare(b.num));
  }

  // variant

  if (variant === "byRoomClientId") {
    const byRoom = {};
    samples.forEach((sample) => {
      const roomClientId = sample.roomClientId;
      if (!byRoom[roomClientId]) {
        byRoom[roomClientId] = [];
      }
      byRoom[roomClientId].push(sample);
    });
    return byRoom;
  }

  return samples;
}
