export default function useKvtMaterial(data) {
  //

  const materialsNomenclature = data?.materialsNomenclature || [];
  const roomsGroupsWithRooms = data?.roomsGroupsWithRooms || [];

  // materialCategoryTypes

  let types =
    materialsNomenclature?.tree?.items
      ?.map((item) => item.type)
      .filter(Boolean) || [];
  types = [...new Set(types)];
  const typesOptions = types.map((type) => ({key: type, name: type}));

  //

  const template = {
    key: "kvfMaterial",
    groups: [
      {code: "INFO", label: "Info"},
      {code: "ROOMS", label: "Pièces"},
    ],
    fields: [
      {
        group: "INFO",
        key: "identification",
        type: "section",
        name: "Identification",
      },
      {group: "INFO", key: "name", type: "text", name: "Nom"},
      {group: "INFO", key: "code", type: "text", name: "Code"},
      {group: "INFO", key: "color", type: "color", name: "Couleur"},
      {group: "INFO", key: "category", type: "section", name: "Catégorie"},
      {
        group: "INFO",
        key: "materialCategory",
        type: "materialCategory",
        name: "Catégorie",
        materialsNomenclature,
      },
      {
        group: "INFO",
        key: "categoryType",
        type: "selector",
        name: "Matériau / Produit",
        options: typesOptions,
      },
      {group: "INFO", key: "categoryCode", type: "categoryCode", name: "Code"},

      {
        group: "INFO",
        key: "location",
        type: "section",
        name: "Localisation",
      },
      {
        group: "INFO",
        key: "fwc",
        type: "selector",
        name: "Sol / Mur / Plafond",
        options: [
          {key: "FLOOR", name: "Sol"},
          {key: "WALL", name: "Mur"},
          {key: "CEILING", name: "Plafond"},
          {key: "OTHER", name: "Autre"},
        ],
      },
      {
        group: "ROOMS",
        key: "roomsIds",
        type: "roomsIds",
        name: "Pièces",
        roomsGroupsWithRooms,
      },
    ],
  };

  return template;
}
