import {useDispatch} from "react-redux";

import {setIsDrawingInZoneViewer} from "../zonesSlice";

import {Box, Paper, IconButton} from "@mui/material";
import {Pentagon as Polygon} from "@mui/icons-material";

export default function ButtonDrawPolygonInViewer({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  // handler

  function handleClick(e) {
    e.stopPropagation();
    //
    dispatch(setIsDrawingInZoneViewer(true));
    //
    caplaEditor.zonesEditor.startDrawing("POLYGON");
  }

  return (
    <Box
      sx={{
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        sx={{width: 30, height: 30, borderRadius: "4px"}}
        onClick={handleClick}
      >
        <Polygon fontSize="small" />
      </IconButton>
    </Box>
  );
}
