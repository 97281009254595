import getPointsFromFlatArray from "../utilsShapesManager/getPointsFromFlatArray";
import getClosestPointPositionAndDistance from "./getClosestPointPositionAndDistance";

export default function getTempAnchorPosition(
  mousePositionInMap,
  shapeNode,
  minDistanceToCorner
) {
  try {
    // edge cases
    if (!shapeNode || !mousePositionInMap) return;

    // threshold
    const threshold = 0.1;

    const flatArray = shapeNode.points();

    const points = getPointsFromFlatArray(flatArray);
    const {position, distanceToCorner, distanceNormalized, index} =
      getClosestPointPositionAndDistance(mousePositionInMap, points);

    if (
      distanceNormalized < threshold &&
      distanceToCorner > minDistanceToCorner
    ) {
      return {position, index};
    }
  } catch (e) {
    console.log("error", e);
  }
}
