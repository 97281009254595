import {client} from "API/capla360";
import remoteToStateShape from "./utils/remoteToStateShape";
import stateToRemoteShape from "./utils/stateToRemoteShape";

export async function fetchShapesService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/shapes/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedShapes = response.data;
  const shapes = sharedShapes.map(remoteToStateShape);
  return {items: shapes, sceneId};
}

export async function createShapeService({accessToken, shape, fromScopeId}) {
  const sceneId = shape.sceneId;
  const newShape = stateToRemoteShape(shape);
  const response = await client.post(`scenes/${sceneId}/shapes/`, newShape, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      fromScope: fromScopeId,
    },
  });
  const remoteShape = response.data;
  const item = remoteToStateShape(remoteShape);
  return {item, fromScopeId};
}

export async function fetchShapeService({accessToken, sceneId, shapeId}) {
  const response = await client.get(`/scenes/${sceneId}/shapes/${shapeId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteShape = response.data;
  const item = remoteToStateShape(remoteShape);
  return {item, sceneId};
}

export async function updateShapeService({accessToken, updates}) {
  console.log("[updateShapeService] updates", updates);
  const sceneId = updates.sceneId;
  const shapeId = updates.id;
  const updatesForRemote = stateToRemoteShape(updates);
  const response = await client.patch(
    `/scenes/${sceneId}/shapes/${shapeId}/`,
    updatesForRemote,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedShape = response.data;
  const item = remoteToStateShape(updatedShape);
  return {item};
}

export const deleteShapeService = async ({accessToken, shapeId, sceneId}) => {
  await client.delete(`/scenes/${sceneId}/shapes/${shapeId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return shapeId;
};
