import {useState} from "react";

import {useSelector} from "react-redux";

import useLoadedMap from "Features/maps/hooks/useLoadedMap";

import {ListItemButton, Box, Typography} from "@mui/material";

import FabLocate from "Features/zones/components/FabLocate";

export default function ListItemMaterialVariantLeftPanel({
  scene,
  material,
  onClick,
  selected,
  caplaEditor,
}) {
  // data

  const loadedMap = useLoadedMap();
  const isDragging = useSelector((s) => s.zones.isDraggingLocateFab);
  const locateFabItem = useSelector((s) => s.zones.locateFabItem);

  // state

  const [hover, setHover] = useState(false);

  // helpers

  const showFab =
    loadedMap && (hover || (isDragging && material.id === locateFabItem?.id));

  // helper - item fab

  const item = {...material, type: "MATERIAL"};

  // helper - shapesLabel

  const count = material?.shapes?.length;
  const shapesLabel = `x${count}`;

  // handlers

  function handleMouseEnter() {
    setHover(true);
  }
  function handleMouseLeave() {
    setHover(false);
  }

  function handleClick() {
    onClick(material);
  }

  return (
    <ListItemButton
      disableGutters
      divider
      selected={selected}
      onClick={handleClick}
      sx={{
        bgcolor: "common.white",
        p: 0,
        display: "flex",
        flexDirection: "column",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{px: 1, py: 0.5, width: 1, display: "flex", alignItems: "center"}}
      >
        <Box sx={{flexGrow: 1}}>
          <Typography sx={{fontSize: 12}}>{material?.name}</Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{fontSize: 12, color: "text.secondary"}}>
              {material?.code}
            </Typography>

            {/* <Typography
              sx={{
                fontSize: 12,
                color: noRooms ? "error.main" : "text.secondary",
              }}
            >
              {noRooms ? noRoomsS : roomsCountLabel}
            </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            //justifyContent: "center",
            color: "grey.400",
            transition: showFab ? "transform 0.3s ease-in-out" : "none",
            transform: showFab ? "translateX(10px)" : "translateX(0)",
          }}
        >
          {showFab && (
            <FabLocate scene={scene} caplaEditor={caplaEditor} item={item} />
          )}
        </Box>
      </Box>
      <Box sx={{width: 1, display: "flex", justifyContent: "end", p: 0.5}}>
        {count > 0 && (
          <Box
            sx={{
              bgcolor: material.color ?? "grey.300",
              borderRadius: 1,
              px: 0.5,
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                color: "white",
              }}
            >
              {shapesLabel}
            </Typography>
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}
