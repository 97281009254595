import theme from "Styles/theme";
import Konva from "konva";
import getCoordsFromMarkerNode from "./getCoordsFromMarkerNode";
import getCoordsInImageFromCoordsInStage from "../utilsZonesEditor/getCoordsInImageFromCoordsInStage";

import {lighten} from "@mui/material/styles";

export default function createMarkerNode({
  targetScale,
  zonesEditor,
  marker,
  stage,
  onClick,
  onMarkerChange,
  onMarkerCoordsChange,
}) {
  try {
    // const

    const layerDrag = zonesEditor.layerEditing;

    // helper showCounters
    let showCounters = zonesEditor.showCounters && marker.type === "ROOM_LABEL";

    // marker

    const {
      id,
      x: xAsImageRatio,
      y: yAsImageRatio,
      label,
      color,
      type,
      scale,
      mapClientId,
      isCluster,
      draggable,
      selected,
      over,
      counter,
    } = marker;

    const imageNode = zonesEditor.imageManager.imageNode;
    const imageSize = zonesEditor.imageManager.imageSize;
    const x = xAsImageRatio * imageSize.width + imageNode.x();
    const y = yAsImageRatio * imageSize.height + imageNode.y();

    // stage

    const stageScale = targetScale ?? stage.scaleX();

    // validation

    if (!id || !label) return;

    // constants

    const triangleRadiusCore = 10;
    const paddingCoreY = 5;
    const paddingCoreX = 10;
    const fontSizeCore = 14;

    // color

    let bgcolor = color;
    if (showCounters && !counter) bgcolor = lighten(color, 0.5);
    if (selected) bgcolor = theme.palette.marker.selected;
    if (over) bgcolor = theme.palette.marker.over;

    // props

    const addTriangle = ["SAMPLE", "OBSERVATION"].includes(type) && !isCluster;

    // text node

    const textNode = new Konva.Text({
      text: label,
      fontSize: fontSizeCore / stageScale,
      fill: "white",
    });

    textNode.setAttr("fontSizeCore", fontSizeCore);
    textNode.setAttr("overNodeType", type);
    textNode.setAttr("overNodeId", id);

    const textWidth = textNode.width();
    const textHeight = textNode.height();
    const labelDeltaY = addTriangle
      ? textHeight / 2 +
        paddingCoreY / stageScale +
        ((3 / 2) * triangleRadiusCore) / stageScale
      : 0;
    textNode.x(x - textWidth / 2);
    textNode.y(y - textHeight / 2 + labelDeltaY);

    // counter node

    const counterNodePosition = {
      x: x + textWidth / 2 + paddingCoreX / stageScale,
      y: y - textHeight / 2 - paddingCoreY / stageScale,
    };

    const counterCircleNode = new Konva.Circle({
      x: counterNodePosition.x,
      y: counterNodePosition.y,
      radius: 10 / stageScale,
      fill: bgcolor,
      strokeWidth: 1 / stageScale,
      stroke: "white",
    });

    const counterTextNode = new Konva.Text({
      x: counterNodePosition.x,
      y: counterNodePosition.y,
      text: counter ? counter : "-",
      fontSize: (fontSizeCore / stageScale) * 0.8,
      fill: "white",
      align: "center",
      verticalAlign: "middle",
    });
    counterTextNode.offsetX(counterTextNode.width() / 2);
    counterTextNode.offsetY(counterTextNode.height() / 2);

    // rectangle node

    const bgWidth = textWidth + (2 * paddingCoreX) / stageScale;
    const bgHeight = textHeight + (2 * paddingCoreY) / stageScale;
    const backgroundNode = new Konva.Rect({
      x,
      y,
      offsetX: bgWidth / 2,
      offsetY: bgHeight / 2 - labelDeltaY,
      width: bgWidth, // Add some padding
      height: bgHeight,
      fill: bgcolor,
      cornerRadius: 8 / stageScale,
    });
    backgroundNode.setAttr("overNodeType", type);
    backgroundNode.setAttr("overNodeId", id);

    // triangle node

    const triangleRadius = triangleRadiusCore / stageScale;
    const triangleHeight = (3 / 2) * triangleRadius;

    const triangleNode = new Konva.RegularPolygon({
      x: x,
      y: y,
      sides: 3,
      radius: triangleRadius,
      fill: color,
      offsetY: -triangleRadius,
      //rotation: 180,
    });

    // Label group (text + background)

    const labelGroupNode = new Konva.Group();
    //
    labelGroupNode.add(backgroundNode);
    labelGroupNode.add(textNode);

    if (showCounters && counter > 0) {
      labelGroupNode.add(counterCircleNode);
      labelGroupNode.add(counterTextNode);
    }

    // Group

    const groupNode = new Konva.Group({id, draggable});

    groupNode.add(labelGroupNode);
    if (addTriangle) groupNode.add(triangleNode);

    groupNode.setAttr("savedScale", scale ?? stageScale);
    groupNode.setAttr("nodeType", type);

    // debug

    const debugCircle = new Konva.Circle({
      x: x,
      y: y,
      radius: 3 / stageScale,
      fill: "red",
    });
    //groupNode.add(debugCircle);

    // ON CREATE

    const bbox = {
      x: x - (textWidth / 2) * stageScale,
      y:
        y -
        textHeight * stageScale -
        2 * paddingCoreY -
        (addTriangle ? (3 / 2) * triangleRadiusCore : 0),
      width: textWidth * stageScale + 2 * paddingCoreY,
      height: textHeight * stageScale + 2 * paddingCoreY,
    };

    const newMarker = {
      ...marker,
      bbox,
    };

    if (onMarkerChange) onMarkerChange(newMarker);

    // LISTENERS

    groupNode.on("tap click", () => {
      if (onClick) onClick(marker);
    });

    groupNode.on("mousedown touchstart", (e) => {
      e.evt.stopPropagation();
      zonesEditor.blockStageDraggable = true;
      stage.draggable(false);
    });

    groupNode.on("mouseup touchend", (e) => {
      zonesEditor.blockStageDraggable = false;
      stage.draggable(true);
    });

    groupNode.on("dragstart", (e) => {
      groupNode.moveTo(layerDrag);
    });

    groupNode.on("dragend", (e) => {
      try {
        const {x, y} = getCoordsFromMarkerNode(groupNode, stage);
        const coordsInImage = getCoordsInImageFromCoordsInStage(
          {x, y},
          zonesEditor
        );
        const newCoords = {
          ...coordsInImage,
          scale: stageScale,
          id: marker.id,
          mapClientId,
          type,
        };
        if (onMarkerCoordsChange) {
          console.log("dragend", newCoords);
          onMarkerCoordsChange(newCoords);
        }
      } catch (e) {
        console.log("error dragend", e);
      }
    });

    groupNode.on("mouseenter", () => {
      stage.container().style.cursor = "pointer";
    });

    groupNode.on("mouseleave", () => {
      stage.container().style.cursor = zonesEditor.stageCursor;
    });

    // RETURN

    return groupNode;
    //return labelGroupNode;
  } catch (e) {
    console.log("error creating marker node", e);
  }
}
