import useTranslation from "Features/translations/useTranslation";

export default function useSceneModuleNameMap() {
  // strings
  const {t} = useTranslation("navigation");

  const homeS = t("home");
  const assistantS = t("assistant");
  const adminS = t("admin");
  const buildS = t("build");
  const dataS = t("files");
  const inputsS = t("inputs");
  const nomenclatureS = "Nomenclature";
  const editMeasurementsS = t("editMeasurements");
  const measurementsS = t("measurements");
  const quantitiesS = "Quantities";
  const notesS = "Notes";
  const issuesS = t("issues");
  const issuesetsS = t("issuesets");
  const viewerPdfS = "Viewer PDF";
  const viewer3DS = t("viewer3D");
  const zonesS = t("zones");
  const buildingsS = t("buildings");
  const sectorsS = t("sectors");
  const roomsS = t("rooms");
  const markersS = t("markers");
  const ifcS = "IFC";
  const elementTypesS = t("types");
  const progressS = t("progress");
  const ressourcesS = t("ressources");
  const packagesS = t("packages");
  const phasesS = t("phases");
  const datasetsS = t("datasets");
  const annotatedPdfS = t("annotatedPdf");
  const bimappsS = t("bimapps");
  const sharedboxesS = t("sharedboxes");
  const libraryS = t("library");
  const materialsS = t("materials");
  const defaultS = "...";
  const pricingsS = t("pricings");
  const versionsS = t("versions");
  const onboardingS = t("onboarding");
  const relationsS = "Compositions";
  const proceduresS = "Procédures";
  const viewsS = "Vues";
  const reportsS = "Rapports";
  const reportS = "Rapport";
  const surveysS = "Visites";
  const samplesS = "Prélèvements";
  const scopesS = "Sous-bimboxes";
  const articlesS = "Articles";
  const shapeS = "Dessin";

  // helpers

  const labelMap = {
    HOME: homeS,
    ASSISTANT: assistantS,
    ADMIN: adminS,
    BUILD: buildS,
    DATA: dataS,
    NOMENCLATURE: nomenclatureS,
    MEASUREMENTS: measurementsS,
    EDIT_MEASUREMENTS: editMeasurementsS,
    QUANTITIES: quantitiesS,
    NOTES: notesS,
    ISSUES: issuesS,
    ISSUESETS: issuesetsS,
    VIEWER_PDF: viewerPdfS,
    VIEWER_3D: viewer3DS,
    DEFAULT: defaultS,
    MARKERS: markersS,
    ZONES: zonesS,
    SECTORS: sectorsS,
    BUILDINGS: buildingsS,
    ROOMS: roomsS,
    IFC: ifcS,
    ELEMENT_TYPES: elementTypesS,
    PROGRESS: progressS,
    RESSOURCES: ressourcesS,
    PACKAGES: packagesS,
    PHASES: phasesS,
    VERSIONS: versionsS,
    DATASETS: datasetsS,
    INPUTS: inputsS,
    ANNOTATED_PDF: annotatedPdfS,
    BIMAPPS: bimappsS,
    SHAREDBOXES: sharedboxesS,
    LIBRARY: libraryS,
    MATERIALS: materialsS,
    PRICINGS: pricingsS,
    ONBOARDING: onboardingS,
    RELATIONS: relationsS,
    PROCEDURES: proceduresS,
    VIEWS: viewsS,
    REPORTS: reportsS,
    REPORT: reportS,
    SAMPLES: samplesS,
    SURVEYS: surveysS,
    SCOPES: scopesS,
    ARTICLES: articlesS,
    SHAPES: shapeS,
  };

  return labelMap;
}
