import {useSelector} from "react-redux";
import {useMemo} from "react";

export default function useLoadedMap() {
  const zoneIdLoadedInViewer = useSelector((s) => s.zones.zoneIdLoadedInViewer);
  const models = useSelector((s) => s.viewer3D.models);

  const imageModel = useMemo(() => {
    const imageModels = models.filter((m) => m.type === "IMAGE");
    return imageModels.find(
      (m) => m.fromModel?.zoneId === zoneIdLoadedInViewer
    );
  }, [zoneIdLoadedInViewer, models]);

  return imageModel;
}
