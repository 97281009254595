import getPointerCoordsInStage from "./getPointerCoordsInStage";

export default function getZoneEditorBboxInStage(zoneEditor) {
  const scale = zoneEditor.stage.scaleX();
  const stage = zoneEditor.stage;
  const bbox = zoneEditor.stage.container().getBoundingClientRect();

  const topLeft = {x: bbox.top, y: bbox.left};
  const bottomRight = {x: bbox.bottom, y: bbox.right};
  const topLeftInStage = getPointerCoordsInStage(topLeft, stage, true);
  const bottomRightInStage = getPointerCoordsInStage(bottomRight, stage, true);

  return {
    x: topLeftInStage.x,
    y: topLeftInStage.y,
    width: bottomRightInStage.x - topLeftInStage.x,
    height: bottomRightInStage.y - topLeftInStage.y,
  };
}
