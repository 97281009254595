import {useDispatch} from "react-redux";
import {createShape} from "../shapesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useCreateShape() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  return async (shape) => {
    await dispatch(createShape({accessToken, shape}));
  };
}
