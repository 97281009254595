const markersSample = [
  {id: "0", x: 0, y: 0, label: "00000", variant: "DEFAULT"},
  {id: "1", x: 100, y: 100, label: "014310", variant: "DEFAULT"},
  {id: "2", x: 500, y: 500, label: "024310", variant: "DEFAULT"},
  {id: "3", x: 500, y: 300, label: "034310", variant: "DEFAULT"},
  {id: "4", x: 100, y: 234, label: "111111", variant: "DEFAULT"},
  {id: "5", x: 134, y: 0, label: "M1----", variant: "DEFAULT"},
  {id: "6", x: 120, y: 800, label: "-----M2", variant: "DEFAULT"},
];

export default markersSample;
