import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {fetchSamplesByScope} from "../samplesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchSamples() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const scopeId = useSelector((s) => s.scopes.selectedScopeId);

  useEffect(() => {
    if (scopeId && accessToken) {
      dispatch(fetchSamplesByScope({accessToken, scopeId}));
    }
  }, [scopeId, accessToken]);
}
