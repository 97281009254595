import testCoordsInBbox from "Utils/testCoordsInBbox";

export default function getRoomsInMap(mapClientId, rooms, options) {
  // options

  const inBbox = options?.inBbox;

  // main
  if (!rooms?.length > 0) return [];

  let roomsInMap = rooms.filter(
    (r) =>
      Array.isArray(r.coords) &&
      r.coords.map((c) => c?.mapClientId).includes(mapClientId)
  );

  // filters

  if (inBbox) {
    roomsInMap = roomsInMap.filter((r) => {
      const coords = r.coords.find((c) => c?.mapClientId === mapClientId);
      return testCoordsInBbox(coords, inBbox);
    });
  }

  return roomsInMap;
}
