import {Paper, Box} from "@mui/material";

import ButtonTestAddMarkers from "./ButtonTestAddMarkers";
import ButtonDownloadStageImage from "./ButtonDownloadStageImage";
import ButtonRefreshZoneInViewer from "./ButtonRefreshZoneInViewer";
import ButtonDebugZoneViewer from "./ButtonDebugZoneViewer";
import ButtonDrawPolygonInViewer from "./ButtonDrawPolygonInViewer";
import ButtonDrawScaleInViewer from "./ButtonDrawScaleInViewer";
import ButtonDrawRectangleInViewer from "./ButtonDrawRectangleInViewer";

export default function ToolbarZoneViewerTest({scene, caplaEditor}) {
  return (
    <Paper sx={{display: "flex"}}>
      <ButtonRefreshZoneInViewer scene={scene} caplaEditor={caplaEditor} />
      <ButtonDrawScaleInViewer scene={scene} caplaEditor={caplaEditor} />
      <ButtonDrawRectangleInViewer scene={scene} caplaEditor={caplaEditor} />
      <ButtonDrawPolygonInViewer scene={scene} caplaEditor={caplaEditor} />
      <ButtonTestAddMarkers caplaEditor={caplaEditor} />
      <ButtonDownloadStageImage caplaEditor={caplaEditor} />
      <ButtonDebugZoneViewer scene={scene} caplaEditor={caplaEditor} />
    </Paper>
  );
}
