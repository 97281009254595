import {useMemo, useRef} from "react";
import {useSelector} from "react-redux";

import useMapsByScene from "Features/maps/hooks/useMapsByScene";

import {List, ListItemButton, Box, Typography, IconButton} from "@mui/material";
import ListItemRoomVariantLeftPanel from "./ListItemRoomVariantLeftPanel";

import getItemRankFromNum from "Utils/getItemRankFromNum";
import debounce from "Utils/debounce";

import getItemsMapById from "Utils/getItemsMapById";

export default function ListRooms({
  scene,
  rooms,
  onClick,
  selection,
  onSelectedRoomParentIdChange,
  caplaEditor,
  roomsCounters,
}) {
  rooms = rooms.filter(Boolean);

  // data

  const pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const os = useSelector((s) => s.ui.openSections);
  const fixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const maps = useMapsByScene(scene, {filterByScope: true});
  const isDraggingLocateFab = useSelector((s) => s.zones.isDraggingLocateFab);

  // helper

  const isPdfViewerOpened =
    Boolean(pdfModelId) && os.fixedViewersBox && fixedViewer === "PDF";

  // helpers - items

  const mapsById = useMemo(() => getItemsMapById(maps), [maps]);
  const items = useMemo(() => {
    return rooms.map((room) => {
      const maps = (room.mapsClientIds || []).map((id) => mapsById[id]);
      const selected = selection?.includes(room?.id);
      const count = roomsCounters?.[room?.id];
      const isLeaf = !count > 0;
      const rank = getItemRankFromNum(room);
      const showFocus =
        isPdfViewerOpened &&
        room?.polygons?.filter((p) => pdfModelId === p.pdfModelId).length > 0;
      return {...room, selected, count, isLeaf, rank, showFocus, maps};
    });
  }, [rooms, selection, roomsCounters, isPdfViewerOpened, pdfModelId]);

  // handlers - other

  function handleClick(room) {
    onClick(room);
    const wasSelected = selection?.includes(room?.id);
    onSelectedRoomParentIdChange(wasSelected ? null : room.id); // keep the parent as the room => to create new room based on its parent.
  }

  return (
    <List
      sx={{
        width: 1,
        maxWidth: 1,
        bgcolor: "common.white",
        position: "relative",
      }}
      dense
      disablePadding
    >
      {items.map((room) => {
        return (
          <Box sx={{width: 1}} key={room.id}>
            <ListItemRoomVariantLeftPanel
              key={room.id}
              caplaEditor={caplaEditor}
              scene={scene}
              room={room}
              onClick={handleClick}
            />
          </Box>
        );
      })}
    </List>
  );
}
