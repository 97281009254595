// the blueprint is one object of the Konva stage used to print and download the image.
// its main props are its bbox that defines the crop area.
// it is related to a React component that displays a dark crop area.

import Konva from "konva";

import getBlueprintBbox from "./utilsZonesEditor/getBlueprintBbox";
import getStageDefaultScale from "./utilsZonesEditor/getStageDefaultScale";
import getImageDefaultPosition from "./utilsZonesEditor/getImageDefaultPosition";
import getBboxInStage from "./utilsZonesEditor/getBboxInStage";

export default class BlueprintManager {
  constructor({zonesEditor, blueprintProps}) {
    this.zonesEditor = zonesEditor;
    this.blueprintProps = blueprintProps;

    this.blueprintBboxInStage = {x: 0, y: 0, width: 0, height: 0};
    this.blueprintBboxInContainer = {x: 0, y: 0, width: 0, height: 0};

    this.addBlueprintNode();
    this.updateBlueprintBboxes();
  }

  addBlueprintNode() {
    const blueprintNode = new Konva.Rect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      fill: "white",
      stroke: "black",
      strokeWidth: 1,
    });
    this.zonesEditor.layerDefault.add(blueprintNode);
    this.zonesEditor.layerDefault.draw();
    //
    this.blueprintNode = blueprintNode;
  }

  updateBlueprintBboxes() {
    //
    const containerElement = this.zonesEditor.stage.container();
    const containerBbox = containerElement.getBoundingClientRect();

    // in window
    const {aspectRatio, padding, stroke} = this.blueprintProps;
    const blueprintBbox = getBlueprintBbox(
      containerElement,
      aspectRatio,
      padding,
      stroke
    );
    // in container
    this.blueprintBboxInContainer = {...blueprintBbox};
    this.blueprintBboxInContainer.x = blueprintBbox.x - containerBbox.x;
    this.blueprintBboxInContainer.y = blueprintBbox.y - containerBbox.y;

    // in stage
    this.blueprintBboxInStage = getBboxInStage(this.blueprintBboxInContainer, {
      stage: this.zonesEditor.stage,
      inputCoordsInStageContainer: true,
    });
  }

  updateBlueprintNode() {
    this.updateBlueprintBboxes();
    const {x, y, width, height} = this.blueprintBboxInStage;
    this.blueprintNode.x(x);
    this.blueprintNode.y(y);
    this.blueprintNode.width(width);
    this.blueprintNode.height(height);
  }

  resetBlueprintNode() {
    this.blueprintNode.x(0);
    this.blueprintNode.y(0);
    this.blueprintNode.width(0);
    this.blueprintNode.height(0);
  }

  initBlueprint() {
    this.zonesEditor.stage.position({x: 0, y: 0});

    // first set stage scale
    const defaultScale = getStageDefaultScale(
      this.zonesEditor.imageManager.imageSize,
      this.blueprintBboxInContainer
    );
    console.log("defaultScale", defaultScale);
    this.zonesEditor.setStageScale(defaultScale);

    // then refresh blueprint bbox
    this.updateBlueprintBboxes();

    // then set image position
    const imageDefaultPosition = getImageDefaultPosition(
      this.zonesEditor.imageManager.imageSize,
      this.blueprintBboxInStage
    );
    this.zonesEditor.imageManager.setImageNodePosition(imageDefaultPosition);
  }
}
