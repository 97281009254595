import {blue, red} from "@mui/material/colors";
import theme from "Styles/theme";

export default function useSceneModuleColorMap() {
  // strings

  // helpers

  const labelMap = {
    HOME: theme.palette.primary.flash,

    MEASUREMENTS: blue[500],
    EDIT_MEASUREMENTS: blue[500],

    MARKERS: blue[500],
    ZONES: theme.palette.sceneModule.map,
    SECTORS: blue[500],
    ROOMS: theme.palette.sceneModule.room,

    ELEMENT_TYPES: blue[500],
    PROGRESS: blue[500],

    PACKAGES: blue[500],
    PHASES: blue[500],
    VERSIONS: blue[500],

    INPUTS: blue[500],
    ANNOTATED_PDF: blue[500],

    MATERIALS: theme.palette.sceneModule.material,

    ONBOARDING: blue[500],

    VIEWS: blue[500],
    REPORTS: red[400],
    REPORT: red[500],
    SAMPLES: theme.palette.sceneModule.sample,
    SURVEYS: blue[500],
    SCOPES: blue[500],
    ARTICLES: blue[500],
    SHAPES: theme.palette.sceneModule.shape,

    DEFAULT: theme.palette.secondary.main,
  };

  return labelMap;
}
