import {useDispatch, useSelector} from "react-redux";

import {setFormTabRoom} from "../roomsSlice";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtRoom from "Features/kvform/hooks/useKvtRoom";

import getItemsMapById from "Utils/getItemsMapById";

export default function FormRoom({
  scene,
  room,
  rooms,
  maps,
  materialsGroupsWithNomenclatureAndMaterials,
  onParentChange,
  onChange,
  canEdit,
  hiddenFields = [],
  onNewMaterial,
}) {
  const dispatch = useDispatch();

  // template

  const data = {rooms, materialsGroupsWithNomenclatureAndMaterials, maps};
  const template = useKvtRoom(data);
  const onNewData = {material: onNewMaterial};

  // data

  const formTabRoom = useSelector((state) => state.rooms.formTabRoom);

  // helpers

  const roomsById = getItemsMapById(rooms);

  // item

  const item = {...room, mapsIds: room.mapsClientIds};

  item.parentRoom = roomsById[room.parentId];

  // handlers

  function handleGroupChange(group) {
    dispatch(setFormTabRoom(group));
  }

  function handleChange(changedRoom) {
    const newRoom = {
      ...room,
      ...changedRoom,
      mapsClientIds: changedRoom.mapsIds,
    };

    // parent

    const oldEditedParentRoomId = room.oldEditedParentRoomId ?? room.parentId;
    const originParentRoomId = room.originParentRoomId ?? room.parentId;
    const newParentRoomId = changedRoom.parentRoom?.id;

    if (newParentRoomId !== oldEditedParentRoomId) {
      let editedRoomParentChange = null;
      if (newParentRoomId !== originParentRoomId) {
        editedRoomParentChange = {
          originParentRoomId,
          targetParentRoomId: newParentRoomId,
          roomClientId: room.clientId,
        };
      }
      onParentChange(editedRoomParentChange);
    }

    newRoom.parentId = newParentRoomId;
    newRoom.originParentRoomId = originParentRoomId;
    newRoom.oldEditedParentRoomId = oldEditedParentRoomId;
    delete newRoom.parentRoom;
    //

    onChange(newRoom);
  }

  return room ? (
    <KeyValueForm
      scene={scene}
      item={item}
      template={template}
      onChange={handleChange}
      hiddenFields={hiddenFields}
      canEdit={canEdit}
      sizeKey={4}
      group={formTabRoom}
      onGroupChange={handleGroupChange}
      //
      onNewData={onNewData}
    />
  ) : (
    <div />
  );
}
