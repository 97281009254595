import Konva from "konva";

import getPointsInStageFromPointsInImage from "../utilsZonesEditor/getPointsInStageFromPointsInImage";
import getPointsFlatArray from "./getPointsFlatArray";

import createHatchingPattern from "./createHatchingPattern";

import theme from "Styles/theme";

export default function createShapeNode({
  zonesEditor,
  shape,
  onClick,
  onRightClick,
  onDblClick,
}) {
  try {
    // shape

    const {
      type2d,
      id,
      points,
      color,
      materialColor,
      fillType,
      selected,
      edited,
      over,
    } = shape;

    if (!type2d) return;

    // main

    const pointsInStage = getPointsInStageFromPointsInImage(
      points,
      zonesEditor
    );

    let computedColor = materialColor ?? color;
    if (selected) computedColor = theme.palette.shape.selected;
    if (edited) computedColor = theme.palette.shape.edited;
    if (over) computedColor = theme.palette.shape.over;

    let shapeNode;

    if (type2d === "POLYGON") {
      const pointsFlat = getPointsFlatArray(pointsInStage);

      shapeNode = new Konva.Line({
        id,
        points: pointsFlat,
        stroke: computedColor,
        strokeWidth: 2,
        closed: true,
        hitStrokeWidth: 7,
        shapeType: "POLYGON",
      });

      if (fillType === "SOLID") {
        shapeNode.fill(
          over ? theme.palette.shape.over : materialColor ?? color
        );
        shapeNode.opacity(0.5);
      } else {
        shapeNode.fillPatternImage(
          createHatchingPattern({
            color: over ? theme.palette.shape.over : materialColor ?? color,
            variant: fillType,
            space: 10 / zonesEditor.stage.scaleX(),
            lineWidth: 2 / zonesEditor.stage.scaleX(),
          })
        );
        shapeNode.fillPatternRepeat("repeat");
      }
    }

    // edge case

    if (!shapeNode) {
      return;
    }

    // listeners

    shapeNode.on("pointerclick", (e) => {
      const evt = e.evt;
      evt.stopPropagation(); // to avoid to trigger a 2nd click on the listener attached to the stage in the shapeManager.
      const isRightClick = evt.button === 2;
      console.log("[createShapeNode] shapeNode click", shape, evt);
      if (isRightClick) {
        if (onRightClick)
          onRightClick({
            shape,
            clickPosition: {x: evt.clientX, y: evt.clientY},
          });
      } else {
        if (onClick) onClick(shape);
      }
    });

    shapeNode.on("pointerdblclick", (e) => {
      console.log("[createShapeNode] shapeNode dblclick", shape);
      if (onDblClick) {
        onDblClick(shape);
      }
    });

    // custom attr

    shapeNode.setAttr("type", "SHAPE");

    //
    return shapeNode;
  } catch (e) {
    console.log("error creating shape node", e);
  }
}
