import theme from "Styles/theme";

export default function parseSampleFromStateToMap(sample, options) {
  const sampleInMap = {};

  // options

  const mapClientId = options?.mapClientId;
  const selected = options?.selected;
  const draggable = options?.draggable;

  // coords
  const coords = sample.coords?.find((c) => c.mapClientId === mapClientId);
  if (coords) {
    sampleInMap.x = coords.x;
    sampleInMap.y = coords.y;
    sampleInMap.scale = coords.scale;
    sampleInMap.mapClientId = mapClientId;
  }

  // label
  sampleInMap.label = sample.num;

  // variant
  sampleInMap.variant = "DEFAULT";
  sampleInMap.type = "SAMPLE"; // marker type
  // sampleInMap.sampleType = sample.sampleType; // not necessary
  sampleInMap.color =
    sample.sampleType === "SAMPLE"
      ? theme.palette.sceneModule.sample
      : theme.palette.sceneModule.sampleObservation;

  // id
  sampleInMap.id = sample.id;

  // other
  sampleInMap.selected = selected;
  sampleInMap.draggable = draggable;

  return sampleInMap;
}
