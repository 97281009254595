import useZonesByScene from "../hooks/useZonesByScene";

import {Box} from "@mui/material";

import ListZones from "./ListZones";

export default function SectionZonesInLeftPanel({scene, caplaEditor}) {
  // data

  const allZones = useZonesByScene(scene, {
    sortByPdf: true,
    filterByScope: true,
  });

  // helpers

  const zones = allZones;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        flexGrow: 1,
        justifyItems: "stretch",
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <ListZones zones={zones} caplaEditor={caplaEditor} />
    </Box>
  );
}
