import Konva from "konva";

import createShapeNode from "./utilsShapesManager/createShapeNode";

import ShapeEditor from "./ShapeEditor";

import PolygonsManager from "./PolygonsManager";
import ScaleManager from "./ScaleManager";
import RectangleManager from "./RectangleManager";

import getShapeNodePoints from "./utilsShapesManager/getShapeNodePoints";

export default class ShapesManager {
  constructor({
    zonesEditor,
    onEditScale,
    onShapeClick,
    onShapeDblClick,
    onShapeRightClick,
    onSaveEditedShape,
    onNewShape,
    onDeleteShape,
  }) {
    this.zonesEditor = zonesEditor;
    this.stage = zonesEditor.stage;

    this.lastCursor = null;

    this.newShape = {}; // used to store the new shape props (color,...)

    this.onShapeClick = onShapeClick;
    this.onShapeDblClick = onShapeDblClick;
    this.onShapeRightClick = onShapeRightClick;
    this.onSaveEditedShape = onSaveEditedShape;
    this.onDeleteShape = onDeleteShape;

    this.onNewShape = onNewShape;

    this.shapesNodesById = {};
    this.shapesById = {};

    this.shapeEditor = new ShapeEditor({
      zonesEditor,
      onEditedShapeIdChange: this.handleEditedShapeIdChange,
    });
    this.editedShapeId = null; // to track if we are editing something

    this.polygonsManager = new PolygonsManager({zonesEditor});
    this.scaleManager = new ScaleManager({zonesEditor, onEditScale});
    this.rectangleManager = new RectangleManager({zonesEditor});

    this.drawingMode = null; // used to store the current drawing mode

    this.stage.on("click", this.handleClick);

    window.addEventListener("keydown", (e) => {
      console.log("keydown", e.key);
      if (e.key === "Delete" || e.key === "Backspace") {
        if (this.editedShapeId) this.onDeleteShape(this.editedShapeId);
      }
    });
  }

  createShapesNodes = (shapes) => {
    try {
      // add shapes
      shapes.forEach((shape) => {
        const shapeNode = createShapeNode({
          zonesEditor: this.zonesEditor,
          shape,
          onClick: this.handleShapeClick,
          onDblClick: this.onShapeDblClick,
          onRightClick: this.onShapeRightClick,
        });

        if (shapeNode) {
          // attr
          shapeNode.setAttr("overNodeType", "SHAPE");
          shapeNode.setAttr("overNodeId", shape.id);

          // layer
          if (shape.edited || shape.selected) {
            this.zonesEditor.layerEditing.add(shapeNode);
          } else {
            this.zonesEditor.layerShapes.add(shapeNode);
          }

          // editor
          if (!shape.edited && shape.id === this.editedShapeId) {
            this.shapeEditor.stopEdition();
          }
          if (shape.edited) {
            this.shapeEditor.startEdition(shapeNode);
          }

          // cache
          this.shapesNodesById[shape.id] = shapeNode;
          this.shapesById[shape.id] = shape;
        }
      });
    } catch (error) {
      console.log("error createShapes", error);
    }
  };

  deleteAllShapesNodes = () => {
    this.zonesEditor.layerShapes.destroyChildren();
  };

  /*
   * Event handlers
   */

  handleShapeClick = (shape) => {
    console.log(
      "[ShapesManager] handleShapeClick",
      shape.id,
      this.editedShapeId
    );
    if (this.editedShapeId) {
      return;
    } else {
      this.onShapeClick(shape);
    }
  };

  handleClick = (event) => {
    const node = event.target;
    const type = node.getAttr("shapeType");
    console.log(
      "[ShapesManager] handleClick",
      type,
      "editedShapeId",
      this.editedShapeId
    );

    // edge case

    if (this.drawingMode) return;

    if (type === "POLYGON" && this.editedShapeId) return; // to prevent a polygon selection while editing another one.

    //
    if (["ANCHOR", "TEMP_ANCHOR", "POLYGON"].includes(type)) {
      return;
    } else {
      // save edited shape
      const editedShapeChanges = this.shapeEditor.stopEdition();
      console.log(
        "[ShapesManager] handleClick - edited changes",
        editedShapeChanges
      );

      if (editedShapeChanges) {
        this.onSaveEditedShape(editedShapeChanges);
      } else {
        this.onShapeClick();
      }

      //
    }
  };

  /*
   *  Drawing
   */

  setNewShape = (newShape) => {
    this.newShape = newShape;
  };

  enableDrawingMode = (mode) => {
    this.drawingMode = mode;
    this.lastCursor = this.stage.container().style.cursor;
    if (mode === "POLYGON") {
      this.polygonsManager.enableDrawingMode();
    } else if (mode === "SCALE") {
      this.scaleManager.enableDrawing();
    } else if (mode === "RECTANGLE") {
      this.rectangleManager.enableDrawing();
    }
  };

  disableDrawingMode = () => {
    console.log("[ShapesManager] disableDrawingMode");
    if (this.drawingMode === "POLYGON") {
      this.polygonsManager.disableDrawingMode();
    } else if (this.drawingMode === "SCALE") {
      this.scaleManager.disableDrawing();
    } else if (this.drawingMode === "RECTANGLE") {
      this.rectangleManager.disableDrawing();
    }
    //
    this.stage.container().style.cursor = this.lastCursor;
    this.drawingMode = null;
  };

  /*
   * Edition
   */

  stopEdition = () => {
    this.shapeEditor.stopEdition();
  };

  handleEditedShapeIdChange = (editedShapeId) => {
    console.log("[ShapesManager] handleEditedShapeIdChange", editedShapeId);
    this.editedShapeId = editedShapeId;
  };
}
