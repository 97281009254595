import {useDispatch} from "react-redux";
import {deleteShape} from "../shapesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteShape() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  return async ({shapeId, sceneId}) => {
    await dispatch(deleteShape({accessToken, shapeId, sceneId}));
  };
}
