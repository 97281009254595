import {useSelector, useDispatch} from "react-redux";

import {setSelectedMaterialsClientIds} from "../materialsSlice";
import {setSelectedMaterialsGroupId} from "../materialsSlice";
import {setSelectedMaterialCategoryId} from "../materialsSlice";

import useMaterialsGroupsWithNomenclatureAndMaterials from "../hooks/useMaterialsGroupsWithNomenclatureAndMaterials";
import useMaterialsByScene from "../hooks/useMaterialsByScene";
import useMaterialsGroupsByScene from "../hooks/useMaterialsGroupsByScene";
import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";

import {Box, Typography} from "@mui/material";

import ListMaterialsMultipleWithGroups from "./ListMaterialsMultipleWithGroups";
import SectionSelectorMaterialsGroup from "./SectionSelectorMaterialsGroup";
import ViewBarMaterials from "./ViewBarMaterials";

import ToggleButtonsMaterialsViewBy from "./ToggleButtonsMaterialsViewBy";
import SectionMaterials from "./SectionMaterials";
import SectionMaterialsByCategory from "./SectionMaterialsByCategory";
import SectionMaterialsByRoom from "./SectionMaterialsByRoom";

export default function SectionMaterialsInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // string

  const noGroupS = "Aucune liste de matériaux";

  // data

  const isSelectingMaterialsGroup = useSelector(
    (s) => s.materials.isSelectingMaterialsGroup
  );
  const materialsViewBy = useSelector((s) => s.materials.materialsViewBy);

  const options = {
    filterByScope: true,
    fetchMaterials: true,
    withShapeMatRelations: true,
  };
  const materialsGroupsWithNomenclatureAndMaterials =
    useMaterialsGroupsWithNomenclatureAndMaterials(scene, options);

  const materialsGroups = materialsGroupsWithNomenclatureAndMaterials.map(
    (g) => g.materialsGroup
  );

  const selectedMaterialsClientIds = useSelector(
    (s) => s.materials.selectedMaterialsClientIds
  );
  console.log("selectedMaterialsClientIds", selectedMaterialsClientIds);

  // helpers

  const noGroup = materialsGroups.length === 0;

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        justifyItems: "stretch",
      }}
    >
      <SectionSelectorMaterialsGroup
        scene={scene}
        variant="list"
        forceSelection={true}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 1,
        }}
      >
        <ToggleButtonsMaterialsViewBy />
      </Box>

      {noGroup && !isSelectingMaterialsGroup && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {noGroupS}
          </Typography>
        </Box>
      )}
      {!isSelectingMaterialsGroup && !noGroup && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: 1,
            minHeight: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: 1,
              minHeight: 0,
            }}
          >
            {materialsViewBy === "MATERIAL" && (
              <SectionMaterials scene={scene} caplaEditor={caplaEditor} />
            )}
            {materialsViewBy === "CATEGORY" && (
              <SectionMaterialsByCategory scene={scene} />
            )}
            {materialsViewBy === "ROOM" && (
              <SectionMaterialsByRoom scene={scene} />
            )}
          </Box>
        </Box>
      )}

      {/* {materialsGroups.length > 0 ? (
        <ListMaterialsMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          nomenclatures={nomenclatures}
          selectedMaterialCategoryId={selectedMaterialCategoryId}
          onSelectedMaterialCategoryIdChange={handleMaterialCategoryIdChange}
          materials={materials}
          selectedMaterialsClientIds={selectedMaterialsClientIds}
          onSelectionChange={handleSelectionChange}
          materialsGroups={materialsGroups}
          forceSelection={true}
          selectedMaterialsGroupId={selectedMaterialsGroupId}
          onSelectedMaterialsGroupIdChange={
            handleSelectedMaterialsGroupIdChange
          }
          multipleSelection={false}
          variant="list"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {noGroupS}
          </Typography>
        </Box>
      )} */}
    </Box>
  );
}
