export default function getPointsInStageFromPointsInImage(
  coordsInImage,
  zonesEditor
) {
  const imageNode = zonesEditor.imageManager.imageNode;
  const imageSize = zonesEditor.imageManager.imageSize;

  const pointsInStage = coordsInImage.map(
    ({x: xAsImageRatio, y: yAsImageRatio}) => {
      const x = xAsImageRatio * imageSize.width + imageNode.x();
      const y = yAsImageRatio * imageSize.height + imageNode.y();
      return {x, y};
    }
  );

  return pointsInStage;
}
