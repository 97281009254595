import {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {showNodesLayer} from "Features/zones/zonesSlice";

import {Box} from "@mui/material";

export default function PageRooms() {
  const dispatch = useDispatch();
  // params

  const {caplaEditor, scene} = useOutletContext();
  const os = useSelector((s) => s.ui.openSections);

  useEffect(() => {
    dispatch(showNodesLayer("ROOMS"));
  }, []);

  if (os.outlet) return <Box sx={{width: 1}}></Box>;
  return <></>;
}
