import {useSelector} from "react-redux";

import {Paper, Button, InputBase, Typography} from "@mui/material";
import useLoadedMap from "Features/maps/hooks/useLoadedMap";
import useSelectedZone from "../hooks/useSelectedZone";

export default function SectionEditScaleInZoneViewerChild({scene}) {
  // strings

  const lengthS = "Longueur (m)";
  const saveS = "Enregistrer";

  // data

  const loadedMap = useLoadedMap();
  const selectedZone = useSelectedZone(scene);
  const editScaleLength = useSelector((s) => s.zones.editScaleLength);

  console.log("loadedMap", loadedMap, selectedZone);

  // helper - length

  const imageSize = selectedZone?.imageSize;
  const imageWidthM = loadedMap.width;
  const scale = imageWidthM / imageSize?.width;
  const length = scale * editScaleLength;

  // helper - length label

  const lengthLabel = length.toFixed(3) + " m";

  // handler

  function handleSaveClick() {}

  return (
    <Paper elevation={12} sx={{p: 1, display: "flex", alignItems: "center"}}>
      <Typography>{lengthLabel}</Typography>
    </Paper>
  );
}
