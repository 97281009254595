import {client} from "API/capla360";
import cleanRoomsGroupToSync from "./utils/cleanRoomsGroupToSync";
import stateToRemoteRoomsGroup from "./utils/stateToRemoteRoomsGroup";
import remoteToStateRoomsGroup from "./utils/remoteToStateRoomsGroup";
import remoteToStateRoom from "./utils/remoteToStateRoom";
import stateToRemoteRoom from "./utils/stateToRemoteRoom";

// CREATE

export const createRoomsGroupService = async ({
  accessToken,
  roomsGroup,
  fromScopeId,
}) => {
  const sceneId = roomsGroup.sceneId;
  const serializedRoomsGroup = stateToRemoteRoomsGroup(roomsGroup);

  const response = await client.post(
    `scenes/${sceneId}/roomsgroups/`,
    serializedRoomsGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        fromScope: fromScopeId,
      },
    }
  );
  const remoteRoomsGroup = response.data;
  const item = remoteToStateRoomsGroup(remoteRoomsGroup);
  return {item, fromScopeId};
};

export const createRoomService = async ({accessToken, room}) => {
  const roomsGroupId = room.roomsGroupId;
  const serializedRoomsGroup = stateToRemoteRoom(room);

  const response = await client.post(
    `rooms_groups/${roomsGroupId}/rooms/`,
    serializedRoomsGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const remoteItem = response.data;
  const item = remoteToStateRoom(remoteItem);
  return {item};
};

export const createRoomsBatchService = async ({
  accessToken,
  rooms,
  rootChildrenClientIds,
}) => {
  const roomsGroupId = rooms[0].roomsGroupId;
  const newRooms = rooms.map(stateToRemoteRoom);

  const data = {
    roomsData: newRooms,
    rootChildrenClientIds,
  };

  const response = await client.post(`rooms/batch/`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      groupid: roomsGroupId,
    },
  });
  const remoteRooms = response.data;
  const items = remoteRooms.map(remoteToStateRoom);
  return {items, roomsGroupId, rootChildrenClientIds};
};

//  FETCH

export const fetchRoomsGroupsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/roomsgroups/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteItems = response.data;
  const items = remoteItems.map(remoteToStateRoomsGroup);
  return {items, sceneId};
};

export const fetchRoomsGroupService = async ({
  accessToken,
  sceneId,
  roomsGroupId,
}) => {
  const response = await client.get(
    `/scenes/${sceneId}/rooms_groups/${roomsGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {items: response.data, sceneId};
};

export const fetchRoomsService = async ({accessToken, roomsGroupId}) => {
  const response = await client.get(`/rooms_groups/${roomsGroupId}/rooms/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteItems = response.data;
  const items = remoteItems.map(remoteToStateRoom);
  return {items, roomsGroupId};
};

//  UPDATE

export const updateRoomsGroupService = async ({accessToken, updates}) => {
  const sceneId = updates.sceneId;
  const roomsGroupId = updates.id;
  const remoteUpdates = remoteToStateRoomsGroup(updates);

  const response = await client.patch(
    `/scenes/${sceneId}/roomsgroups/${roomsGroupId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteRoomsGroup = response.data;
  const item = remoteToStateRoomsGroup(remoteRoomsGroup);
  return {item};
};

export const updateRoomService = async ({accessToken, updates}) => {
  console.log("updateRoomService", updates);
  const roomsGroupId = updates.roomsGroupId;
  const roomId = updates.id;
  const remoteUpdates = stateToRemoteRoom(updates);
  const response = await client.patch(
    `/rooms_groups/${roomsGroupId}/rooms/${roomId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteRoom = response.data;
  const item = remoteToStateRoom(remoteRoom);
  return {item};
};

export const updateRoomsBatchService = async ({accessToken, updatesBatch}) => {
  const updates0 = updatesBatch[0];
  console.log("updates0", updates0);
  const roomsGroupId = updates0.roomsGroupId;
  const remoteUpdatesBatch = updatesBatch.map(stateToRemoteRoom);
  const response = await client.patch(
    `/rooms/batch/update/`,
    remoteUpdatesBatch,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        groupid: roomsGroupId,
      },
    }
  );
  const items = response.data.map(remoteToStateRoom);
  return {items};
};

//  DELETE

export const deleteRoomsGroupService = async ({accessToken, roomsGroup}) => {
  const roomsGroupId = roomsGroup.id;
  const sceneId = roomsGroup.sceneId;
  console.log("try to delete typesRoomsGroup", sceneId, roomsGroup);
  await client.delete(`/scenes/${sceneId}/roomsgroups/${roomsGroupId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {roomsGroupId};
};

export const deleteAllRoomsService = async ({accessToken, roomsGroupId}) => {
  await client.delete(`/rooms_group/delete-all-rooms/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      groupid: roomsGroupId,
    },
  });
  return {roomsGroupId};
};

export const deleteRoomService = async ({accessToken, room}) => {
  const roomsGroupId = room.roomsGroupId;
  const roomId = room.id;
  await client.delete(`/roomsgroups/${roomsGroupId}/rooms/${roomId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {roomId};
};

export const deleteRoomsBatchService = async ({
  accessToken,
  roomsIds,
  groupId,
}) => {
  await client.request({
    method: "delete",
    url: "/rooms/batch/delete/",
    data: {roomsIds, groupId},
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {roomsIds, groupId};
};
