const enabledModulesByModeMap = {
  BASIC: [
    "SCOPES",
    "INPUTS",
    "ZONES",
    "PACKAGES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",
  ],
  STANDARD: [
    "SCOPES",
    "INPUTS",
    "SECTORS",
    "ZONES",
    "MARKERS",
    "PACKAGES",
    "ELEMENT_TYPES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",
    "VIEWS",
  ],
  PRO: [
    "SCOPES",
    "INPUTS",
    "ZONES",
    "SECTORS",
    "ZONES",
    "MARKERS",
    "PACKAGES",
    "ELEMENT_TYPES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",
    "RESSOURCES",
    "RELATIONS",
    "ROOMS",
    "PHASE",
    "SHAREDBOXES",
    "ISSUES",
    "REPORTS",
    "VIEWS",
  ],
  DIAG: [
    "SCOPES",
    "INPUTS",
    "SECTORS",
    "ROOMS",
    "MARKERS",
    //"PACKAGES",
    //"ELEMENT_TYPES",
    //"EDIT_MEASUREMENTS",
    //"MEASUREMENTS",
    //"ARTICLES",
    "SURVEYS",
    "SAMPLES",
    "SHAPES",
    "REPORTS",
    "VIEWS",
  ],
};

export default enabledModulesByModeMap;
