export default function getShapeNodePoints(shapeNode, zonesEditor) {
  const imageNode = zonesEditor.imageManager.imageNode;
  const imageSize = zonesEditor.imageManager.imageSize;

  const points = shapeNode.points();

  const indexMax = points.length / 2;

  const pointsInImage = [];

  for (let i = 0; i < indexMax; i++) {
    const x = points[i * 2];
    const y = points[i * 2 + 1];
    const xInImage = x - imageNode.x();
    const yInImage = y - imageNode.y();
    const xInImageNormalized = xInImage / imageSize.width;
    const yInImageNormalized = yInImage / imageSize.height;
    pointsInImage.push({x: xInImageNormalized, y: yInImageNormalized});
  }

  return pointsInImage;
}
