import Konva from "konva";

import {cropImage} from "Features/images/imageUtils";
import {saveBlob} from "Features/files/utils";

export default class ImageManager {
  constructor({zonesEditor}) {
    this.zonesEditor = zonesEditor;

    this.url = null;
    this.imageNode = null;
    this.imageSize = {width: 0, height: 0};
  }

  /*
   * image
   */

  async createImageNode({id, url, width, height, onCreated}) {
    console.log("[ImageManager] createImageNode", id, url, width, height);
    if (!url || !id) return;

    if (this.imageNode) {
      this.imageNode.destroy();
      this.imageNode = null;
    }
    const imageSize = {width, height};
    this.imageSize = imageSize;

    const imageObj = new Image();
    imageObj.onload = () => {
      const image = new Konva.Image({
        image: imageObj,
      });
      this.imageNode = image;

      this.url = url;

      this.zonesEditor.layerImage.add(image);
      this.zonesEditor.layerImage.draw();

      this.zonesEditor.blueprintManager.initBlueprint(); // to init image position

      if (onCreated) onCreated(id);
    };
    imageObj.crossOrigin = "Anonymous";
    imageObj.src = url;
  }

  setImageNodePosition({x, y}) {
    this.imageNode.x(x);
    this.imageNode.y(y);
    this.prevImageNodePosition = {x, y};
  }

  getImageCropByBlueprint() {
    this.zonesEditor.blueprintManager.updateBlueprintBboxes();
    const {x, y, width, height} =
      this.zonesEditor.blueprintManager.blueprintBboxInStage;
    const cropX = x - this.imageNode.x();
    const cropY = y - this.imageNode.y();
    const cropWidth = width;
    const cropHeight = height;
    return {x: cropX, y: cropY, width: cropWidth, height: cropHeight};
  }

  async getImageFileCropByBlueprint() {
    const {x, y, width, height} = this.getImageCropByBlueprint();
    const cropFile = await cropImage(this.url, x, y, width, height);
    return cropFile;
  }

  async downloadImageCropByBlueprint() {
    const {x, y, width, height} = this.getImageCropByBlueprint();
    console.log("crop", x, y, width, height);
    const cropFile = await cropImage(this.url, x, y, width, height);
    console.log("cropFile", cropFile);
    saveBlob(cropFile, cropFile.name);
  }

  draw() {
    this.layerImage.draw();
  }

  clear() {
    this.stage.clear();
    this.stage.clearCache();
    if (this.lastTextarea) document.body.removeChild(this.lastTextarea);
  }

  getImageNodeFromLayerImage() {
    // used to avoid 2 creations. Bug with the useEffect of init.
    const children = this.layerImage.getChildren();
    return children[0];
  }

  hideImage() {
    const imageNode = this.getImageNodeFromLayerImage();
    if (imageNode) imageNode.hide();
  }

  showImage() {
    const imageNode = this.getImageNodeFromLayerImage();
    if (imageNode) imageNode.show();
  }

  setImageOpacity(opacity) {
    const imageNode = this.getImageNodeFromLayerImage();
    if (imageNode) imageNode.opacity(opacity / 100);
  }
  clearLayerImage() {
    const children = this.layerImage.getChildren();
    for (let i = 0; i < children.length; i++) {
      children[i].destroy();
    }
  }
}
