import {useRef} from "react";

import {useSelector} from "react-redux";

import useKvtSample from "Features/kvform/hooks/useKvtSample";

import {Box} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

import getRoomsMapFromRoomsGroupsWithRooms from "Features/rooms/utils/getRoomsMapFromRoomsGroupsWithRooms";
import getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials from "Features/materials/utils/getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials";

export default function FormSample({
  caplaEditor,
  scene,
  sample,
  onChange,
  roomsGroupsWithRooms,
  materialsGroupsWithNomenclatureAndMaterials,
  surveys,
}) {
  const selectorContainerRef = useRef(null);

  const data = {
    roomsGroupsWithRooms,
    materialsGroupsWithNomenclatureAndMaterials,
    surveys,
  };
  const template = useKvtSample(scene, data);

  // data

  const surveysMap = useSelector((s) => s.surveys.surveysMap);
  const roomsMapByClientId = getRoomsMapFromRoomsGroupsWithRooms(
    roomsGroupsWithRooms,
    {byClientId: true}
  );
  const materialsMapByClientId =
    getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials(
      materialsGroupsWithNomenclatureAndMaterials,
      {byClientId: true}
    );

  // item

  const item = {...sample};

  if (sample.surveyId) item.survey = surveysMap[sample.surveyId];
  delete item.surveyId;

  if (sample.roomClientId) item.room = roomsMapByClientId[sample.roomClientId];
  delete item.roomClientId;

  if (sample.materialClientId)
    item.material = materialsMapByClientId[sample.materialClientId];
  delete item.materialClientId;

  // handlers

  function handleItemChange(changedItem) {
    console.log("[FormSample] changedItem", changedItem);
    const changedSample = {...sample, ...changedItem};
    //
    changedSample.surveyId = changedItem?.survey?.id;
    changedSample.roomClientId = changedItem?.room?.clientId;
    changedSample.materialClientId = changedItem?.material?.clientId;
    //
    delete changedSample.survey;
    delete changedSample.room;
    delete changedSample.material;
    //
    onChange(changedSample);
  }

  return (
    <Box
      ref={selectorContainerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          flexGrow: 1,
        }}
      >
        <KeyValueForm
          template={template}
          item={item}
          onChange={handleItemChange}
          sizeKey={4}
          selectorContainerRef={selectorContainerRef}
        />
      </Box>
    </Box>
  );
}
