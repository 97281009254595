export default function stateToRemoteShapeMatRelation(shapeMatRelation) {
  const remoteShapeMatRelation = {...shapeMatRelation};

  // scene
  remoteShapeMatRelation.scene = shapeMatRelation.sceneId;
  delete remoteShapeMatRelation.sceneId;

  // shape
  remoteShapeMatRelation.shape = shapeMatRelation.shapeId;
  delete remoteShapeMatRelation.shapeId;

  // material
  remoteShapeMatRelation.material = shapeMatRelation.materialId;
  delete remoteShapeMatRelation.materialId;

  //
  return remoteShapeMatRelation;
}
