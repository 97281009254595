import React from "react";

import {useSelector} from "react-redux";

import {Box} from "@mui/material";
import SelectorZoneInViewer from "./SelectorZoneInViewer";
import ButtonRefreshZoneInViewer from "./ButtonRefreshZoneInViewer";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import SelectorZonesInZoneViewer from "./SelectorZonesInZoneViewer";
import ButtonDownloadStageImage from "./ButtonDownloadStageImage";
import SectionZoneDifferences from "./SectionZoneDifferences";
import SectionPatternRecognition from "./SectionPatternRecognition";

import ToolbarZoneViewerTest from "./ToolbarZoneViewerTest";
import ToolbarZoneViewerDrawShape from "./ToolbarZoneViewerDrawShape";

import SectionSelectorZoneVariantImages from "./SectionSelectorZoneVariantImages";
import ButtonToggleBlueprint from "./ButtonToggleBlueprint";
import SectionIsDrawingInZoneViewer from "./SectionIsDrawingInZoneViewer";
import SelectorNodesLayers from "./SelectorNodesLayers";

export default function LayerZoneViewer({scene, caplaEditor}) {
  //
  const fullscreen = useIsFullscreen();
  const openZonesPanel = useSelector((s) => s.zones.openZonesPanel);
  const isDrawing = useSelector((s) => s.zones.isDrawingInZoneViewer);
  const enabledDrawingMode = useSelector((s) => s.zones.enabledDrawingMode);
  //
  //const top = fullscreen ? "16px" : "68px";
  const top = "16px";

  return (
    <>
      {openZonesPanel && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 90,
          }}
        >
          <SectionSelectorZoneVariantImages scene={scene} />
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 20,
        }}
      >
        <SelectorZoneInViewer scene={scene} caplaEditor={caplaEditor} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 20,
        }}
      >
        {enabledDrawingMode ? (
          <ToolbarZoneViewerDrawShape caplaEditor={caplaEditor} scene={scene} />
        ) : (
          <ToolbarZoneViewerTest caplaEditor={caplaEditor} scene={scene} />
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top,
          left: "8px",
          zIndex: 20,
        }}
      >
        {/* <ButtonRefreshZoneInViewer scene={scene} caplaEditor={caplaEditor} /> */}
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "16px",
          zIndex: 20,
        }}
      >
        <SelectorNodesLayers />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: "16px",
          zIndex: 20,
        }}
      >
        <ButtonToggleBlueprint />
      </Box>

      {/* <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: "16px",
          zIndex: 20,
        }}
      >
        <SectionPatternRecognition scene={scene} caplaEditor={caplaEditor} />
      </Box> */}
    </>
  );
}
