import {useRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useDraggable, useDndMonitor} from "@dnd-kit/core";

import {
  setContextMenu,
  setOpenContextMenu,
  setContextMenuAnchorPosition,
} from "../uiSlice";

import {Box, Popper, ClickAwayListener, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import ContextMenuSelectedShape from "Features/shapes/components/ContextMenuSelectedShape";

export default function ContextMenu({caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const open = useSelector((s) => s.ui.openContextMenu);
  const anchorPosition = useSelector((s) => s.ui.contextMenuAnchorPosition);
  const contextMenu = useSelector((s) => s.ui.contextMenu);

  const isEditingShape = useSelector((s) => s.shapes.isEditingShape);

  // data dnd

  const {attributes, listeners, setNodeRef, transform, isDragging} =
    useDraggable({
      id: `contextMenu`,
    });
  useDndMonitor({onDragEnd: handleDragEnd});

  function handleDragEnd(event) {
    const {activatorEvent, delta} = event;
    // const pointer = {
    //   x: activatorEvent.clientX + delta.x ?? 0,
    //   y: activatorEvent.clientY + delta.y ?? 0,
    // };
    const pointer = {
      x: anchorPosition.x + delta.x ?? 0,
      y: anchorPosition.y + delta.y ?? 0,
    };
    dispatch(setContextMenuAnchorPosition(pointer));
  }

  // virtual ref

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef({getBoundingClientRect: generateBBCR});

  virtualElementRef.current.getBoundingClientRect = generateBBCR(
    anchorPosition.x,
    anchorPosition.y
  );

  // debug

  const menuItems = [
    {
      label: "test",
      onClick: () => {
        console.log("test");
      },
    },
  ];

  // handlers

  function handleClose() {
    // edge case
    if (isEditingShape && contextMenu === "SHAPE") return;

    // main
    dispatch(setOpenContextMenu(false));
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        disablePortal={false}
        open={open}
        onClose={handleClose}
        anchorEl={virtualElementRef.current}
        placement="auto"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
          },
          {
            name: "offset",
            options: {
              offset: [10, 10],
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              rootBoundary: "viewport",
              altAxis: true,
              altBoundary: true,
              tether: true,
              padding: 0,
            },
          },
        ]}
      >
        {contextMenu === "SHAPE" && (
          <ContextMenuSelectedShape caplaEditor={caplaEditor} />
        )}
      </Popper>
    </ClickAwayListener>
  );
}
