import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createRoomsGroupService,
  fetchRoomsGroupsService,
  fetchRoomsGroupService,
  updateRoomsGroupService,
  deleteRoomsGroupService,
  fetchRoomsService,
  createRoomService,
  createRoomsBatchService,
  updateRoomService,
  updateRoomsBatchService,
  deleteRoomService,
  deleteAllRoomsService,
  deleteRoomsBatchService,
} from "./services";
import {setMultipleSelection} from "Features/viewer3D/viewer3DSlice";
import {update} from "Features/ifc/ifcSlice";

export const createRoomsGroup = createAsyncThunk(
  "rooms/createRoomsGroup",
  createRoomsGroupService
);
export const createRoom = createAsyncThunk(
  "rooms/createRoom",
  createRoomService
);
export const createRoomsBatch = createAsyncThunk(
  "articles/createRoomsBatch",
  createRoomsBatchService
);
//
export const fetchRoomsGroups = createAsyncThunk(
  "rooms/fetchRoomsGroups",
  fetchRoomsGroupsService
);
export const fetchRoomsGroup = createAsyncThunk(
  "rooms/fetchRoomsGroup",
  fetchRoomsGroupService
);
export const fetchRooms = createAsyncThunk(
  "rooms/fetchRooms",
  fetchRoomsService
);
//
export const updateRoomsGroup = createAsyncThunk(
  "rooms/updateRoomsGroup",
  updateRoomsGroupService
);
export const updateRoom = createAsyncThunk(
  "rooms/updateRoom",
  updateRoomService
);
export const updateRoomsBatch = createAsyncThunk(
  "rooms/updateRoomsBatch",
  updateRoomsBatchService
);
//
export const deleteRoomsGroup = createAsyncThunk(
  "rooms/deleteRoomsGroup",
  deleteRoomsGroupService
);
export const deleteRoom = createAsyncThunk(
  "rooms/deleteRoom",
  deleteRoomService
);
export const deleteAllRooms = createAsyncThunk(
  "articles/deleteAllRooms",
  deleteAllRoomsService
);
export const deleteRoomsBatch = createAsyncThunk(
  "articles/deleteRoomsBatch",
  deleteRoomsBatchService
);

export const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    //
    fetchedRoomsByGroupId: {},
    //
    items: [],
    roomsMap: {},
    //
    formTabRoom: "INFO",
    //
    selectedRoomId: null,
    selectedRoomParentId: null,
    selectedRoomIds: [],
    isEditingRoom: false,
    isDrawingRoom: false,
    editedRoom: {},
    tempRoom: {},
    editedRoomParentChange: null, // {roomClientId,originParentRoomId,targeParentRoomId}
    tempRoomPolyonProps: {},
    //
    overRoomIdInMap: null,
    //
    roomsGroups: [],
    roomsGroupsMap: {},
    selectedRoomsGroupId: null,
    //
    isEditingRoomsGroup: false,
    editedRoomsGroup: {},
    newRoomsGroup: {},
    //
    roomsViewVariant: "LIST", // LIST, TREE, TREE_LIST
    multipleSelectionRooms: false,
    searchTextRooms: "",
    //
    roomsUpdatedAt: null,
    roomsAnnotationsUpdatedAt: null,
  },
  reducers: {
    setFormTabRoom: (state, action) => {
      state.formTabRoom = action.payload;
    },
    setOverRoomIdInMap: (state, action) => {
      state.overRoomIdInMap = action.payload;
    },
    setSelectedRoomId: (state, action) => {
      const roomId = action.payload;
      state.selectedRoomId = roomId;
    },
    setSelectedRoomParentId: (state, action) => {
      state.selectedRoomParentId = action.payload;
    },
    setSelectedRoomIds: (state, action) => {
      state.selectedRoomIds = action.payload;
    },
    updateRoomsSelection: (state, action) => {
      const {roomsIdsToAdd, roomsIdsToRemove} = action.payload;
      console.log(
        "[STATE] updateRoomsSelection",
        roomsIdsToAdd,
        roomsIdsToRemove
      );
      const selectedRoomIds = state.selectedRoomIds;
      const newSelectedRoomIds = [...selectedRoomIds].filter(
        (id) => !roomsIdsToRemove?.includes(id)
      );
      roomsIdsToAdd?.forEach((id) => {
        if (!newSelectedRoomIds.includes(id)) {
          newSelectedRoomIds.push(id);
        }
      });
      state.selectedRoomIds = newSelectedRoomIds;
    },
    setSelectedRoomsGroupId: (state, action) => {
      state.selectedRoomsGroupId = action.payload;
    },
    setIsEditingRoom: (state, action) => {
      state.isEditingRoom = action.payload;
    },
    setIsDrawingRoom: (state, action) => {
      state.isDrawingRoom = action.payload;
    },
    setTempRoomPolygonProps: (state, action) => {
      const props = action.payload;
      state.tempRoomPolyonProps = {...props, updatedAt: Date.now()};
    },
    setEditedRoom: (state, action) => {
      state.editedRoom = action.payload;
    },
    setEditedRoomParentChange: (state, action) => {
      state.editedRoomParentChange = action.payload;
    },
    setTempRoom: (state, action) => {
      state.tempRoom = action.payload;
    },
    //
    setIsEditingRoomsGroup: (state, action) => {
      state.isEditingRoomsGroup = action.payload;
    },
    setEditedRoomsGroup: (state, action) => {
      state.editedRoomsGroup = action.payload;
    },
    setNewRoomsGroup: (state, action) => {
      state.newRoomsGroup = action.payload;
    },
    //
    setRoomsViewVariant: (state, action) => {
      state.roomsViewVariant = action.payload;
    },
    setMultipleSelectionRooms: (state, action) => {
      state.multipleSelectionRooms = action.payload;
    },
    setSearchTextRooms: (state, action) => {
      state.searchTextRooms = action.payload;
    },
    //
    triggerRoomsAnnotationsUpdatedAt: (state, action) => {
      state.roomsAnnotationsUpdatedAt = Date.now();
    },
    triggerUpdateRooms: (state) => {
      state.roomsUpdatedAt = Date.now();
    },
  },
  extraReducers: {
    [createRoomsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.roomsGroupsMap[item.id] = item;
    },
    [createRoom.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.roomsMap[item.id] = item;
    },
    [fetchRoomsGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.roomsGroupsMap[item.id] = item;
      });
    },
    [createRoomsBatch.fulfilled]: (state, action) => {
      const {items, roomsGroupId, childrenClientIds} = action.payload;
      items.forEach((item) => {
        state.roomsMap[item.id] = item;
      });
      if (childrenClientIds?.length > 0) {
        const roomsGroup = state.roomsGroupsMap[roomsGroupId];
        const newRoomsGroup = {...roomsGroup, childrenClientIds};
        state.roomsGroupsMap[roomsGroupId] = newRoomsGroup;
      }
    },
    [fetchRooms.fulfilled]: (state, action) => {
      const {items, roomsGroupId} = action.payload;
      items.forEach((item) => {
        state.roomsMap[item.id] = item;
      });
      state.fetchedRoomsByGroupId[roomsGroupId] = "done";
    },
    [updateRoomsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.roomsGroupsMap[item.id] = item;
    },
    [updateRoom.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.roomsMap[item.id] = item;
      state.roomsUpdatedAt = Date.now();
    },
    [updateRoomsBatch.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.roomsMap[item.id] = item;
      });
    },
    [deleteRoomsGroup.fulfilled]: (state, action) => {
      const {roomsGroupId} = action.payload;
      delete state.roomsGroupsMap[roomsGroupId];
      if (state.selectedRoomsGroupId === roomsGroupId) {
        state.selectedRoomsGroupId = null;
      }
    },
    [deleteAllRooms.fulfilled]: (state, action) => {
      const {roomsGroupId} = action.payload;
      const newRoomsMap = {};
      Object.keys(state.roomsMap).forEach((roomId) => {
        if (state.roomsMap[roomId].roomsGroupId !== roomsGroupId) {
          newRoomsMap[roomId] = state.roomsMap[roomId];
        }
      });
      state.roomsMap = newRoomsMap;
    },
    [deleteRoomsBatch.fulfilled]: (state, action) => {
      const {roomsIds, groupId} = action.payload;
      //
      roomsIds.forEach((roomId) => {
        delete state.roomsMap[roomId];
      });
      //
    },
  },
});

export const {
  setFormTabRoom,
  setSelectedRoomId,
  setSelectedRoomParentId,
  setSelectedRoomIds,
  updateRoomsSelection,
  setSelectedRoomsGroupId,
  setIsEditingRoom,
  setIsDrawingRoom,
  setEditedRoom,
  setEditedRoomParentChange,
  setTempRoom,
  setTempRoomPolygonProps,
  //
  setOverRoomIdInMap,
  //
  setIsEditingRoomsGroup,
  setEditedRoomsGroup,
  setNewRoomsGroup,
  //
  setRoomsViewVariant,
  setMultipleSelectionRooms,
  setSearchTextRooms,
  //
  triggerRoomsAnnotationsUpdatedAt,
  triggerUpdateRooms,
} = roomsSlice.actions;

export default roomsSlice.reducer;
