import {useDispatch} from "react-redux";

import {updateRoom} from "../roomsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateRoom() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const update = (updates) => {
    dispatch(updateRoom({accessToken, updates}));
  };

  return update;
}
