import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  createShapeService,
  fetchShapesService,
  fetchShapeService,
  updateShapeService,
  deleteShapeService,
} from "./servicesShapes";
import {shapes} from "konva/lib/Shape";
import theme from "Styles/theme";

export const createShape = createAsyncThunk(
  "shapes/createShape",
  createShapeService
);
export const fetchShapes = createAsyncThunk(
  "shapes/fetchShapes",
  fetchShapesService
);
export const fetchShape = createAsyncThunk(
  "shapes/fetchShape",
  fetchShapeService
);
export const updateShape = createAsyncThunk(
  "shapes/updateShape",
  updateShapeService
);
export const deleteShape = createAsyncThunk(
  "shapes/deleteShape",
  deleteShapeService
);

const shapesSlice = createSlice({
  name: "shapes",
  initialState: {
    shapesUpdatedAt: null,
    //
    shapesMap: {},
    selectedShapeId: null,
    editedShape: {},
    isEditingShape: false,
    newShape: {
      color: theme.palette.primary.flash,
      fillType: "SOLID",
    },
    //
    overShapeIdInMap: null,
  },
  reducers: {
    setSelectedShapeId: (state, action) => {
      state.selectedShapeId = action.payload;
      state.shapesUpdatedAt = Date.now();
    },
    setIsEditingShape: (state, action) => {
      state.isEditingShape = action.payload;
    },
    setEditedShape: (state, action) => {
      state.editedShape = action.payload;
    },
    setNewShape: (state, action) => {
      state.newShape = action.payload;
    },
    //
    setOverShapeIdInMap: (state, action) => {
      state.overShapeIdInMap = action.payload;
    },
    //
    triggerUpdateShapes: (state) => {
      state.shapesUpdatedAt = Date.now();
    },
  },
  extraReducers: {
    [createShape.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.shapesMap[item.id] = item;
    },
    [fetchShapes.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.shapesMap[item.id] = item;
      });
    },
    [fetchShape.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.shapesMap[item.id] = item;
    },
    [updateShape.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.shapesMap[item.id] = item;
      state.shapesUpdatedAt = Date.now();
    },
    [deleteShape.fulfilled]: (state, action) => {
      const shapeId = action.payload;
      delete state.shapesMap[shapeId];
    },
  },
});
export const {
  setSelectedShapeId,
  setIsEditingShape,
  setEditedShape,
  setNewShape,
  setTempShape,
  //
  setOverShapeIdInMap,
  //
  triggerUpdateShapes,
} = shapesSlice.actions;

export default shapesSlice.reducer;
