import {useRef} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Popper, ClickAwayListener, Box} from "@mui/material";

import SectionEditScaleInZoneViewerChild from "./SectionEditScaleInZoneViewerChild";
import {setEditScaleAnchorPosition} from "../zonesSlice";

export default function SectionEditScaleInZoneViewer({scene}) {
  const dispatch = useDispatch();

  // data

  const anchorPosition = useSelector((s) => s.zones.editScaleAnchorPosition);
  const open = Boolean(anchorPosition);

  // virtual ref

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef({getBoundingClientRect: generateBBCR});

  virtualElementRef.current.getBoundingClientRect = generateBBCR(
    anchorPosition?.x,
    anchorPosition?.y
  );

  // handlers

  function handleClose(e) {
    console.log("[SectionEditScaleInZoneViewer] handleClose");
    dispatch(setEditScaleAnchorPosition(null));
  }

  return (
    <>
      {open && (
        <Box>
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              disablePortal={false}
              open={open}
              onClose={handleClose}
              anchorEl={virtualElementRef.current}
              placement="auto"
              modifiers={[
                {
                  name: "arrow",
                  enabled: true,
                },
                {
                  name: "offset",
                  options: {
                    offset: [10, 10],
                  },
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    rootBoundary: "viewport",
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    padding: 0,
                  },
                },
              ]}
            >
              <SectionEditScaleInZoneViewerChild scene={scene} />
            </Popper>
          </ClickAwayListener>
        </Box>
      )}
    </>
  );
}
