import Konva from "konva";

import createMarkerNode from "./utilsMarkersManager/createMarkerNode";

import getClustersFromMarkers from "../utils/getClustersFromMarkers";
import getItemsMapById from "Utils/getItemsMapById";

import getCoordsFromMarkerNode from "./utilsMarkersManager/getCoordsFromMarkerNode";

export default class MarkersManager {
  constructor({
    zonesEditor,
    onMarkerCoordsChange,
    onMarkerClick,
    onMarkerDelete,
    onMarkerOver,
  }) {
    this.zonesEditor = zonesEditor;

    this.markerNodesById = {};
    this.markersById = {};

    this.markerRadius = 20;

    this.onMarkerCoordsChange = onMarkerCoordsChange;
    this.onMarkerClick = onMarkerClick;
    this.onMarkerOver = onMarkerOver;
    this.onMarkerDelete = onMarkerDelete;

    this.editedMarkerNode = null; // to keep a track for deletion / update
    this.editedMarker = null;

    // LISTENERS

    this.zonesEditor.stage.on("click", (e) => {
      const node = e.target;
      const nodeType = node?.getAttr("nodeType");
      if (!["ROOM_LABEL"].includes(nodeType)) {
        this.onMarkerClick(null);
      }
    });

    window.addEventListener("keydown", (e) => {
      console.log("keydown", e.key);
      if (e.key === "Delete" || e.key === "Backspace") {
        console.log("delete", this.editedMarker);
        if (this.editedMarker) this.onMarkerDelete(this.editedMarker);
      }
    });
  }

  // ADD

  addMarkers(markers) {
    try {
      markers.forEach((marker) => {
        if (this.markerNodesById[marker.id]) {
          return;
        } else {
          this.createNode(marker);
        }
      });

      this.zonesEditor.stage.batchDraw();
    } catch (error) {
      console.log("error addMarkers", error);
    }
  }

  createNode = (marker) => {
    try {
      //
      const markerNode = createMarkerNode({
        marker,
        stage: this.zonesEditor.stage,
        zonesEditor: this.zonesEditor,
        onClick: () => this.onMarkerClick(marker),
        onMarkerCoordsChange: this.handlerMarkerCoordsChange,
      });
      //
      this.markerNodesById[marker.id] = markerNode;
      this.markersById[marker.id] = marker;
      //
      if (marker.selected) {
        this.zonesEditor.layerEditing.add(markerNode);
        this.editedMarkerNode = markerNode;
        this.editedMarker = marker;
      } else {
        this.zonesEditor.layerMarkers.add(markerNode);
      }

      // listeners
      markerNode.on("mouseenter", () => {
        if (!this.zonesEditor.shapesManager.editedShapeId) {
          this.onMarkerOver(marker);
        }
      });
      markerNode.on("mouseleave", () => {
        if (!this.zonesEditor.shapesManager.editedShapeId) {
          this.onMarkerOver(null);
        }
      });
    } catch (error) {
      console.log("error createNode", error);
    }
  };

  // CHANGE

  handlerMarkerChange(marker) {
    const existingMarker = this.markersMap[marker.id];
    // the marker can be a cluster. In this case, we do not want to update the makersMap
    if (existingMarker) {
      this.markersMap[marker.id] = marker;
    }
  }

  handlerMarkerCoordsChange = (updates) => {
    console.log("handlerMarkerCoordsChange", updates);
    this.onMarkerCoordsChange(updates);
  };

  // DELETE

  deleteMarkersNodes(ids) {
    ids.forEach((id) => {
      const markerNode = this.markerNodesById[id];
      if (markerNode) markerNode.destroy();
      delete this.markerNodesById[id];
      delete this.markersById[id];
    });
  }

  deleteAllMarkersNodes() {
    this.zonesEditor.layerMarkers.destroyChildren();
    this.markerNodesById = {};
    this.markersById = {};
    //
    this.deleteEditedMarkerNode();
  }

  deleteEditedMarkerNode() {
    if (this.editedMarkerNode) {
      this.editedMarkerNode.destroy();
      this.editedMarkerNode = null;
      this.editedMarker = null;
    }
  }

  // RESIZE & CLUSTER

  resizeMarkersNodes(targetScale) {
    const markers = Object.values(this.markersById);
    this.deleteAllMarkersNodes();
    //const clusteredMarkers = getClustersFromMarkers(markers, targetScale);
    //this.addMarkers(clusteredMarkers, {targetScale});
    this.addMarkers(markers);
  }

  clusterNodes(clusterMarker) {
    const idsToCluster = clusterMarker.cluster.map((m) => m.id);
    this.deleteMarkersNodes(idsToCluster);
    this.markerNodesById[clusterMarker.id] = this.createMarkerNode(
      clusterMarker,
      {isCluster: true}
    );
  }

  // FOCUS

  focusOnMarker(id) {
    try {
      const markerNode = this.markerNodesById[id];
      if (markerNode) {
        const {x, y} = getCoordsFromMarkerNode(
          markerNode,
          this.zonesEditor.stage
        );
        console.log("focusOnMarker", x, y);
        this.zonesEditor.centerStageOn(x, y);
      }
    } catch (e) {
      console.log("error focusOnMarker", e);
    }
  }
}
