import useAccessToken from "Features/auth/useAccessToken";
import {useDispatch} from "react-redux";
import {updateScope} from "../scopesSlice";

export default function useAddItemToScope() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const addItem = async ({scope, itemType, itemId}) => {
    const itemTypeFieldMap = {
      //SURVEY: "surveysIds",
      ROOMS_GROUP: "roomsGroupsIds",
      MATERIALS_GROUP: "materialsGroupsIds",
      ELEMENT_TYPES_GROUP: "typesGroupIds",
      PACKAGE: "packageIds",
      INPUT: "inputIds",
      ZONE: "zoneIds",
      ARTICLES_GROUP: "articlesGroupsIds",
    };

    const field = itemTypeFieldMap[itemType];

    const scopeUpdates = {
      id: scope?.id,
      sceneId: scope?.sceneId,
    };
    if (scope?.data[field]?.length > 0) {
      scopeUpdates.data = {
        ...scope.data,
        [field]: [...scope.data[field], itemId],
      };
    } else {
      scopeUpdates.data = {...scope.data, [field]: [itemId]};
    }

    await dispatch(updateScope({scope: scopeUpdates, accessToken}));
  };

  return addItem;
}
