import {distance} from "jimp";
import getPointsBbox from "./getPointsBbox";

function isBetween(start, center, end) {
  return (
    ((start.x <= center.x && center.x <= end.x) ||
      (end.x <= center.x && center.x <= start.x)) &&
    ((start.y <= center.y && center.y <= end.y) ||
      (end.y <= center.y && center.y <= start.y))
  );
}

export default function getClosestPointPositionAndDistance(point0, points) {
  try {
    // edge case

    if (!points.length) {
      return null;
    }

    // helpers

    const bbox = getPointsBbox(points);
    const distRef = Math.min(bbox.width, bbox.height);

    // find closest point

    function getDistance(point1, point2) {
      return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
      );
    }

    function getOrthoProjection(point, segmentStart, segmentEnd) {
      const dx = segmentEnd.x - segmentStart.x;
      const dy = segmentEnd.y - segmentStart.y;
      const lengthSquared = dx * dx + dy * dy;
      const t =
        ((point.x - segmentStart.x) * dx + (point.y - segmentStart.y) * dy) /
        lengthSquared;
      return {
        x: segmentStart.x + t * dx,
        y: segmentStart.y + t * dy,
      };
    }

    let closestPoint = null;
    let minDistance = Infinity;
    let index = 0;
    let distanceToCorner = null;
    let isLast = false;

    for (let i = 0; i < points.length; i++) {
      const segmentStart = points[i];
      const segmentEnd = points[(i + 1) % points.length];
      const projection = getOrthoProjection(point0, segmentStart, segmentEnd);
      const distance = getDistance(point0, projection);
      const _index = i;
      const _distanceToCorner = Math.min(
        getDistance(point0, segmentStart),
        getDistance(point0, segmentEnd)
      );

      if (
        isBetween(segmentStart, projection, segmentEnd) &&
        distance < minDistance
      ) {
        minDistance = distance;
        closestPoint = projection;
        index = _index;
        isLast = _index === points.length - 1;
        distanceToCorner = _distanceToCorner;
      }
    }
    return {
      position: closestPoint,
      distance: minDistance,
      distanceNormalized: minDistance / distRef,
      index,
      isLast,
      distanceToCorner,
    };
  } catch (e) {
    console.log("error", e);
  }
}
