import {useDispatch, useSelector} from "react-redux";

import {setOpenZonesPanel} from "../zonesSlice";

import {Paper, Typography, IconButton} from "@mui/material";
import {ArrowDropDown as Down, Refresh} from "@mui/icons-material";

import useSelectedZone from "../hooks/useSelectedZone";

export default function SelectorZoneInViewer({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedZone = useSelectedZone(scene);

  // handlers

  function handleClick() {
    dispatch(setOpenZonesPanel(true));
  }

  function handleReset(e) {
    e.stopPropagation();
    caplaEditor.zonesEditor.initBlueprint();
  }

  return (
    <Paper
      elevation={12}
      sx={{
        bgcolor: "common.caplaBlack",
        color: "common.white",
        borderRadius: "15px",
        height: "30px",
        px: 1,
        cursor: "pointer",
        minWidth: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={handleClick}
    >
      <IconButton onClick={handleReset} sx={{mr: 1}}>
        <Refresh fontSize="small" sx={{color: "grey"}} />
      </IconButton>

      <Typography variant="body2">{selectedZone?.name}</Typography>
      <Down sx={{ml: 1}} color="inherit" />
    </Paper>
  );
}
