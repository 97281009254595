import {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import {addDataLoadedInZonesEditor} from "Features/zones/zonesSlice";

import useLoadedMap from "Features/maps/hooks/useLoadedMap";
import useShapes from "./useShapes";
import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedMaterial from "Features/materials/hooks/useSelectedMaterial";

export default function useAutoLoadShapesInZonesEditor({scene, zonesEditor}) {
  const dispatch = useDispatch();

  // data

  const showBlueprint = useSelector((s) => s.zones.showBlueprint);

  const loadedMap = useLoadedMap();
  const loadedMapClientId = loadedMap?.id;
  const showNodesLayers = useSelector((s) => s.zones.showNodesLayers);

  const shapesUpdatedAt = useSelector((s) => s.shapes.shapesUpdatedAt);
  const overShapeIdInMap = useSelector((s) => s.shapes.overShapeIdInMap);

  const selectedMaterial = useSelectedMaterial();

  let shapes = useShapes({
    filterByMapClientId: loadedMapClientId,
    filterByMaterialId: selectedMaterial?.id,
    withSelected: true,
    withEdited: true,
    withMaterials: true,
  });

  console.log("[useAutoLoadShapesInZonesEditor]", shapes);

  const mod = useSceneModule();

  // helpers

  const editedShapeId = shapes.find((shape) => shape.edited)?.id;

  // helpers - showShapes

  const showShapes = showNodesLayers.includes("SHAPES");
  if (!showShapes) shapes = [];

  // helpers - items

  const items = shapes.map((shape) => {
    return {
      ...shape,
      over: shape.id === overShapeIdInMap,
      materialColor: shape.materials?.[0]?.color,
    };
  });

  // effect

  useEffect(() => {
    if (items && zonesEditor && loadedMapClientId) {
      // options
      const options = {};
      dispatch(addDataLoadedInZonesEditor({shapes: items}));
      zonesEditor.loadShapes(items, options);
    }
  }, [
    loadedMapClientId,
    shapesUpdatedAt,
    zonesEditor,
    items.length,
    editedShapeId,
    overShapeIdInMap,
    selectedMaterial?.id,
    showBlueprint,
    mod,
  ]);
}
