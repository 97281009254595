import {useSelector} from "react-redux";

export default function useShapeMatRelations(options) {
  // options

  const filterBySceneId = options?.sceneId;
  const variant = options?.variant;

  // data

  const shapeMatRelationsMap = useSelector(
    (s) => s.shapeMatRelations.shapeMatRelationsMap
  );

  // helpers - items

  let items = Object.values(shapeMatRelationsMap);

  // filters

  if (filterBySceneId) {
    items = items.filter((item) => item.sceneId === filterBySceneId);
  }

  // variant

  if (variant === "byMatId") {
    const byMatId = {};
    items.forEach((item) => {
      if (!byMatId[item.materialId]) {
        byMatId[item.materialId] = [];
      }
      byMatId[item.materialId].push(item);
    });
    return byMatId;
  }
  if (variant === "byShapeId") {
    const byShapeId = {};
    items.forEach((item) => {
      if (!byShapeId[item.shapeId]) {
        byShapeId[item.shapeId] = [];
      }
      byShapeId[item.shapeId].push(item);
    });
    return byShapeId;
  }
  if (variant === "byShapeIdMatId") {
    const byShapeIdMatId = {};
    items.forEach((item) => {
      if (!byShapeIdMatId[item.shapeId + item.materialId]) {
        byShapeIdMatId[item.shapeId + item.materialId] = item;
      }
    });
    return byShapeIdMatId;
  }

  // result
  return items;
}
