import {useSelector} from "react-redux";

import useRoomLabels from "./useRoomLabels";

import useSelectedRoomsGroup from "./useSelectedRoomsGroup";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import getRoomsWithNum from "../utils/getRoomsWithNum";
import getRoomsWithParentIdFromNum from "../utils/getRoomsWithParentIdFromNum";
import getFoundRooms from "../utils/getFoundRooms";
import getRoomsWithLabels from "../utils/getRoomsWithLabels";
import useSamples from "Features/samples/hooks/useSamples";

export default function useRooms(options) {
  // options

  const filterByScope = options?.filterByScope;
  const filterByRoomsGroupId = options?.filterByRoomsGroupId;
  const filterByPdfModelId = options?.filterByPdfModelId;
  const filterByClientIds = options?.filterByClientIds;
  const withNum = options?.withNum;
  const withParentId = options?.withParentId;
  const withMaterialsOfGroupId = options?.withMaterialsOfGroupId;
  const withSamples = options?.withSamples;
  const applySearch = options?.applySearch;
  const variantByClientId = options?.variantByClientId;

  // data

  const scope = useSelectedScope();
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsGroup = useSelectedRoomsGroup();
  const searchText = useSelector((s) => s.rooms.searchTextRooms);
  const samplesByRoomClientId = useSamples({variant: "byRoomClientId"});

  // helper

  let rooms = roomsMap ? Object.values(roomsMap) : [];

  //
  if (filterByScope) {
    rooms = rooms.filter(
      (room) =>
        !scope?.data?.roomsGroupsIds ||
        scope?.data?.roomsGroupsIds?.includes(room.roomsGroupId)
    );
  }

  if (filterByRoomsGroupId) {
    rooms = rooms.filter((r) => r.roomsGroupId === filterByRoomsGroupId);
  }

  if (filterByPdfModelId) {
    rooms = rooms.filter((r) =>
      r.polygons?.map((p) => p.pdfModelId).includes(filterByPdfModelId)
    );
  }

  //
  if (withNum || withParentId) {
    rooms = getRoomsWithNum(roomsGroup, rooms);
  }

  //
  if (withParentId) {
    rooms = getRoomsWithParentIdFromNum(rooms);
  }

  //
  if (withSamples) {
    rooms = rooms.map((room) => {
      return {...room, samples: samplesByRoomClientId[room.clientId]};
    });
  }

  // FILTERS

  if (filterByClientIds) {
    const roomsByClientIds = rooms.reduce((acc, r) => {
      acc[r.clientId] = r;
      return acc;
    }, {});
    rooms = filterByClientIds.map((id) => roomsByClientIds[id]);
  }

  // WITH

  if (withMaterialsOfGroupId) {
    rooms = rooms.map((room) => {
      const materialsClientIds = room.materialsData?.find(
        (d) => d.materialsGroupId === withMaterialsOfGroupId
      )?.materialsClientIds;
      return {...room, materialsClientIds};
    });
  }

  // SEARCH
  if (applySearch) {
    const searchOptions = {
      searchText,
      searchKeys: ["name"],
      withParents: true,
    };
    rooms = getFoundRooms(rooms, searchOptions);
  }

  // VARIANT
  if (variantByClientId) {
    rooms = rooms.reduce((acc, room) => {
      acc[room.clientId] = room;
      return acc;
    }, {});
  }

  return rooms;
}
