import {List, ListItemButton, Typography} from "@mui/material";

export default function ListShapes({shapes, onClick, selection}) {
  return (
    <List>
      {shapes.map((shape) => {
        const selected = selection.includes(shape.id);
        const surface = shape.surface;
        const surfaceLabel = surface ? `${surface.toFixed(2)} m²` : "N/A";
        return (
          <ListItemButton
            divider
            selected={selected}
            key={shape.id}
            onClick={() => onClick(shape)}
            sx={{justifyContent: "space-between"}}
          >
            <Typography sx={{fontSize: 13}}>{shape.type2d}</Typography>
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {surfaceLabel}
            </Typography>
          </ListItemButton>
        );
      })}
    </List>
  );
}
