const isProperScale = (s) => {
  const vs = s.split(":");
  if (vs.length !== 2) return false;
  const checkV1 = !isNaN(vs[0]) && !isNaN(parseFloat(vs[0]));
  const checkV2 = !isNaN(vs[1]) && !isNaN(parseFloat(vs[1]));
  return checkV1 && checkV2;
};

export default function getChangedFields(o, n) {
  // o = oldFields, n = newFields
  const f = {}; // f = newFields

  // name
  if (n.name !== o.name) f.name = n.name;

  // sectorId & roomId (& materialId <- no materialId for zones !)
  if (n.sectorId !== o.sectorId) f.sectorId = n.sectorId;
  if (n.roomId !== o.roomId) f.roomId = n.roomId;
  if (n.roomClientId !== o.roomClientId) f.roomClientId = n.roomClientId;
  // if (n.materialId !== o.materialId) f.materialId = n.materialId;

  // scale
  // if (n.scale !== o.scale) f.scale = n.scale;
  if (n.formatedScale) {
    let newScale = n.formatedScale;
    if (!newScale || !isProperScale(newScale)) newScale = "1:50";
    f.scale = (parseFloat(newScale.split(":")[1]) * 0.0254) / 72.0;
  }

  // zBasis
  if (n.zBasis !== o.zBasis) f.zBasis = n.zBasis;

  // d3
  if (n.d3 !== o.d3) f.d3 = n.d3;

  // rotation
  if (
    o.rotation.x !== n.rotation.x ||
    o.rotation.y !== n.rotation.y ||
    o.rotation.z !== n.rotation.z
  ) {
    f.rotation = n.rotation;
  }

  // position
  if (
    o.position.x !== n.position.x ||
    o.position.y !== n.position.y ||
    o.position.z !== n.position.z
  ) {
    f.position = n.position;
  }

  return f;
}
