import {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import useLoadedMap from "Features/maps/hooks/useLoadedMap";
import useSamples from "Features/samples/hooks/useSamples";
import useRooms from "Features/rooms/hooks/useRooms";
import useSceneModule from "Features/navigation/useSceneModule";

import {addDataLoadedInZonesEditor} from "../zonesSlice";

import getSamplesInMap from "Features/samples/utils/getSamplesInMap";
import parseSampleFromStateToMap from "Features/samples/utils/parseSampleFromStateToMap";
import getRoomsInMap from "Features/rooms/utils/getRoomsInMap";
import getMarkerFromRoom from "Features/rooms/utils/getMarkerFromRoom";
import getBboxInImageFromBboxInStage from "../js/utilsZonesEditor/getBboxInImageFromBboxInStage";
import useSelectedMaterial from "Features/materials/hooks/useSelectedMaterial";

export default function useAutoLoadMarkersInZonesEditor({scene, zonesEditor}) {
  const dispatch = useDispatch();
  // data

  const loadedMap = useLoadedMap();
  const showNodesLayers = useSelector((s) => s.zones.showNodesLayers);

  const materialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );

  const rooms = useRooms({
    filterByScope: true,
    withMaterialsOfGroupId: materialsGroupId,
    withSamples: true,
  });
  const samples = useSamples();

  const roomsUpdatedAt = useSelector((s) => s.rooms.roomsUpdatedAt);
  const samplesUpdatedAt = useSelector((s) => s.samples.samplesUpdatedAt);

  const selectedRoomId = useSelector((s) => s.rooms.selectedRoomId);
  const selectedSampleId = useSelector((s) => s.samples.selectedSampleId);
  const selectedMaterial = useSelectedMaterial();

  const overRoomIdInMap = useSelector((s) => s.rooms.overRoomIdInMap);

  const mod = useSceneModule();
  const showBlueprint = useSelector((s) => s.zones.showBlueprint);

  // helpers - loadedMapClientId

  const loadedMapClientId = loadedMap?.id;

  // helpers - bbox

  let bbox = null;
  if (showBlueprint) {
    const bboxInStage = zonesEditor?.blueprintManager?.blueprintBboxInStage;
    bbox = getBboxInImageFromBboxInStage(bboxInStage, zonesEditor);
  }

  // helpers - markers in map

  let markersInMap = [];

  let roomsInMap = getRoomsInMap(loadedMapClientId, rooms, {inBbox: bbox});
  let samplesInMap = getSamplesInMap(loadedMapClientId, samples, {
    inBbox: bbox,
  });

  // filters based on main filters

  if (!showNodesLayers.includes("ROOMS")) roomsInMap = [];
  if (!showNodesLayers.includes("SAMPLES")) samplesInMap = [];

  // filters based on selection

  if (mod === "MATERIALS" && selectedMaterial) {
    roomsInMap = roomsInMap.filter((room) =>
      room.materialsClientIds?.includes(selectedMaterial.clientId)
    );
  }

  // add

  const addRooms = true;

  const addSamples = ["SAMPLES", "MATERIALS"].includes(mod);

  if (addRooms) dispatch(addDataLoadedInZonesEditor({rooms: roomsInMap}));
  markersInMap.push(
    ...roomsInMap.map((room) => {
      let counter;
      if (mod === "MATERIALS") counter = room.materialsClientIds?.length;
      if (mod === "SAMPLES") counter = room.samples?.length;
      return getMarkerFromRoom(room, {
        mapClientId: loadedMapClientId,
        draggable: selectedRoomId === room.id && mod === "ROOMS",
        selected: selectedRoomId === room.id,
        over: overRoomIdInMap === room.id,
        counter,
      });
    })
  );
  if (addSamples) dispatch(addDataLoadedInZonesEditor({sambles: samplesInMap}));
  markersInMap.push(
    ...samplesInMap.map((sample) =>
      parseSampleFromStateToMap(sample, {
        mapClientId: loadedMapClientId,
        selected: sample.id === selectedSampleId,
        draggable: selectedSampleId === sample.id && mod === "SAMPLES",
      })
    )
  );

  // effect

  useEffect(() => {
    if (zonesEditor && loadedMapClientId) {
      //
      console.log("[loadMarkers] markersInMap", markersInMap);
      zonesEditor.loadMarkers(markersInMap);
    }
  }, [
    loadedMapClientId,
    roomsUpdatedAt,
    samplesUpdatedAt,
    zonesEditor,
    markersInMap.length,
    selectedRoomId,
    selectedSampleId,
    overRoomIdInMap,
    mod,
  ]);
}
