import {useSelector} from "react-redux";

import {Box, Typography, Paper} from "@mui/material";
import {AddLocation, LocationOn} from "@mui/icons-material";

import theme from "Styles/theme";

export default function DragOverlayItem() {
  // data

  //const isDragging = useSelector((s) => s.zones.isDraggingLocateFab);
  const locateFabItem = useSelector((s) => s.zones.locateFabItem);
  const isLocatingFab = useSelector((s) => s.zones.isLocatingFab);

  // helper - label

  let label = "-";
  let color = theme.palette.primary.main;
  if (locateFabItem?.type === "ROOM") {
    label = locateFabItem?.name;
    color = theme.palette.sceneModule.room;
  } else if (locateFabItem?.type === "MATERIAL") {
    label = locateFabItem?.name;
    color = theme.palette.sceneModule.material;
  } else if (locateFabItem?.type === "SAMPLE") {
    label = locateFabItem?.num;
    color = theme.palette.sceneModule.sample;
  }

  // helpers - dims

  const height = isLocatingFab ? "36px" : "unset";
  const width = isLocatingFab ? "36px" : "unset";

  return (
    <Paper
      sx={{
        cursor: isLocatingFab ? "crosshair" : "grab",
        opacity: isLocatingFab ? 0.6 : 1,
        bgcolor: color,
        borderRadius: 0,
        color: "white",
        width,
        height,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLocatingFab ? <LocationOn /> : <AddLocation />}
    </Paper>
  );
}
