// input : bbox coords in stage container (take into account container clientRectangleBbox)
// output : bbox coords in stage

import getPointerCoordsInStage from "./getPointerCoordsInStage";

export default function getBboxInStage(bbox, options) {
  // options

  const stage = options.stage;
  const inputCoordsInStageContainer = options.inputCoordsInStageContainer;

  // main

  const topLeftInStage = getPointerCoordsInStage(
    {x: bbox.x, y: bbox.y},
    stage,
    inputCoordsInStageContainer
  );
  const bottomRightInStage = getPointerCoordsInStage(
    {x: bbox.x + bbox.width, y: bbox.y + bbox.height},
    stage,
    inputCoordsInStageContainer
  );

  //
  const bboxInStage = {
    x: topLeftInStage.x,
    y: topLeftInStage.y,
    width: bottomRightInStage.x - topLeftInStage.x,
    height: bottomRightInStage.y - topLeftInStage.y,
  };
  //
  return bboxInStage;
}
