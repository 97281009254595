import {useEffect} from "react";
import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

import {fetchShapeMatRelations} from "../shapeMatRelationsSlice";

export default function useInitFetchShapeMatRelations(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  useEffect(() => {
    if (sceneId && accessToken) {
      dispatch(fetchShapeMatRelations({sceneId, accessToken}));
    }
  }, [accessToken, sceneId]);
}
