import React, {useRef, useEffect, useLayoutEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {useDroppable} from "@dnd-kit/core";

import useSceneModule from "Features/navigation/useSceneModule";
import useZonesByScene from "../hooks/useZonesByScene";
import useSelectedZone from "../hooks/useSelectedZone";

import {
  setOverRoomIdInMap,
  setSelectedRoomId,
  triggerUpdateRooms,
} from "Features/rooms/roomsSlice";

import {
  setIsDrawingInZoneViewer,
  setSelectedZoneId,
  setZoneIdLoadedInViewer,
  setEditScaleAnchorPosition,
  setEditScaleLength,
  setEnabledDrawingMode,
} from "../zonesSlice";

import {
  setSelectedShapeId,
  setEditedShape,
  setIsEditingShape,
} from "Features/shapes/shapesSlice";

import {setSelectedSampleId} from "Features/samples/samplesSlice";

import {
  setContextMenuAnchorPosition,
  setOpenContextMenu,
  setContextMenu,
} from "Features/ui/uiSlice";

import useAutoLoadImageInZonesEditor from "../hooks/useAutoLoadImageInZonesEditor";
import useAutoLoadMarkersInZonesEditor from "../hooks/useAutoLoadMarkersInZonesEditor";
//import useAutoLoadSamplesInZonesEditor from "Features/samples/hooks/useAutoLoadSamplesInZonesEditor"; // replaced by markers
import useUpdateSample from "Features/samples/hooks/useUpdateSample";
import useAutoLoadShapesInZonesEditor from "Features/shapes/hooks/useAutoLoadShapesInZonesEditor";

import useCreateShape from "Features/shapes/hooks/useCreateShape";
import useUpdateShape from "Features/shapes/hooks/useUpdateShape";
import useDeleteShape from "Features/shapes/hooks/useDeleteShape";

import useUpdateRoom from "Features/rooms/hooks/useUpdateRoom";

import {Box} from "@mui/material";

import ZonesEditor from "../js/ZonesEditor";
import LayerZoneViewer from "./LayerZoneViewer";
import LayerBlueprintRectangle from "./LayerBlueprintRectangle";
import SectionEditScaleInZoneViewer from "./SectionEditScaleInZoneViewer";

import theme from "Styles/theme";

import getBlueprintBbox from "../js/utilsZonesEditor/getBlueprintBbox";
import useLoadedMap from "Features/maps/hooks/useLoadedMap";
import useSelectedMaterial from "Features/materials/hooks/useSelectedMaterial";
import removeMaterialFromMaterialsData from "Features/materials/utils/removeMaterialFromMaterialData";
import computeShapeSurface from "Features/shapes/utils/computeShapeSurface";

export default function ViewerZone({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const zonesEditorRef = useRef();

  // strings

  const zonesS = "Fonds de plan";

  // size

  const blueprintStroke = 2;
  const blueprintRatio = 4 / 3;
  const blueprintPadding = 60;
  const blueprintColor = theme.palette.common.caplaBlack;

  // state

  const [zonesEditorIsLoaded, setZonesEditorIsLoaded] = useState(false);
  const [blueprintBbox, setBlueprintBbox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [containerBbox, setContainerBbox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  // data - dnd

  const {isOver, setNodeRef} = useDroppable({
    id: "drop-container",
  });

  // data

  const sceneModule = useSceneModule();
  const zones = useZonesByScene(scene);
  const selectedZone = useSelectedZone(scene);
  const os = useSelector((s) => s.ui.openSections);
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const fixedViewersBoxWidth = useSelector(
    (s) => s.viewer3D.fixedViewersBoxWidth
  );

  const blueprintProps = useSelector((s) => s.zones.blueprintProps) ?? {};
  const showBlueprint = useSelector((s) => s.zones.showBlueprint);

  const loadedMap = useLoadedMap();
  const loadedMapClientIdRef = useRef();
  const loadedMapRef = useRef();
  loadedMapClientIdRef.current = loadedMap?.id;
  loadedMapRef.current = loadedMap;

  const selectedShapeId = useSelector((s) => s.shapes.selectedShapeId);
  const selectedShapeIdRef = useRef();
  selectedShapeIdRef.current = selectedShapeId;

  const isDrawing = useSelector((s) => s.zones.isDrawingInZoneViewer);
  const isDrawingRef = useRef();
  isDrawingRef.current = isDrawing;

  const newShape = useSelector((s) => s.shapes.newShape);
  const newShapeRef = useRef();
  newShapeRef.current = newShape;

  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsMapRef = useRef();
  roomsMapRef.current = roomsMap;

  const shapesMap = useSelector((s) => s.shapes.shapesMap);
  const shapesMapRef = useRef();
  shapesMapRef.current = shapesMap;

  const samplesMap = useSelector((s) => s.samples.samplesMap);
  const samplesMapRef = useRef();
  samplesMapRef.current = samplesMap;

  const editedShape = useSelector((s) => s.shapes.editedShape);
  const editedShapeRef = useRef();
  editedShapeRef.current = editedShape;

  const overRoomIdInMap = useSelector((s) => s.rooms.overRoomIdInMap);
  const overRoomIdInMapRef = useRef();
  overRoomIdInMapRef.current = overRoomIdInMap;

  const selectedMaterial = useSelectedMaterial();
  const selectedMaterialRef = useRef();
  selectedMaterialRef.current = selectedMaterial;

  const sceneIdRef = useRef();
  sceneIdRef.current = scene?.id;

  // data - func

  const updateRoomRef = useRef();
  const updateRoom = useUpdateRoom();
  updateRoomRef.current = updateRoom;

  const updateSampleRef = useRef();
  const updateSample = useUpdateSample();
  updateSampleRef.current = updateSample;

  const createShapeRef = useRef();
  const createShape = useCreateShape();
  createShapeRef.current = createShape;

  const updateShapeRef = useRef();
  const updateShape = useUpdateShape();
  updateShapeRef.current = updateShape;

  const deleteShapeRef = useRef();
  const deleteShape = useDeleteShape();
  deleteShapeRef.current = deleteShape;

  // data - depth

  const openNavigation = useSelector((s) => s.navigation.open);
  const openSelection = useSelector((s) => s.selection.openSelectionPanel);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);
  const navPanelSceneModulesWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );

  // ref

  // helpers

  const showLayer = os.fixedViewersBox && selectedFixedViewer === "ZONE";
  const showBlueprintLayer = showBlueprint && os.fixedViewersBox;

  // handlers - zone

  function handleZoneLoaded(zoneId) {
    dispatch(setZoneIdLoadedInViewer(zoneId));
  }

  function handleIsDrawingChange(isDrawing) {
    dispatch(setIsDrawingInZoneViewer(isDrawing));
  }

  function handleEditScale({mousePosition, length}) {
    dispatch(setEditScaleAnchorPosition(mousePosition));
    dispatch(setEditScaleLength(length));
  }

  // handlers - markers

  function handleMarkerClick(marker) {
    console.log("marker click", marker);
    if (!marker) {
      dispatch(setSelectedRoomId(null));
      dispatch(setSelectedSampleId(null));
    } else if (marker.type === "ROOM_LABEL") {
      dispatch(setSelectedRoomId(marker.id));
      //dispatch(triggerUpdateRooms());
    } else if (marker.type === "SAMPLE") {
      dispatch(setSelectedSampleId(marker.id));
    }
  }

  async function handleMarkerCoordsChange(markerUpdates) {
    console.log("markerUpdates", markerUpdates);
    if (
      markerUpdates.type === "SAMPLE" ||
      markerUpdates.type === "SAMPLES_GROUP"
    ) {
      const sample = samplesMapRef.current[markerUpdates.id];
      const mapClientId = markerUpdates.mapClientId;
      const coords = sample.coords
        ? sample.coords.filter((c) => c.mapClientId !== mapClientId)
        : [];
      coords.push({
        x: markerUpdates.x,
        y: markerUpdates.y,
        scale: markerUpdates.scale,
        mapClientId,
      });
      const updates = {
        id: sample.id,
        surveyId: sample.surveyId,
        coords,
      };
      await updateSampleRef.current(updates);
    } else if (markerUpdates.type === "ROOM_LABEL") {
      const room = roomsMapRef.current[markerUpdates.id];
      const mapClientId = markerUpdates.mapClientId;
      const coords = room.coords
        ? room.coords.filter((c) => c.mapClientId !== mapClientId)
        : [];
      coords.push({
        x: markerUpdates.x,
        y: markerUpdates.y,
        scale: markerUpdates.scale,
        mapClientId,
      });
      const updates = {
        id: room.id,
        roomsGroupId: room.roomsGroupId,
        coords,
      };
      await updateRoomRef.current(updates);
    }
  }

  function handleDeleteMarkerNode({id: markerId, mapClientId, type}) {
    if (type === "ROOM_LABEL") {
      const room = roomsMapRef.current[markerId];
      let updates = {id: room.id, roomsGroupId: room.roomsGroupId};
      if (sceneModule === "ROOMS") {
        const coords = room.coords
          ? room.coords.filter((c) => c.mapClientId !== mapClientId)
          : [];
        updates.coords = coords;
      } else if (sceneModule === "MATERIALS" && selectedMaterial) {
        console.log(
          "removeMaterial from room",
          selectedMaterial.name,
          room.name
        );
        const newMaterialData = removeMaterialFromMaterialsData(
          selectedMaterial.clientId,
          selectedMaterial.materialsGroupId,
          room.materialsData
        );
        updates.materialsData = newMaterialData;
      }

      updateRoomRef.current(updates);
    }
  }

  function handleMarkerOver(marker) {
    let markerId = null;
    if (marker && marker.type === "ROOM_LABEL") {
      markerId = marker.id;
    }
    if (overRoomIdInMapRef.current !== markerId) {
      dispatch(setOverRoomIdInMap(markerId));
      //dispatch(triggerUpdateRooms());
    }
  }

  // handlers - shapes

  function handleCreateShape(shapeFromEditor) {
    const surface = computeShapeSurface(shapeFromEditor, loadedMapRef.current);
    const shape = {
      ...newShapeRef.current,
      ...shapeFromEditor,
      surface,
      id: nanoid(),
      sceneId: sceneIdRef.current,
      mapClientId: loadedMapClientIdRef.current,
    };
    console.log("[ViewerZone] handleCreateShape", shape);
    createShapeRef.current(shape);
  }

  function handleShapeClick(shape) {
    console.log("shape click", shape, selectedShapeIdRef.current);
    const id = shape?.id === selectedShapeIdRef.current ? null : shape?.id;
    dispatch(setSelectedShapeId(id));
    dispatch(setIsEditingShape(false)); // to avoid to keep one edited shape once clicked outside a shape
  }

  function handleShapeDblClick(shape) {
    const editedShape = shapesMap[shape.id];
    dispatch(setIsEditingShape(true));
    dispatch(setEditedShape(editedShape));
  }

  function handleShapeRightClick({shape, clickPosition}) {
    //dispatch(setSelectedShapeId(shape.id));
    const editedShape = shapesMapRef.current[shape.id];
    dispatch(setIsEditingShape(true));
    dispatch(setEditedShape(editedShape));
    console.log("[ViewerZone] editedShape", editedShape, shape, shapesMap);
    dispatch(setContextMenuAnchorPosition(clickPosition));
    dispatch(setOpenContextMenu(true));
    dispatch(setContextMenu("SHAPE"));
  }

  async function handleSaveEditedShape(changes) {
    console.log("[ViewerZone] handleSaveEditedShape", changes);
    const editedShape = editedShapeRef.current;

    const updatedShape = {
      ...editedShape,
      ...changes,
      sceneId: sceneIdRef.current,
    };
    if (changes.points) {
      updatedShape.surface = computeShapeSurface(changes, loadedMapRef.current);
    }
    await updateShapeRef.current(updatedShape);
    dispatch(setOpenContextMenu(false));
    dispatch(setIsEditingShape(false));
    dispatch(setEditedShape({}));
  }

  async function handleDeleteShape(shapeId) {
    dispatch(setOpenContextMenu(false));
    dispatch(setIsEditingShape(false));
    dispatch(setEditedShape({}));
    const sceneId = sceneIdRef.current;
    await deleteShapeRef.current({shapeId, sceneId});
  }

  // handler - event

  function handleRightClick(e) {
    e.preventDefault();
  }

  function handleKeyDown(e) {
    if (e.key === "Escape") {
      console.log("[ViewerZone] Escape, isDrawingRef", isDrawingRef.current);
      dispatch(setEditScaleAnchorPosition(null));
      if (!isDrawingRef.current) {
        zonesEditorRef.current.disableDrawingMode();
        zonesEditorRef.current.stopEdition();
        dispatch(setEnabledDrawingMode(null));
        dispatch(setSelectedShapeId(null));
        dispatch(setIsEditingShape(false));
      }
    }
  }
  // init

  function init() {
    //
    if (!containerRef.current || zonesEditorIsLoaded) return;
    //
    const bbox = containerRef.current.getBoundingClientRect();
    //
    zonesEditorRef.current = new ZonesEditor({
      caplaEditor,
      container: "container",
      width: bbox.width,
      height: bbox.height,
      bgcolor: theme.palette.background.default,
      blueprintProps,
      //
      onZoneLoaded: handleZoneLoaded,
      onEditScale: handleEditScale,
      onIsDrawingChange: handleIsDrawingChange,
      //
      onMarkerClick: handleMarkerClick,
      onMarkerDelete: handleDeleteMarkerNode,
      onMarkerCoordsChange: handleMarkerCoordsChange,
      onMarkerOver: handleMarkerOver,
      //
      onNewShape: handleCreateShape,
      onShapeClick: handleShapeClick,
      onShapeDblClick: handleShapeDblClick,
      onShapeRightClick: handleShapeRightClick,
      onSaveEditedShape: handleSaveEditedShape,
      onDeleteShape: handleDeleteShape,
    });
    caplaEditor.zonesEditor = zonesEditorRef.current;
    setZonesEditorIsLoaded(true);

    window.addEventListener("resize", () => {
      zonesEditorRef.current.updateStageSize();

      if (containerRef.current) {
        //
        const bbox = getBlueprintBbox(
          containerRef.current,
          blueprintRatio,
          blueprintPadding,
          blueprintStroke
        );
        setBlueprintBbox(bbox);

        // container bbox (for blueprint)
        const containerBbox = containerRef.current.getBoundingClientRect();
        setContainerBbox(containerBbox);
      }
    });

    window.addEventListener("keydown", handleKeyDown);
  }

  useEffect(() => {
    if (caplaEditor && containerRef.current) {
      init();
    }
  }, [caplaEditor, containerRef?.current]);

  // load effect

  useAutoLoadImageInZonesEditor({scene, zonesEditor: zonesEditorRef.current});
  useAutoLoadMarkersInZonesEditor({scene, zonesEditor: zonesEditorRef.current});
  useAutoLoadShapesInZonesEditor({zonesEditor: zonesEditorRef.current});

  // mode effect

  const showCounters = ["MATERIALS", "SAMPLES"].includes(sceneModule)
    ? sceneModule
    : null;
  useEffect(() => {
    zonesEditorRef.current.showCounters = showCounters;
  }, [showCounters]);
  // update stage size

  useLayoutEffect(() => {
    setTimeout(() => {
      if (zonesEditorIsLoaded && containerRef.current) {
        //
        zonesEditorRef.current.updateStageSize();
        //
        zonesEditorRef.current.updateStageOnContainerResize();
        //
        const bbox = getBlueprintBbox(
          containerRef.current,
          blueprintRatio,
          blueprintPadding,
          blueprintStroke
        );
        setBlueprintBbox(bbox);

        // container bbox (for blueprint)
        const containerBbox = containerRef.current.getBoundingClientRect();
        setContainerBbox(containerBbox);
      }
    }, 10); // to get proper canvas resize...necessary when switching MIX<=>..
  }, [
    openNavigation,
    openSelection,
    openLeftPanel,
    navPanelSceneModulesWidth,
    selectedFixedViewer,
    zonesEditorIsLoaded,
    containerRef.current,
    fixedViewersBoxWidth,
    os.viewer3D,
  ]);

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      {showLayer && <LayerZoneViewer scene={scene} caplaEditor={caplaEditor} />}
      {showBlueprintLayer && (
        <LayerBlueprintRectangle
          containerBbox={containerBbox}
          bbox={blueprintBbox}
          color={blueprintColor}
          stroke={blueprintStroke}
        />
      )}
      <SectionEditScaleInZoneViewer scene={scene} />

      <Box
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          bgcolor: "common.white",
          boxSizing: "borderBox",
          overflow: "hidden",
        }}
        onContextMenu={handleRightClick}
      >
        <div
          ref={setNodeRef}
          onMouseOver={() => console.log("Mouse over container")}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: isOver ? "lightblue" : "white",
          }}
        >
          <div
            id="container"
            ref={containerRef}
            style={{
              //border: "1px solid black",
              boxSizing: "border-box",
              width: "100%",
              height: "100%",
              //zIndex: 1,
            }}
          ></div>
        </div>
      </Box>
    </Box>
  );
}
