import {useSelector} from "react-redux";

import useLoadedMap from "Features/maps/hooks/useLoadedMap";
import useShapeMatRelations from "Features/shapeMatRelations/hooks/useShapeMatRelations";
import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";
import getItemsMapById from "Utils/getItemsMapById";

export default function useShapes(options) {
  // data

  const shapesMap = useSelector((s) => s.shapes.shapesMap);

  const loadedMap = useLoadedMap();

  const selectedShapeId = useSelector((s) => s.shapes.selectedShapeId);

  const editedShape = useSelector((s) => s.shapes.editedShape);
  const isEditingShape = useSelector((s) => s.shapes.isEditingShape);

  const shapeMatRelations = useShapeMatRelations({variant: "byShapeIdMatId"});
  const shapeMatRelationsByShapeId = useShapeMatRelations({
    variant: "byShapeId",
  });

  const materialsMap = useSelector((s) => s.materials.materialsMap);
  const materials = Object.values(materialsMap);
  const materialsById = getItemsMapById(materials);

  // helpers

  let shapes = Object.values(shapesMap);

  // filters

  if (options.bySceneId) {
    shapes = shapes.filter((shape) => shape.sceneId === options.bySceneId);
  }

  if (options.filterByMapClientId) {
    shapes = shapes.filter(
      (shape) =>
        shape.mapClientId === options.filterByMapClientId &&
        shape.points?.length > 1
    );
  }

  if (options?.filterByMaterialId) {
    shapes = shapes.filter((shape) => {
      const shapeMatRelation =
        shapeMatRelations[shape.id + options.filterByMaterialId];
      return Boolean(shapeMatRelation);
    });
    console.log("shapesSSS", shapes);
  }

  if (options.debug) {
    const debugShape = {
      id: "debug",
      mapClientId: loadedMap?.id,
      color: "red",
      type2d: "POLYGON",
      points: [
        {x: 0.25, y: 0.25},
        {x: 0.5, y: 0.25},
        {x: 0.5, y: 0.5},
        {x: 0.25, y: 0.5},
      ],
    };
    shapes.push(debugShape);
  }

  if (options.withSelected) {
    shapes = shapes.map((shape) => {
      return {
        ...shape,
        selected: shape.id === selectedShapeId,
      };
    });
  }

  if (options.withEdited) {
    if (isEditingShape) {
      shapes = shapes.map((shape) => {
        const edited = shape.id === editedShape?.id;
        if (edited) console.log("edited shape", shape);
        return {
          ...shape,
          edited,
        };
      });
    }
  }

  if (options.withMaterials) {
    shapes = shapes.map((shape) => {
      const shapeMatRelations = shapeMatRelationsByShapeId[shape.id];
      const materials = shapeMatRelations
        ?.map((shapeMatRelation) => {
          return materialsById[shapeMatRelation.materialId];
        })
        .filter(
          (material) =>
            !options?.filterByMaterialId ||
            material?.id === options?.filterByMaterialId
        );
      return {
        ...shape,
        materials,
      };
    });
  }

  //

  return shapes;
}
