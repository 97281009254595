export default function getPointerCoordsInStage(
  pointer,
  stage,
  coordsInStageContainer
) {
  const scale = stage.scaleX();
  //
  const rect = stage.container().getBoundingClientRect();

  // Add window scroll positions to account for scrolling
  let x = rect.left + window.scrollX;
  let y = rect.top + window.scrollY;

  // correction: pointer coords in canvas ref.
  if (coordsInStageContainer) {
    x = 0;
    y = 0;
  }

  const focusPointTo = {
    x: (pointer.x - x - stage.x()) / scale,
    y: (pointer.y - y - stage.y()) / scale,
  };
  return focusPointTo;
}
