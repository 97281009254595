import {useSelector, useDispatch} from "react-redux";
import {useDraggable, useDndMonitor} from "@dnd-kit/core";

import useSelectedShape from "../hooks/useSelectedShape";

import {setContextMenuAnchorPosition} from "Features/ui/uiSlice";
import {setEditedShape, triggerUpdateShapes} from "../shapesSlice";

import {Box, Paper, IconButton} from "@mui/material";
import {DragHandle, Add} from "@mui/icons-material";

import SelectorColorVariantButton from "Features/color/components/SelectorColorVariantButton";

export default function ContextMenuSelectedShape({caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedShape = useSelectedShape();
  const anchorPosition = useSelector((s) => s.ui.contextMenuAnchorPosition);
  const editedShape = useSelector((s) => s.shapes.editedShape);
  const isEditingShape = useSelector((s) => s.shapes.isEditingShape);

  // helper

  const shape = isEditingShape ? editedShape : selectedShape;

  // data dnd

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    isDragging,
  } = useDraggable({
    id: `contextMenu-selectedShape`,
  });
  useDndMonitor({onDragEnd: handleDragEnd});

  function handleDragEnd(event) {
    const {activatorEvent, delta} = event;
    // const pointer = {
    //   x: activatorEvent.clientX + delta.x ?? 0,
    //   y: activatorEvent.clientY + delta.y ?? 0,
    // };
    const pointer = {
      x: anchorPosition.x + delta.x ?? 0,
      y: anchorPosition.y + delta.y ?? 0,
    };
    dispatch(setContextMenuAnchorPosition(pointer));
  }

  // helpers

  const color = shape?.color ?? "black";

  // handlers

  function handleColorChange(color) {
    console.log("color", color);
    dispatch(setEditedShape({...editedShape, color}));
    caplaEditor.zonesEditor.shapesManager.shapeEditor.setColor(color);
  }

  return (
    <Paper
      ref={setNodeRef}
      {...attributes}
      sx={{
        display: "flex",
        justifyContent: "center",
        transform: `translate(${transform?.x ?? 0}px, ${transform?.y ?? 0}px)`,
        position: "relative",
      }}
    >
      <Box
        ref={setActivatorNodeRef}
        {...listeners}
        sx={{
          transform: "rotate(90deg)",
          cursor: "grab",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DragHandle sx={{color: "grey.200"}} />
      </Box>

      <SelectorColorVariantButton
        name="Couleur"
        color={color}
        onChange={handleColorChange}
      />
    </Paper>
  );
}
