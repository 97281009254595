import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  createShapeMatRelationService,
  fetchShapeMatRelationsService,
  fetchShapeMatRelationService,
  updateShapeMatRelationService,
  deleteShapeMatRelationService,
} from "./servicesShapeMatRelations";

import theme from "Styles/theme";

export const createShapeMatRelation = createAsyncThunk(
  "shapeMatRelations/createShapeMatRelation",
  createShapeMatRelationService
);
export const fetchShapeMatRelations = createAsyncThunk(
  "shapeMatRelations/fetchShapeMatRelations",
  fetchShapeMatRelationsService
);
export const fetchShapeMatRelation = createAsyncThunk(
  "shapeMatRelations/fetchShapeMatRelation",
  fetchShapeMatRelationService
);
export const updateShapeMatRelation = createAsyncThunk(
  "shapeMatRelations/updateShapeMatRelation",
  updateShapeMatRelationService
);
export const deleteShapeMatRelation = createAsyncThunk(
  "shapeMatRelations/deleteShapeMatRelation",
  deleteShapeMatRelationService
);

const shapeMatRelationsSlice = createSlice({
  name: "shapeMatRelations",
  initialState: {
    shapeMatRelationsUpdatedAt: null,
    //
    shapeMatRelationsMap: {},
    selectedShapeMatRelationId: null,
    editedShapeMatRelation: {},
    isEditingShapeMatRelation: false,
    newShapeMatRelation: {},
    //
  },
  reducers: {
    setSelectedShapeMatRelationId: (state, action) => {
      state.selectedShapeMatRelationId = action.payload;
      state.shapeMatRelationsUpdatedAt = Date.now();
    },
    setIsEditingShapeMatRelation: (state, action) => {
      state.isEditingShapeMatRelation = action.payload;
    },
    setEditedShapeMatRelation: (state, action) => {
      state.editedShapeMatRelation = action.payload;
    },
    setNewShapeMatRelation: (state, action) => {
      state.newShapeMatRelation = action.payload;
    },
    //
    triggerUpdateShapeMatRelations: (state) => {
      state.shapeMatRelationsUpdatedAt = Date.now();
    },
  },
  extraReducers: {
    [createShapeMatRelation.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.shapeMatRelationsMap[item.id] = item;
    },
    [fetchShapeMatRelations.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.shapeMatRelationsMap[item.id] = item;
      });
    },
    [fetchShapeMatRelation.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.shapeMatRelationsMap[item.id] = item;
    },
    [updateShapeMatRelation.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.shapeMatRelationsMap[item.id] = item;
      state.shapeMatRelationsUpdatedAt = Date.now();
    },
    [deleteShapeMatRelation.fulfilled]: (state, action) => {
      const shapeMatRelationId = action.payload;
      delete state.shapeMatRelationsMap[shapeMatRelationId];
    },
  },
});
export const {
  setSelectedShapeMatRelationId,
  setIsEditingShapeMatRelation,
  setEditedShapeMatRelation,
  setNewShapeMatRelation,
  setTempShapeMatRelation,
  //
  triggerUpdateShapeMatRelations,
} = shapeMatRelationsSlice.actions;

export default shapeMatRelationsSlice.reducer;
