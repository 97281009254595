import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useRoomsGroupsWithRooms from "Features/rooms/hooks/useRoomsGroupsWithRooms";
import getRoomsMapFromRoomsGroupsWithRooms from "Features/rooms/utils/getRoomsMapFromRoomsGroupsWithRooms";

export default function FieldZoneRoom({
  zone,
  scene,
  onZoneChange,
  containerRef,
}) {
  // strings

  const roomS = "Secteur / Pièce";

  // data

  const roomsGroupsWithRooms = useRoomsGroupsWithRooms(scene, {
    filterByScope: true,
    fetchRooms: true,
    roomsWithParents: true,
  });

  // helpers - item

  const roomClientId = zone?.roomClientId;
  const roomsMap = getRoomsMapFromRoomsGroupsWithRooms(roomsGroupsWithRooms, {
    byClientId: true,
  });
  const item = {room: roomsMap[roomClientId]};

  // template

  const template = {
    key: "kvtZoneRoom",
    fields: [
      {
        key: "room",
        name: roomS,
        type: "room",
        roomsGroupsWithRooms,
        canCreate: false,
      },
    ],
  };

  // handlers

  function handleItemChange(item) {
    const room = item.room;
    onZoneChange({...zone, roomClientId: room?.clientId});
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleItemChange}
      selectorContainerRef={containerRef}
    />
  );
}
