import {useSelector, useDispatch} from "react-redux";

import {setTestScenesOnly} from "../scenesSlice";

import {FormControlLabel, Switch, Typography, Box} from "@mui/material";

export default function SwitchTestScenesOnly() {
  const dispatch = useDispatch();

  // strings

  const label = "Bimbox test uniquement";

  // data

  const checked = useSelector((s) => s.scenes.testScenesOnly);

  // handlers

  function handleSwitchChange() {
    dispatch(setTestScenesOnly(!checked));
  }

  return (
    <Box sx={{pl: 2}}>
      <FormControlLabel
        control={
          <Switch
            size="small"
            checked={checked}
            onChange={handleSwitchChange}
          />
        }
        label={<Typography variant="body2">{label}</Typography>}
      />
    </Box>
  );
}
