import {useSelector} from "react-redux";

export default function useSelectedShape(options) {
  // data

  const id = useSelector((s) => s.shapes.selectedShapeId);
  const shapesMap = useSelector((s) => s.shapes.shapesMap);

  // helpers

  let shape = shapesMap[id];

  //

  return shape;
}
