import {useState} from "react";

import {Box, Button} from "@mui/material";
import ListMapsVariantBasic from "./ListMapsVariantBasic";

export default function SelectorMapsVariantList({selection, maps, onChange}) {
  // handlers

  function handleClick(zone) {
    let newSelection = (selection ?? []).filter(
      (clientId) => clientId !== zone.id
    );
    if (!selection?.includes(zone.id)) {
      newSelection.push(zone.id);
    }
    onChange(newSelection);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: 1,
          minHeight: 0,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <ListMapsVariantBasic
          maps={maps}
          selection={selection ?? []}
          onClick={handleClick}
        />
      </Box>
    </Box>
  );
}
