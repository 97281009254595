import {useState, useEffect} from "react";

import {Box} from "@mui/material";
import SelectorMapsVariantList from "Features/maps/components/SelectorMapsVariantList";

export default function FieldMapsIds({item, name, value, maps, onChange}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: 1,
        minHeight: 0,
      }}
    >
      <SelectorMapsVariantList
        maps={maps}
        selection={value}
        onChange={onChange}
      />
    </Box>
  );
}
