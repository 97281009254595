export default function remoteToStateShapeMatRelation(shapeMatRelation) {
  const stateShapeMatRelation = {...shapeMatRelation};

  // scene
  stateShapeMatRelation.sceneId = shapeMatRelation.scene;
  delete stateShapeMatRelation.scene;

  // shape
  stateShapeMatRelation.shapeId = shapeMatRelation.shape;
  delete stateShapeMatRelation.shape;

  // material
  stateShapeMatRelation.materialId = shapeMatRelation.material;
  delete stateShapeMatRelation.material;

  return stateShapeMatRelation;
}
