import {useSelector} from "react-redux";

import {IconButton, Tooltip} from "@mui/material";
import {Download} from "@mui/icons-material";

export default function ButtonDownloadStageImage({caplaEditor}) {
  // strings

  const label = "Télécharger l'image";

  // data

  const data = useSelector((s) => s.zones.dataLoadedInZonesEditor);
  const dataJson = JSON.stringify(data);
  const metaData = {dataFromCapla: dataJson};

  // handler

  function handleClick() {
    //caplaEditor.zonesEditor.downloadStageImage();
    //caplaEditor.zonesEditor.downloadBlueprint();
    caplaEditor.zonesEditor.downloadBlueprint({metaData});
  }
  return (
    <Tooltip title={label}>
      <IconButton onClick={handleClick}>
        <Download />
      </IconButton>
    </Tooltip>
  );
}
