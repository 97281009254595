import {useSelector, useDispatch} from "react-redux";

import {
  setNewSample,
  setEditedSample,
  setIsEditingSample,
} from "../samplesSlice";

import useSampleInSelectionPanel from "../hooks/useSampleInSelectionPanel";
import useRoomsGroupsWithRooms from "Features/rooms/hooks/useRoomsGroupsWithRooms";
import useMaterialsGroupsWithNomenclatureAndMaterials from "Features/materials/hooks/useMaterialsGroupsWithNomenclatureAndMaterials";
import useSurveysByScene from "Features/surveys/hooks/useSurveysByScene";

import FormSample from "./FormSample";

export default function SectionSampleInSelectionPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();
  // data

  const sample = useSampleInSelectionPanel();

  const roomsGroupsWithRooms = useRoomsGroupsWithRooms(scene, {
    filterByScope: true,
    fetchRooms: true,
    roomsWithParents: true,
  });
  const materialsGroupsWithNomenclatureAndMaterials =
    useMaterialsGroupsWithNomenclatureAndMaterials(scene, {
      filterByScope: true,
      fetchMaterials: true,
    });

  const surveys = useSurveysByScene(scene, {
    filterByScope: true,
    sortByScheduledAt: true,
  });

  // helpers

  const isNew = !sample?.id;

  // handlers

  function handleChange(changedSample) {
    if (isNew) {
      dispatch(setNewSample(changedSample));
    } else {
      dispatch(setEditedSample(changedSample));
      dispatch(setIsEditingSample(true));
    }
  }

  return (
    <FormSample
      caplaEditor={caplaEditor}
      scene={scene}
      sample={sample}
      onChange={handleChange}
      roomsGroupsWithRooms={roomsGroupsWithRooms}
      materialsGroupsWithNomenclatureAndMaterials={
        materialsGroupsWithNomenclatureAndMaterials
      }
      surveys={surveys}
    />
  );
}
