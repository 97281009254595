import {saveBlob, urlToBlob} from "Features/files/utils";
import {
  createImageAsync,
  cropImage,
  getImageSize,
} from "Features/images/imageUtils";
import Konva from "konva";
import theme from "Styles/theme";

import ImageManager from "./ImageManager";
import BlueprintManager from "./BlueprintManager";
import MarkersManager from "./MarkersManager";
import ShapesManager from "./ShapesManager";

import getPointerCoordsInStage from "./utilsZonesEditor/getPointerCoordsInStage";

import addMetaDataToImage from "Features/images/utils/addMetaDataToImage";
import downloadImage from "Utils/downloadImage";
import getZoneEditorBboxInStage from "./utilsZonesEditor/getZoneEditorBboxInStage";

export default class ZonesEditor {
  constructor({
    caplaEditor,
    container,
    width,
    height,
    bgcolor,
    blueprintProps,
    //
    onZoneLoaded,
    onEditScale,
    //
    onIsDrawingChange,
    //
    onMarkerClick,
    onMarkerCoordsChange,
    onMarkerDelete,
    onMarkerOver,
    //
    onNewShape,
    onShapeClick,
    onShapeDblClick,
    onShapeRightClick,
    onSaveEditedShape,
    onDeleteShape,
  }) {
    this.caplaEditor = caplaEditor;

    this.stage = new Konva.Stage({
      container, // container string  ("container")
      width,
      height,
      draggable: true,
    });

    // mode

    this.showCounters = false;

    // bgcolor

    if (bgcolor) this.stage.container().style.backgroundColor = bgcolor;

    // scale

    this.scaleBy = 1.1;
    this.scale = 1;
    this.prevStageScale = 1;

    // dragging

    this.stageIsDragging = false; // to prevent drawing events when dragging.

    // layer

    this.layerDefault = new Konva.Layer({zIndex: 1});
    this.layerImage = new Konva.Layer({zIndex: 2});
    this.layerShapes = new Konva.Layer({zIndex: 3});
    this.layerMarkers = new Konva.Layer({zIndex: 7});
    this.layerEditing = new Konva.Layer({zIndex: 8});
    this.layerAnchors = new Konva.Layer({zIndex: 9});

    this.stage.add(this.layerDefault);
    this.stage.add(this.layerImage);
    this.stage.add(this.layerShapes);
    this.stage.add(this.layerMarkers);
    this.stage.add(this.layerEditing);
    this.stage.add(this.layerAnchors);

    // handlers

    this.onZoneLoaded = onZoneLoaded;
    this.onIsDrawingChange = onIsDrawingChange;

    // managers

    this.imageManager = new ImageManager({zonesEditor: this});
    this.blueprintManager = new BlueprintManager({
      zonesEditor: this,
      blueprintProps,
    });

    this.markersManager = new MarkersManager({
      zonesEditor: this,
      onMarkerClick,
      onMarkerCoordsChange,
      onMarkerDelete,
      onMarkerOver,
    });

    this.shapesManager = new ShapesManager({
      zonesEditor: this,
      onShapeClick: onShapeClick,
      onShapeDblClick: onShapeDblClick,
      onShapeRightClick: onShapeRightClick,
      onNewShape: onNewShape,
      onEditScale: onEditScale,
      onSaveEditedShape,
      onDeleteShape,
    });

    // listeners

    this.addListeners();

    // cursor
    this.stageCursor = "default";
  }

  /*
   * listeners
   */

  handleWheelEvent = (e) => {
    e.evt.preventDefault();
    var oldScale = this.stage.scaleX();

    var pointer = this.stage.getPointerPosition();

    var mousePointTo = {
      x: (pointer.x - this.stage.x()) / oldScale,
      y: (pointer.y - this.stage.y()) / oldScale,
    };

    var newScale =
      e.evt.deltaY < 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
    this.scale = newScale;

    this.setStageScale(newScale);

    var newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    this.stage.position(newPos);
    this.stage.batchDraw();

    this.resizeNodes(newScale);
    this.blueprintManager.updateBlueprintBboxes();
  };

  handleKeyDown = (e) => {
    console.log("keydown", e.key);
    const bbox = this.stage.container().getBoundingClientRect();
    const x1 = bbox.left;
    const y1 = bbox.top;
    const x2 = bbox.right;
    const y2 = bbox.bottom;
    const pointTL = getPointerCoordsInStage({x: x1, y: y1}, this.stage, true);
    const pointBR = getPointerCoordsInStage({x: x2, y: y2}, this.stage, true);
    const width = pointBR.x - pointTL.x;
    const height = pointBR.y - pointTL.y;
    const scale = this.stage.scaleX();
    const moveByH = width * 0.01 * scale;
    const moveByV = height * 0.01 * scale;

    let x = this.stage.x();
    let y = this.stage.y();
    if (e.key === "ArrowLeft") x -= moveByH;
    if (e.key === "ArrowRight") x += moveByH;
    if (e.key === "ArrowUp") y -= moveByV;
    if (e.key === "ArrowDown") y += moveByV;
    this.stage.position({x, y});
    this.stage.batchDraw();
  };

  handleDragStart = (e) => {
    this.stageIsDragging = true;
  };
  handleDragStop = (e) => {
    this.stageIsDragging = false;
  };

  addListeners = () => {
    console.log("addListeners");
    this.stage.on("wheel", (e) => this.handleWheelEvent(e));
    this.stage.on("dragstart", this.handleDragStart);
    this.stage.on("dragend", this.handleDragStop);
    window.addEventListener("keydown", this.handleKeyDown);
  };

  /*
   * pointer
   */

  getPointerCoords(x, y, options) {
    const coordsInStageContainer = options?.coordsInStageContainer;
    const outputInStageContainer = options?.outputInStageContainer;

    if (outputInStageContainer) {
      const bbox = this.stage.container().getBoundingClientRect();
      return {
        x: x - bbox.left,
        y: y - bbox.top,
      };
    } else {
      let coords = getPointerCoordsInStage(
        {x, y},
        this.stage,
        coordsInStageContainer
      );

      if (options?.asImageRatio) {
        const {width, height} = this.imageManager.imageSize;
        coords = {
          x: (coords.x - this.imageManager.imageNode.x()) / width,
          y: (coords.y - this.imageManager.imageNode.y()) / height,
        };
      }

      return coords;
    }
  }

  /*
   * style
   */

  enableCrosshair() {
    this.stage.container().style.cursor = "crosshair";
    this.stageCursor = "crosshair";
  }
  disableCrosshair() {
    this.stage.container().style.cursor = "default";
  }

  /*
   * layers
   */

  clearImageLayer() {
    this.layerImage.destroyChildren();
    this.layerImage.draw();
  }

  /*
   * drawing
   */

  enableDrawingMode(mode) {
    this.shapesManager.enableDrawingMode(mode);
  }
  disableDrawingMode() {
    this.shapesManager.disableDrawingMode();
  }

  /*
   * editing
   */

  stopEdition() {
    this.shapesManager.stopEdition();
  }

  /*
   * debug
   */

  addCircle({x, y}) {
    var circle = new Konva.Circle({
      x: x,
      y: y,
      radius: 5 / this.stage.scaleX(),
      fill: "red",
      stroke: "black",
      strokeWidth: 1,
    });
    this.layerMarkers.add(circle);
    this.layerMarkers.draw();
  }

  /*
   * stage
   */

  setStageDraggable(draggable) {
    this.stage.draggable(draggable);
  }

  setStageScale(scale) {
    this.stage.scale({x: scale, y: scale});
    this.prevStageScale = scale;
  }

  downloadStageImage() {
    const visibleRect = this.stage.getClientRect({skipTransform: false});
    const _visibleRect = this.stage.getClientRect({skipTransform: true});

    const offsetX = -visibleRect.left;
    const offsetY = -visibleRect.top;
    const width = visibleRect.width / this.stage.scaleX;
    const height = visibleRect.height / this.stage.scaleY;

    const _offsetX = -_visibleRect.left;
    const _offsetY = -_visibleRect.top;
    const _width = _visibleRect.width;
    const _height = _visibleRect.height;

    this.stage.toImage({
      x: _offsetX,
      y: _offsetY,
      width: _width,
      height: _height,
      pixelRatio: 2,
      imageSmoothingEnabled: false,
      quality: 1,
      callback: downloadImage,
    });
  }

  downloadBlueprint(options) {
    // options
    const metaData = options?.metaData;

    // add background
    this.blueprintManager.updateBlueprintNode();

    // get image
    const {x, y, width, height} =
      this.blueprintManager.blueprintBboxInContainer;
    this.stage.toDataURL({
      x,
      y,
      pixelRatio: 2,
      width,
      height,
      mimeType: "image/png",
      callback: (imageData) => {
        let img = imageData;
        if (metaData) {
          img = addMetaDataToImage({imageData, metaData});
        }
        downloadImage(img, {variant: "base64"});
      },
    });

    // reset
    this.blueprintManager.resetBlueprintNode();
  }

  updateStageSize() {
    const container = this.stage.container();
    const width = container.getBoundingClientRect().width;
    const height = container.getBoundingClientRect().height;
    console.log("dims", width, height);
    this.stage.width(width);
    this.stage.height(height);
  }

  // update stage position & scale when container changes
  // rule : keep center of image at the center of the blueprint

  updateStageOnContainerResize() {
    try {
      //
      this.blueprintManager.updateBlueprintBboxes();
      //
      const containerBbox = this.containerElement.getBoundingClientRect();
      //
      const deltaScale = containerBbox.width / this.prevContainerBbox.width;

      // delta X and Y : based on center of blueprint.

      const prevWidth = this.prevContainerBbox.width;
      const prevHeight = this.prevContainerBbox.height;
      const prevCenterPointer = {x: prevWidth / 2, y: prevHeight / 2};
      const prevCenterPoint = getPointerCoordsInStage(
        prevCenterPointer,
        this.stage
      );

      //
      const newScale = this.prevStageScale * deltaScale;

      // change stage scale

      this.setStageScale(newScale);

      // compute new center

      const newWidth = containerBbox.width;
      const newHeight = containerBbox.height;
      const centerPointer = {x: newWidth / 2, y: newHeight / 2};
      const centerPoint = getPointerCoordsInStage(centerPointer, this.stage);

      // delta X - Y
      const deltaX = centerPoint.x - prevCenterPoint.x;
      const deltaY = centerPoint.y - prevCenterPoint.y;

      const newPositionX = this.prevImageNodePosition.x + deltaX;
      const newPositionY = this.prevImageNodePosition.y + deltaY;

      // change image position
      this.setImageNodePosition({x: newPositionX, y: newPositionY});
      //
      this.prevContainerBbox = containerBbox;
    } catch (e) {
      console.log("error", e);
    }
  }

  resetStageScale() {
    this.stage.scaleX(1 / this.imageScale);
    this.stage.scaleY(1 / this.imageScale);
    this.stage.position({x: 0, y: 0});
  }

  resizeNodes(scale) {
    try {
      this.markersManager.resizeMarkersNodes(scale);
    } catch (e) {
      console.log("error resizing nodes", e);
    }
  }

  centerStageOn(x, y, targetScale) {
    console.log("centerStageOn", x, y, targetScale);
    const scale = targetScale ?? this.stage.scaleX();
    const windowW = this.stage.container().clientWidth;
    const windowH = this.stage.container().clientWidth;
    const stageX = windowW / 2 - scale * x;
    const stageY = windowH / 2 - scale * y;
    //this.stage.position({x: stageX, y: stageY});
    const props = {
      x: stageX,
      y: stageY,
      scaleX: scale,
      scaleY: scale,
      duration: 0.3,
    };
    this.stage.to(props);
    //
    this.resizeNodes(scale);
  }

  focusOnMarker(x, y) {
    const imageSize = this.imageManager.imageSize;
    const imageNode = this.imageManager.imageNode;
    const position = {
      x: x * imageSize.width + imageNode.x(),
      y: y * imageSize.height + imageNode.y(),
    };
    //console.log("focus on marker", position,imageSize,imageNode);
    this.centerStageOn(position.x, position.y);
  }

  // ZONE IMAGE

  loadZone(zone) {
    console.log("[ZonesEditor] load zone", zone);
    try {
      const url = zone?.imageUrl;
      const width = zone?.imageSize?.width;
      const height = zone?.imageSize?.height;
      const id = zone?.id;
      this.clearImageLayer();
      this.imageManager.createImageNode({
        id,
        url,
        height,
        width,
        onCreated: this.onZoneLoaded,
      });
    } catch (e) {
      console.log("error loading zone", e);
    }
  }

  resetZone() {
    this.blueprintManager.initBlueprint();
  }

  // MARKERS

  loadMarkers(markers) {
    this.layerEditing.destroyChildren();
    this.markersManager.deleteAllMarkersNodes();
    this.markersManager.addMarkers(markers, {init: true});
  }

  // SHAPES

  loadShapes(shapes) {
    this.layerEditing.destroyChildren();
    this.shapesManager.deleteAllShapesNodes();
    this.shapesManager.createShapesNodes(shapes);
  }
}
