import {useDispatch, useSelector} from "react-redux";

import {
  updateModel,
  updateModelNotAsync,
} from "Features/viewer3D/viewer3DSlice";
import {
  setSelectedZoneId,
  addZoneToBuffer,
  removeZoneFromBuffer,
} from "../zonesSlice";
import {
  setSelectedPdfModelId,
  setSelectedModelId,
} from "Features/viewer3D/viewer3DSlice";

import {Box, ListItemButton, Typography, IconButton} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  CenterFocusStrong as Focus,
} from "@mui/icons-material";

import getListItemStyle from "Styles/getListItemStyle";
import getItemsMapById from "Utils/getItemsMapById";

export default function ListZones({zones, caplaEditor}) {
  const dispatch = useDispatch();
  // data

  const os = useSelector((s) => s.ui.openSections);
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);
  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);

  // helpers items

  const items = zones;
  const zonesById = getItemsMapById(zones);

  // helperr - showZoneIn3d

  async function showZoneIn3d(zone) {
    const imageModel = zone.imageModel;
    // state
    const updates = {id: imageModel.id, hidden: false};
    dispatch(updateModelNotAsync({updates, sync: false}));
    dispatch(addZoneToBuffer(zone));
    // load texture
    const options = {loadTexture: true}; // remove hideImageModels: true from option. Used for ?
    await caplaEditor?.editor3d.loader.loadInitialModel(imageModel, options);
    // show 3D
    const entity = caplaEditor?.editor3d.getEntity(imageModel.id);
    entity?.show();
  }

  function hideZoneIn3d(zone) {
    const imageModel = zone?.imageModel;
    // state
    const updates = {id: imageModel.id, hidden: true};
    dispatch(updateModelNotAsync({updates, sync: false}));
    dispatch(removeZoneFromBuffer(zone));
    // show 3D
    const entity = caplaEditor?.editor3d.getEntity(imageModel.id);
    entity?.hide();
  }

  // handlers

  function handleZoneClick(zone) {
    console.log("zoneXXX", zone);
    const wasSelected = selectedZoneId === zone.id;
    const zoneId = wasSelected ? null : zone.id;
    if (!zone.isPdf) {
      dispatch(setSelectedZoneId(zoneId));
      if (zone) {
        //dispatch(setSelectedModelId(zoneId));
        dispatch(setSelectedPdfModelId(zone.pdfModelId));
        if (zoneId) {
          caplaEditor?.editor3d.sceneEditor.jumpModel(zone.imageModelId);
        }
        caplaEditor?.editorPdf.annotationsManager.selectAnnotation(zoneId);
      } else {
        caplaEditor?.editorPdf.annotationsManager.selectAnnotation(null);
      }
    }
  }

  async function handleZoneVisibility(event, zone, show) {
    event.stopPropagation();
    if (show) {
      showZoneIn3d(zone);
    } else {
      hideZoneIn3d(zone);
    }
  }

  function handleZoneFocus(event, zone) {
    event.stopPropagation();

    //dispatch(setSelectedModelId(zone?.pdfModelId));
    if (zone?.pdfModelId) dispatch(setSelectedPdfModelId(zone?.pdfModelId));
    if (os.viewer3D && zone.imageModel) {
      showZoneIn3d(zone);
      caplaEditor?.editor3d.sceneEditor.focusImageModel(zone.imageModel);
    }
    if (os.fixedViewersBox) {
      event.stopPropagation();
      caplaEditor?.editorPdf.annotationsManager.selectAnnotation(zone.id);
      caplaEditor?.editorPdf.annotationsManager.goToZone(zone.id);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        bgcolor: "common.white",
      }}
    >
      {items.map((item) => {
        const {fontWeight, bgcolor, color} = getListItemStyle(item);
        const selected = selectedZoneId === item.id;
        const isHidden =
          item?.imageModel?.hidden || !item?.imageModelWithInitialTexture;
        return (
          <ListItemButton
            key={item.id}
            selected={selected}
            onClick={() => handleZoneClick(item)}
            sx={{p: 0, m: 0, pl: 0.5, fontWeight, bgcolor, color}}
            divider
          >
            <Box
              sx={{
                display: "flex",
                width: 1,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "text.secondary",
                }}
              >
                <IconButton
                  size="small"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    mr: 1,
                  }}
                  onClick={(event) =>
                    handleZoneVisibility(event, item, isHidden)
                  }
                >
                  {isHidden && (
                    <VisibilityOff
                      sx={{
                        color: (theme) => theme.palette.divider,
                        visibility: os.viewer3D ? "visible" : "hidden",
                      }}
                      fontSize="small"
                    />
                  )}
                  {!isHidden && (
                    <Visibility
                      sx={{
                        color: "inherit",
                        visibility: os.viewer3D ? "visible" : "hidden",
                      }}
                      fontSize="small"
                    />
                  )}
                </IconButton>
              </Box>
              <Box sx={{flexGrow: 1}}>
                <Typography
                  sx={{
                    fontSize: 12,
                    color: item.imageModelWithInitialTexture
                      ? "text.primary"
                      : "text.secondary",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
              {!item.isPdf && (
                <IconButton
                  onClick={(event) => handleZoneFocus(event, item)}
                  size="small"
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <Focus sx={{color: "text.secondary"}} fontSize="small" />
                </IconButton>
              )}
            </Box>
          </ListItemButton>
        );
      })}
    </Box>
  );
}
