import React from "react";

import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Texture, Square} from "@mui/icons-material";

export default function SelectorFillTypeVariantButton({
  fillType,
  onChange,
  color,
}) {
  const options = [
    {value: "SOLID", icon: <Square sx={{color}} />},
    {value: "HATCH_1", icon: <Texture sx={{color}} />},
    {value: "HATCH_2", icon: <Texture sx={{color}} />},
  ];

  function handleChange(event, newFillType) {
    if (newFillType) onChange(newFillType);
  }

  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      value={fillType}
      onChange={handleChange}
    >
      {options.map((option) => {
        return (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{
              ...(option.value === "HATCH_2" && {transform: "rotate(90deg)"}),
            }}
          >
            {option.icon}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
