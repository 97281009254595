import {Box, List, ListItemButton, Typography} from "@mui/material";

export default function ListMapsVariantBasic({maps, selection, onClick}) {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        height: 1,
        minHeight: 0,
      }}
    >
      <List disablePadding>
        {maps?.map((map) => {
          const selected = selection.includes(map.id);
          return (
            <ListItemButton
              key={map.id}
              selected={selected}
              onClick={() => onClick(map)}
              sx={{p: 0, m: 0}}
              divider
            >
              <Box
                sx={{
                  display: "flex",
                  width: 1,
                  alignItems: "center",
                }}
              >
                <Box sx={{p: 1}}>
                  <img
                    src={map.url}
                    style={{
                      border: "1px solid grey",
                      width: "80px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "text.secondary",
                    px: 2,
                  }}
                >
                  <Typography variant="body2">{map.name}</Typography>
                </Box>
              </Box>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
