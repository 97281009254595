import Konva from "konva";
import getMousePositionInStage from "./utilsZonesEditor/getMousePositionInStage";
import theme from "Styles/theme";
import createHatchingPattern from "./utilsShapesManager/createHatchingPattern";
import getRectangleNodePoints from "./utilsShapesManager/getRectangleNodePoints";

export default class RectangleManager {
  constructor({zonesEditor}) {
    this.zonesEditor = zonesEditor;
    this.layerEditing = zonesEditor.layerEditing;
    this.stage = zonesEditor.stage;

    this.drawingIsEnabled = false;
    this.isDrawing = false;

    this.currentRect = null; // the scale being drawn.
    this.startPos = null;
    this.endPos = null;

    // Bind event handlers
    this.handleMouseClick = this.handleMouseClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.handleShapeClick = this.handleShapeClick.bind(this);
    this.handleShapeDblClick = this.handleShapeDblClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleStageClick = this.handleStageClick.bind(this);

    // Add keydown event listener
    window.addEventListener("keydown", this.handleKeyDown);
  }

  enableDrawing() {
    //
    this.zonesEditor.enableCrosshair();
    this.drawingIsEnabled = true;
    this.stage.on("click", this.handleMouseClick);
  }

  disableDrawing() {
    this.resetCurrentScale();
    this.zonesEditor.disableCrosshair();
    this.drawingIsEnabled = false;
    this.stage.off("click", this.handleMouseClick);
  }

  startDrawing() {
    this.isDrawing = true;
    this.resetCurrentScale();
    this.stage.on("mousemove", this.handleMouseMove);
    window.addEventListener("keydown", this.handleKeyDown);
    //
    this.zonesEditor.onIsDrawingChange(true);
  }

  stopDrawing() {
    this.isDrawing = false;
    this.stage.off("mousemove", this.handleMouseMove);
    window.removeEventListener("keydown", this.handleKeyDown);
    //
    setTimeout(() => {
      this.zonesEditor.onIsDrawingChange(false); // to let window keyDown event being executed on the Viewer.
    }, 100);
  }

  resetCurrentScale() {
    if (this.currentRect) this.currentRect?.destroy();
    this.currentRect = null;
    this.startPos = null;
    this.endPos = null;
    this.layerEditing.destroyChildren();
  }

  handleMouseClick(event) {
    const newShape = this.zonesEditor.shapesManager.newShape;

    //event.evt.stopPropagation();
    if (!this.drawingIsEnabled || this.zonesEditor.stageIsDragging) return;

    const {x, y} = getMousePositionInStage(this.stage);
    if (!this.isDrawing) {
      this.startDrawing();
      this.startPos = {x, y};
      this.currentRect = new Konva.Rect({
        x,
        y,
        width: 0,
        height: 0,
        stroke: newShape.color,
        strokeWidth: 2,
      });
      //
      if (newShape.fillType === "SOLID") {
        this.currentRect.fill(newShape.color);
        this.currentRect.opacity(0.5);
        this.currentRect.stroke(newShape.color);
      } else {
        this.currentRect.fillPatternImage(
          createHatchingPattern({
            color: newShape.color,
            variant: newShape.fillType,
            space: 10 / this.zonesEditor.stage.scaleX(),
            lineWidth: 2 / this.zonesEditor.stage.scaleX(),
          })
        );
        this.currentRect.fillPatternRepeat("repeat");
      }
      //
      this.layerEditing.add(this.currentRect);
    } else if (this.currentRect && !this.endPos) {
      this.endPos = {x, y};
      const width = x - this.startPos.x;
      const height = y - this.startPos.y;
      this.currentRect.width(width);
      this.currentRect.height(height);
      this.layerEditing.batchDraw();
      //
      const points = getRectangleNodePoints(this.currentRect, this.zonesEditor);
      console.log("[RectangleManager] points", points);
      //
      if (points.length > 0)
        this.zonesEditor.shapesManager.onNewShape({points, type2d: "POLYGON"});
      //
      this.stopDrawing();
    } else {
      return;
    }
  }

  handleMouseMove(event) {
    if (!this.isDrawing || !this.currentRect || this.endPos) return;

    const nextPoint = getMousePositionInStage(this.stage);
    const dx = nextPoint.x - this.startPos.x;
    const dy = nextPoint.y - this.startPos.y;
    this.currentRect.width(dx);
    this.currentRect.height(dy);
    this.stage.batchDraw();
  }

  handleShapeDblClick(event) {
    console.log("dblclick event", event);
    const shape = event.target;
    this.transformer.nodes([shape]);
    this.layerEditing.batchDraw();
  }

  handleShapeClick(event) {
    const shape = event.target;
    console.log("click on shape", shape);
    //this.transformer.nodes([shape]);
    //this.layerEditing.batchDraw();
    this.drawAnchors(shape);
    this.editedShape = shape;
  }

  handleKeyDown(event) {
    if (event.key === "Escape" && this.isDrawing) {
      this.resetCurrentScale();
      this.stopDrawing();
    }
  }

  handleStageClick(event) {
    const clickedShape = event.target;
    const shapeId = clickedShape?._id ?? "-?-";
    console.log("clickedShape id", shapeId);
    // if (this.selectedShape?.id && this.selectedShape.id !== this.selectedShape) {
    //   this.removeAnchors();
    // }
  }
}
