import {SpaceBar} from "@mui/icons-material";

export default function createHatchingPattern({
  color,
  space,
  lineWidth,
  variant,
}) {
  const patternCanvas = document.createElement("canvas");
  patternCanvas.width = space;
  patternCanvas.height = space;
  const ctx = patternCanvas.getContext("2d");

  ctx.strokeStyle = color ?? "black";
  ctx.lineWidth = lineWidth;

  if (variant === "HATCH_1") {
    // Draw hatching lines
    ctx.beginPath();
    ctx.moveTo(0, space);
    ctx.lineTo(space, 0);
    ctx.stroke();
  } else if (variant === "HATCH_2") {
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(space, space);
    ctx.stroke();
  } else {
    // Draw hatching lines
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(space, space);
    ctx.moveTo(space, 0);
    ctx.lineTo(0, space);
    ctx.stroke();
  }

  return patternCanvas;
}
