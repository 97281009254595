import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {useOutletContext} from "react-router-dom";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {setSelectedFixedViewer} from "Features/ui/uiSlice";
import {setSelectedZoneId, showNodesLayer} from "Features/zones/zonesSlice";

import {Box} from "@mui/material";

export default function PageShapes() {
  const dispatch = useDispatch();
  const {scene} = useOutletContext();
  const sceneId = scene?.id;

  // data

  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);
  const zones = useZonesByScene(scene);

  // effect

  useEffect(() => {
    if (selectedFixedViewer !== "ZONE") {
      dispatch(setSelectedFixedViewer("ZONE"));
    }
    if (selectedZoneId === null && zones.length > 0) {
      dispatch(setSelectedZoneId(zones[0].id));
    }
    dispatch(showNodesLayer("SHAPES"));
  }, [selectedFixedViewer, selectedZoneId]);

  //
  //useInitFetchSurveys(sceneId);
  //

  return <Box sx={{width: 1, height: 1}}></Box>;
}
